/*global google*/

import React, { useState } from 'react';

import { compose, withProps, lifecycle } from 'recompose';
import { GoogleMap, Marker, withScriptjs, withGoogleMap, Polyline } from "react-google-maps";

import apiUtil from '../../api/apiUtil.jsx';

const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };

const GOOGLE_STYLE = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#46BCEC"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
];

let points = [];

const getPinIcon = (color) => {
      return '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">' +
        '<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="' + color + '" stroke="none">' +
          '<path d="M2360 5099 c-676 -76 -1267 -509 -1539 -1128 -143 -324 -195 -700 -140 -1021 94 -556 482 -1268 1136 -2086 213 -266 549 -649 693 -789 l61 -60 118 121 c308 317 731 831 1000 1214 533 758 791 1366 791 1862 0 726 -419 1388 -1080 1707 -322 155 -691 219 -1040 180z m418 -644 c529 -86 948 -499 1047 -1034 19 -107 20 -331 0 -445 -89 -512 -483 -919 -995 -1027 -136 -29 -362 -31 -492 -6 -390 77 -720 323 -898 668 -99 193 -140 364 -140 589 0 350 120 639 370 891 194 196 419 315 685 363 107 19 308 19 423 1z"/>' + 
        '</g>' +
      '</svg>'
}

const RouteOptimizeMap = compose(
  withProps({
    googleMapURL: apiUtil.getGoogleMapUrl(),
    loadingElement: <div style={{ width: '100%', height: '100%' }} />,
    containerElement: <div style={{ width: '100%', height:'100%' }} />,
    mapElement: <div style={{ width: '100%', height: `100%` }} />
  }),
  lifecycle({
    componentWillMount() {
      this.setState({
        zoomToMarkers: map => {
          const bounds = new google.maps.LatLngBounds();
          points.forEach((point) => {
            bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
          })
          map.fitBounds(bounds);
        }
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  var lineSymbol = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
  };

  const [defaultCenter] = useState(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
  const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
  const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 12);
  const [zoom, setZoom] = useState(props.zoom ? props.zoom : 12);

  React.useEffect(() => {
    setCenter(props.center ? props.center : DEF_CENTER);
    setZoom(props.zoom ? props.zoom : 12);
  }, [props.center, props.zoom, props.markers]);

  const prepareMarkers = (path, color) => {
    let markers = [];

    let svg = getPinIcon(color);
    let icon = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);

    let firstMarker = path[0];
    if (firstMarker !== undefined) {
      points.push({ lat: firstMarker.lat, lng: firstMarker.lng });
      markers.push(<Marker
        key={0}
        label={'Start'}
        icon={{
          url: icon,
          scaledSize: new google.maps.Size(35,35),
          labelOrigin: new google.maps.Point(15,-5)
        }}
        position={{ lat: firstMarker.lat, lng: firstMarker.lng }}
      />);
    }

    if (path.jobs !== undefined) {
      path.jobs.forEach((jobMarker, i) => {
        points.push({ lat: jobMarker.lat, lng: jobMarker.lng });
        markers.push(<Marker
          key={i+2}
          label={{
            text: jobMarker.label !== undefined ? jobMarker.label.toString() : '0',
          }}
          icon={{
            url: icon,
            scaledSize: new google.maps.Size(35,35),
            labelOrigin: new google.maps.Point(15,-5)
          }}
          position={{ lat: jobMarker.lat, lng: jobMarker.lng }}
        />);
      });
    }

    return markers;
  }

  const preparePolyLines = (paths) => {
    let polylines = [];

    paths.map((data, i) => {
      let randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
      polylines.push(
        <div key={i}>
          {prepareMarkers(data, randomColor)}
          {paths.length === 1 ? 
          <Polyline
          path={data}
          geodesic={true}
          options={{
            strokeColor: randomColor,
            strokeOpacity: 0.75,
            strokeWeight: 2,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "50px"
              }
            ]
          }}
          /> : ""}
        </div>
      );
    });

    return polylines;
  }

  const googleMap = () => {
    return <div>
      <GoogleMap
        ref={props.zoomToMarkers}
        onCenterChanged={props.onCenterChanged}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        center={center}
        zoom={zoom}
        defaultOptions={{
          disableDefaultUI: true,
          disableDoubleClickZoom: false,
          rotateControl: false,
          styles: GOOGLE_STYLE,
        }}
        >
        {preparePolyLines(props.paths)}
      </GoogleMap>
    </div>
  }

  return googleMap();
});

export default RouteOptimizeMap;