import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";

import { 
  Box,
  Grid,
  Container,
  Divider,
  IconButton,
  CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as InformationIcon } from '../../../assets/img/icons/information.svg';

import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../../components/Card/Card.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomAlert from '../../../components/Alert/CustomAlert.jsx';


/**
 * @todo
//  * 1. create
//  * 2. update
 * 3. contact no. preset +65 
//  * 4. formValidatedation - show error. password hide text
   5. ask user to enter two password and display error message if the passwords doesn't match.
 * 6. ux enter key should move to next input
 */

export const LogisticForm = () => {
    const location = useLocation();
    const history = useHistory();
    let Lid = (location && location.state && location.state.id && location.state.id > 0) ? location.state.id : 0;

    const { accessToken } = useContext(AuthContext)
    const [ id, setId ] = useState(Lid);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ firstName, setFirstName] = useState("")
    const [ firstNameValidated, setFirstNameValidated] = useState(false)
    const [ lastName, setLastName] = useState("")
    const [ lastNameValidated, setLastNameValidated] = useState(false)
    const [ contactNo, setContactNo] = useState("")
    const [ contactNoValidated, setContactNoValidated] = useState(false)
    const [ userName, setUserName] = useState("")
    const [ userNameValidated, setUserNameValidated] = useState(false)
    const [ password, setPassword] = useState("")
    const [ passwordValidated, setPasswordValidated] = useState(false)
    const [ passwordType, setPasswordType] = useState('password')
    useEffect(() => {
        console.log("locationstate",location.state)
        if(id && id > 0) {
            callDetailApi(id)
        } 
    }, [])
    const CustomStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        history.push('/resources/logistic-assistance');
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={(id > 0) ? 'Edit Logistic Assistance' : 'Add New Logistic Assistance'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>Submit</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
    }

    const callDetailApi = ( id = '', callback = null) =>  {
        setIsLoading(true);
        Api.details('workers', id, accessToken)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                let result = (data && data.result) ? data.result : null;
                if(result){
                    setIsLoading(false);
                    setFirstName(result.first_name)
                    setLastName(result.last_name)
                    setContactNo(result.contact_no)
                    setUserName(result.email)
                    if(callback){
                      callback(result);
                    }
                } else {
                    setIsLoading(false);
                    if(callback){
                      callback(null);
                    }
                }
              }, (error, type) => {
                  setIsLoading(false);
                  if(callback){
                    callback(null);
                  }
              });
        })
    }
    const callUpdateApi =  ( id = '', row,  callback = null) => {
        setIsLoading(true);
        Api.update('workers/' + id, row, accessToken)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
                if(callback) {
                    callback();
                }
            }, (error, type) => {
                console.log("error", error)
                setIsLoading(false);
                if(callback) {
                    callback();
                }
            });
        }).catch(
            error => console.log(error)
        )

    }
    const callCreateApi = ( row, callback = null) => {
        setIsLoading(true);
        Api.create('workers', row, accessToken)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
                if(callback) {
                    callback();
                }
            }, (error, type) => {
                console.log("error", error)
                setIsLoading(false);
                if(callback) {
                    callback();
                }
            });
        })
    }
    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        const form = e.currentTarget;

        if(firstName === '') {
            setFirstNameValidated(true)
        }
        if(lastName === '') {
            setLastNameValidated(true)
        }
        if(contactNo === '') {
            setContactNoValidated(true)
        }
        if(userName === '') {
            setUserNameValidated(true)
        }
        if(password === '') {
            setPasswordValidated(true)
        }

        if (form.checkValidity() === true) {
            let data = {
                contact_no: contactNo,
                disabled: false,
                email: userName,
                first_name: firstName,
                is_man_power: true,
                last_name: lastName,
                max_job_perday: 0,
                password: password,
                priority: 0
            }
            if(id && id > 0) {
                console.log()
                callUpdateApi(id, data, () => {
                    history.push('/resources/logistic-assistance');
                    apiUtil.toast('Successfully Updated', 'check_circle');
                })
            } else {
                callCreateApi( data, () => {
                    history.push('/resources/logistic-assistance');
                    apiUtil.toast('Successfully Created', 'check_circle');
                })
            }
        } else {
            setIsLoading(false)
        }
    }
    return <Box className="transport-form-page" >
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <StickyPageHeader isSmall={true}>
            <CustomStickyPageHeader />
        </StickyPageHeader>
        
        <Container maxWidth="lg">
            <Card>
                {
                    !isLoading ?
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <CustomLabel
                                    label={'Logistic Assistance Information'}
                                    weight={'bold'}
                                    size={'md'}
                                />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <Container maxWidth={'sm'}>
                                    <Box clone p={5}>
                                        <Grid container>

                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'First Name'}
                                                        placeholder={'Enter operator first name'}
                                                        value={firstName}
                                                        error={firstNameValidated}
                                                        required={true}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setFirstName(e.target.value)
                                                            setFirstNameValidated( e.target.value !== '' ? false : true )
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Last Name'}
                                                        placeholder={'Enter operator last name'}
                                                        value={lastName}
                                                        error={lastNameValidated}
                                                        required={true}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setLastName(e.target.value)
                                                            setLastNameValidated(e.target.value !== '' ? false : true )
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Phone Number'}
                                                        placeholder={'Enter phone number'}
                                                        value={contactNo}
                                                        required={true}
                                                        error={contactNoValidated}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setContactNo(e.target.value)
                                                            setContactNoValidated(e.target.value !== '' ? false : true )
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>

                                        </Grid>
                                    </Box>
                                </Container>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomLabel
                                    label={'Logistic Assistance Account'}
                                    weight={'bold'}
                                    size={'md'}
                                />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <Container maxWidth={'sm'}>
                                    <Box clone p={5}>
                                        <Grid container>

                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Username'}
                                                        placeholder={'Enter username'}
                                                        value={userName}
                                                        error={userNameValidated}
                                                        required={true}
                                                        autoFocus={true}
                                                        onChange={(e) => {
                                                            setUserName(e.target.value)
                                                            setUserNameValidated(e.target.value !== '' ? false : true )
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Password'}
                                                        className={'custom-password'}
                                                        placeholder={'Enter password'}
                                                        autoComplete={'new-password'}
                                                        value={password}
                                                        error={passwordValidated}
                                                        type={passwordType}
                                                        endAdornment={(obj) => {
                                                            return <Box
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    console.log("hah")
                                                                    setPasswordType((passwordType === 'password') ? 'text' : 'password')
                                                                }}
                                                            >
                                                                {
                                                                    (passwordType === 'password')
                                                                    ?
                                                                    <i className={'far fa-eye-slash'}></i>
                                                                    :
                                                                    <i className={'far fa-eye'}></i>
                                                                }
                                                            </Box>
                                                        }}
                                                        required={true}
                                                        onChange={(e) => {
                                                            setPassword(e.target.value)
                                                            setPasswordValidated((e.target.value !== '') ? false : true)
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                            {/* <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomInput
                                                        label={'Confirm Password'}
                                                        placeholder={'Enter password'}
                                                        value={''}
                                                        onChange={() => {
                                                            
                                                        }}
                                                    />
                                                </Grid>
                                            </Box> */}
                                            <Box clone>
                                                <Grid item xs={12}>
                                                    <CustomAlert
                                                        icon={<InformationIcon />}
                                                    >
                                                        SMS will be sent to your logistic assistance to notify about their new account
                                                    </CustomAlert>
                                                </Grid>
                                            </Box>

                                        </Grid>
                                    </Box>
                                </Container>
                            </Grid>
                        </Grid>
                    </Box>
                    : 
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                        <CircularProgress size={35} />
                    </Box>
                }
            </Card>
        </Container>
        
    </form>
</Box>
}
