import React, { Component } from 'react';
 
import { 
    Box,
    List,
    ListSubheader,
} from '@material-ui/core';


class CustomList extends Component {
    constructor(props) {
        super(props);
        
        let className = (props.className) ? props.className : '';
        let header = (props.header) ? props.header : null;

        this.state = {
            className: className,
            header: header,
        };
    }
 
 
    componentWillReceiveProps(nextProps) {
        if (nextProps.header !== this.state.header) {
            let header = (nextProps.header) ? nextProps.header : '';
            this.setState({
                header: header
            });
        }
    }


    header = () => {
        if(this.state.header){
            return <ListSubheader component="div" id="nested-list-subheader">
                {this.state.header}
            </ListSubheader>
        }
    }
    
    render() {
        return <Box className={'custom-list-component ' + ((this.state.className && this.state.className !== '') ? this.state.className : '')}>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={this.header()}
            >
                {this.props.children}
            </List>
        </Box>
    }
}

export default CustomList;