import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import {
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomAutosuggest from '../../../components/CustomFields/CustomAutosuggest.jsx';
import MultipleSelectChip from "../../../components/CustomFields/MultipleSelectChip.jsx";
import FormControl from "@mui/material/FormControl";

export class AssignManpowerForm extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let selectedManPowers = props.selectedWorkers ? props.selectedWorkers : [];
    
    this.state = {
      isLoading: isLoading,
      id: null,
      extra_worker_ids:null,
      manPowerList: [],
      selectedManPowers: [],
      passedWorkers: selectedManPowers
    }
  }

  componentDidMount() {
    this.callManpowerDriverReadApi("", (arr) => {
      this.setState({
        manPowerList: arr,
        selectedManPowers: this.state.passedWorkers
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
      let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
      this.setState({
        isLoading: isLoading
      });
    }
  }

  /* WORKER API */
  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search,
      is_man_power: true,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if (result && result.length > 0) {
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if (arr.findIndex(x => x.value === value) === -1) {
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });

            if (callback) {
              callback(arr);
            }
          } else {
            if (callback) {
              callback([]);
            }
          }
        }, (error, type) => {
          if (callback) {
            callback([]);
          }
        });
      });
  }
  /* END WORKER API */

  /* FORM */
  setForm = () => {
    return <Box>
      <Grid container>
        <Box clone>
          <Grid item xs={12}>
            <FormControl className="multi-select-form">
              <MultipleSelectChip
                items={this.state.manPowerList}
                value={this.state.selectedManPowers}
                className={"multiple-select-chip"}
                onChange={(value) => {
                  this.setState({
                    selectedManPowers: value
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  setButtons = () => {
    return <Box>
      <Grid container alignItems={'flex-end'} >
        <Box clone textAlign={'right'} pr={2}>
          <Grid item xs={true}>
            <CustomButton
              onClick={() => {
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}
            >
              Cancel
            </CustomButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            >
              Submit
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      isLoading: true,
    });

    const form = e.currentTarget;

    if (this.props.onSave) {
      this.props.onSave(this.getForm());
    }
  }


  getForm = () => {
    let data = {
      id: this.state.id,
      extra_worker_ids: this.state.selectedManPowers,
    }

    return data;
  }
  /* END SUBMIT */

  render() {
    return <Box>
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container>
          <Box clone pt={2} pb={2}>
            <Grid item xs={12}>
              {this.setForm()}
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12}>
              {this.setButtons()}
            </Grid>
          </Box>
        </Grid>
      </form>
    </Box>
  }
}

export default AssignManpowerForm;