import React, { useRef, useState } from 'react';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import apiUtil from '../../api/apiUtil.jsx';
import { useHistory } from "react-router-dom";
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
//import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
//import apiUtil from '../../api/apiUtil.jsx';

import logo from '../../assets/img/logo_black.png';
import signup_image from '../../assets/img/signup-bg.png';

const styles = {
  error: {
      color: 'red',
      fontSize: 'small'
  }
};
export default function SignUp() {
  //const { setAuthenticated, setToken, setUser } = useContext(AuthContext);
  let history = useHistory();


  const [acceptTerm, setAcceptTerm] = useState(false);
  const [validatedAcceptTerms, setValidatedAcceptTerms] = useState(false);
  const [errorAcceptTerm, setErrorAcceptTerm] = useState(null);

  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);

  const [validatedCompanyName, setValidatedCompanyName] = useState(false);
  const [errorCompanyName, setErrorCompanyName] = useState(null);
  
  const [validatedFullName, setValidatedFullName] = useState(false);
  const [errorFullName, setErrorFullName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const emailInput = useRef();
  const companyNameInput = useRef();
  const fullNameInput = useRef();
  // const acceptterms = useRef();

  const label = (
    <span>I accept the&nbsp;
      <a
        href="/terms_and_conditions"
        target="_blank"
      >
        Terms and Conditions
      </a>
      &nbsp;and&nbsp;
      <a
        href="/terms_and_conditions"
        target="_blank"
      >
             Privacy Policy
      </a>
    </span>
  )
  
  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);

    setValidatedAcceptTerms(false);
    setErrorAcceptTerm(null);

    setValidatedCompanyName(false);
    setErrorCompanyName(null);    
    
    setValidatedFullName(false);
    setErrorFullName(null);
    
    if(emailInput.current.lastChild.firstChild.value === ''){
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if(companyNameInput.current.lastChild.firstChild.value === ''){
      setValidatedCompanyName(true);
      setErrorCompanyName('Please enter your CompanyName.');
    }    
    if(fullNameInput.current.lastChild.firstChild.value === ''){
      setValidatedFullName(true);
      setErrorFullName('Please enter your FullName.');
    }
    if(acceptTerm == false){
        setValidatedAcceptTerms(true);
        setErrorAcceptTerm('Please accept term and condition');
    }
    if (form.checkValidity() === true && acceptTerm !== false) {
      
      setIsLoading(true);
    
      let data = {
        "admins": [
          {
            "email": emailInput.current.lastChild.firstChild.value,
            "first_name": fullNameInput.current.lastChild.firstChild.value,
            "password": ""
          }
        ],
        "company_name": companyNameInput.current.lastChild.firstChild.value,
        "contact_no": "",
        "email": emailInput.current.lastChild.firstChild.value,
        "is_default_password": true,
        "ref_admin_id": null,
        "ref_company_id": null
      };
      authApi.signup(data)
        .then((result) => {
          console.log(result);
          apiUtil.parseResult(result, (data) => {
            
            history.push('/sign-up-success');
          }, (error, type) => {
            
            setError({ title: JSON.stringify(error) });
            setIsLoading(false);
          });
      });
    }
  };

  return (
    
    <div className="signup container">
    <div className="signup-left">
      <div className="top-menu">
        <div className="logo"><img src={logo} alt=""/></div>
      </div>
      <div className="middle-text">
        <div className="middle-text-title">Workforce Management Reimagine</div>
        <div className="middle-text-description">Keep your mobile workers organized, on track and optimize your workflow
      with worknode workforce management software.</div>
      </div>
      
      <div className="signup-bg"><img src={signup_image} /></div>
    </div>
    <div className="signup-right" component="main" >
      <div className="top-menu">
        <div className="logo"></div>
        <div className="signin-acc"> 
        <div>Already have an account?</div>
        <Button variant="contained" color="primary" href="/Sign-in">
          Login
        </Button>
        </div>
      </div>
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={3} mb={3}>
          <Alert severity="error">
            {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })}
          </Alert>
        </Box>

        
      }
      <div className="form-wrapper">
      <form noValidate onSubmit={handleSubmit}>

        <Box textAlign="center" mb={3}>
          <div className="form-title">
          Get Start for free
          </div>
          <div className="form-description">
          Try worknode for free with unlimited devices for 30 days
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="fullname"
            label="Full name"
            name="fullname"
            autoFocus
            error={validatedFullName} 
            helperText={errorFullName}
            ref={fullNameInput}
          /> 
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={validatedEmail} 
            helperText={errorEmail}
            ref={emailInput}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="companyname"
            label="Company Name"
            id="companyname"
            ref={companyNameInput}
            error={validatedCompanyName} 
            helperText={errorCompanyName}

          />
          <FormControlLabel
              control={<Checkbox
              checked={acceptTerm}
              onChange={() => {     
                setAcceptTerm(!acceptTerm)
              }}
              color="primary" />}
              label={label}
            />          
            <span style={styles.error}>{errorAcceptTerm}</span>
        </Box>
    
        <Button
          type="submit"
          fullWidth
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN UP</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
      </form>
      </div>
    </div>
    </div>
  );
}