import React, { Component } from 'react';

import uuid from 'react-uuid'
import moment from 'moment';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';
import ldsApi from "../../../api/ldsApi.jsx";
import wmsApi from "../../../api/wmsApi.jsx";

import { 
  Box,
  Grid,
  Chip,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomTabs from '../../../components/Tabs/CustomTabs.jsx'

import ItemsInJobList from './ItemsInJobList.jsx';
import StepsList from './StepsList.jsx';
import HistoryList from './HistoryList.jsx';


export class JobPreviewForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let id = (props.id && props.id > 0) ? props.id : 0;
    let tab = (props.tab && props.tab > 0) ? props.tab : 0;

    this.state = {
        tabsValue: tab,

        isLoading: true,

        id: id,

        drop_off_date: '',
        drop_off_time_planned: '',
        drop_off_description: '',
        drop_off_address: '',
        amount: '',

        drop_off_name: '',
        drop_off_contact_name: '',
        assign_type: true,
        picItems: [],
        itemsInJobList: [],
        job_steps: [],

        openDialogCustomer: false,
        itemDialogCustomer: null,
        
        openDialogDriver: false,
        itemDialogDriver: null,

        openDialogLogisticsAssistance: false,
        itemDialogLogisticsAssistance: null,

        customerItems: [],
        customerItemsIsLoading: false,
        customerItem: null,
        customerId: '',
        customerName: '',
        
        vendorItems: [],
        vendorItemsIsLoading: false,
        vendorItem: null,
        vendorId: '',
        vendorName: '',

        workerItems: [],
        workerItemsIsLoading: false,
        workerItem: null,
        workerId: '',
        workerName: '',

        manPowerItems: [],
        manPowerItemsIsLoading: false,
        manPowerItem: null,
        manPowerId: '',
        manPowerName: '',

        branchItems: [],
        order_branch_id: '',
        
        departmentItems: [],
        departmentItem: null,
        department_id: '',

        customerDetailsIsLoading: true,
        customerDetails: null,

        orderStatusesIsLoading: false,
        orderStatuses: [],
        order_status: null,
        order_status_id: 0,
        order_status_name: '',

        order_histories: [],
    }
  }
  

  componentDidMount() {
    this.callOrderStatusesApi((data) => {
        if(this.state.item === null){
            let orderStatusItem = apiUtil.customFilter(data, 'label', 'not assigned');
            if(orderStatusItem){
              this.setState({
                  job_step_status_id: orderStatusItem.value,
                  job_step_status: orderStatusItem,
              });
            }
        }
    });

    if(this.state.id && this.state.id > 0){
        this.callDetailsApi(this.state.id, (data, itemsInTheJob) => {
      
            /* HISTORY */
            this.setState({
                order_histories: (data && data.order_histories && data.order_histories.length > 0) ? data.order_histories : [],
              });
            /* END HISTORY */


            /* VENDOR */
            let vendorItems = [];
            let vendorItem = null;
            if(data && data.vendor){
              vendorItem = {
                value: (data.vendor_id) ? data.vendor_id : '',
                label: (data.vendor.application_company && data.vendor.application_company.company_name && data.vendor.application_company.company_name !== '') ? data.vendor.application_company.company_name : '',
                item: (data.vendor) ? data.vendor : null,
              };
              vendorItems.push(vendorItem);
            }
            this.setState({
              assign_type: (data && data.assign_type && data.assign_type === 'vendor') ? false : true,
              vendorNameValidated: false,
              vendorName: (vendorItem && vendorItem.label) ? vendorItem.label : '',
              vendorId: (vendorItem && vendorItem.value) ? vendorItem.value : '',
              vendorItem: (vendorItem && vendorItem.item) ? vendorItem.item : null,
              vendorItems: vendorItems,
            });
            /* END VENDOR */
    
    
            /* STATUS */
            this.setState({
              order_status: (data && data.order_status) ? data.order_status : null,
              order_status_id: (data && data.order_status_id) ? data.order_status_id : '',
              order_status_name: (data && data.order_status && data.order_status.status) ? data.order_status.status : '',
            });
            /* END STATUS */
    
            
            /* JOB DETAILS SECTION */
            this.setState({
              drop_off_date: (data && data.drop_off_date) ? moment(data.drop_off_date).format(apiUtil.getDefaultDateFormat()) : '',
              drop_off_time_planned: (data && data.drop_off_time_planned) ? moment(data.drop_off_time_planned).format(apiUtil.getDefaultTimeFormat()) : '',
              drop_off_description: (data && data.drop_off_description) ? data.drop_off_description : '',
            });
            /* END JOB DETAILS SECTION */
    
    
            /* CUSTOMERS DETAILS SECTION */
            let customerItems = [];
            let customerItem = null;
            if(data && data.customer){
              customerItem = {
                value: (data.customer_id) ? data.customer_id : '',
                label: (data.company_name) ? data.company_name : '',
                item: (data.customer) ? data.customer : null,
              };
              customerItems.push(customerItem);
            }
    
            this.setState({
              customerNameValidated: false,
              customerName: (customerItem && customerItem.label) ? customerItem.label : '',
              customerId: (customerItem && customerItem.value) ? customerItem.value : '',
              customerItem: (customerItem && customerItem.item) ? customerItem.item : null,
              customerItems: customerItems,
              amount: (data && data.amount) ? data.amount : '',
              item_tracking_number: (data.item_tracking_number) ? data.item_tracking_number : '',
              is_tracking_link_required: (data.is_tracking_link_required) ? data.is_tracking_link_required : false,
              is_notify_customer: (data.is_notify_customer) ? data.is_notify_customer : false,
            }, () => {
              if(data && data.customer_id){
                this.callCustomerDetailsApi(this.state.customerId, (customerData) => {
                  let customerAddresses = (customerData && customerData.customer_addresses && customerData.customer_addresses.length > 0) ? customerData.customer_addresses : [];
                  let branchItems = customerAddresses.map((item, i) => {
                    return {
                      value: item.id,
                      label: item.name,
                      item: item,
                    }
                  });
    
                  let departmentItems = [];
                  if(customerData && customerData.departments && customerData.departments.length > 0){
                    departmentItems = customerData.departments.map((item, i) => {
                      return {
                        value: item.id,
                        label: item.name,
                        item: item,
                      }
                    });
                  }
    
                  this.setState({
                    branchItems: branchItems,
                    departmentItems: departmentItems,
                  }, () => {
                    let drop_off_address = '';
                    let order_branch_id = (data && data.order_branch_id) ? data.order_branch_id : '';
                    let drop_off_addressItem = branchItems.filter(x => x.value === order_branch_id);
                    if(drop_off_addressItem && drop_off_addressItem.length > 0){
                      drop_off_address = drop_off_addressItem[0].item.address_line1;
                    }
                    this.setState({
                      order_branch_id: order_branch_id,
                      drop_off_address: drop_off_address,
                    });
                    
                    let departmentItem = null;
                    let department_id = (data && data.department_id) ? data.department_id : '';
                    let departmentArray = (departmentItems && departmentItems.length > 0) ? departmentItems.filter(x => x.value === department_id) : [];
                    if(departmentArray && departmentArray.length > 0){
                      departmentItem = departmentArray[0].item;
                    }
                    this.setState({
                      department_id: department_id,
                      departmentItem: departmentItem,
                    });
                  });
                });
              }
            });
    
            this.setState({
                drop_off_name: (data.drop_off_name && data.drop_off_name !== '') ? data.drop_off_name : '',
            });
            /* END CUSTOMERS DETAILS SECTION */
    
    
            /* ITEMS IN JOB LIST SECTION */
            let itemsInJobList = [];
            if(itemsInTheJob && itemsInTheJob.length > 0){
              itemsInJobList = itemsInTheJob.map((item, i) => {
                return {
                  uuid: uuid(),
                  id: item.id,
                  description: item.description,
                  quantity: item.quantity,
                  uom: item.uom,
                  weight: item.weight,
                  remarks: item.remarks,
                  remove: false,
                }
              });
            }
            this.setState({
              itemsInJobList: itemsInJobList,
            });
            /* END ITEMS IN JOB LIST SECTION */
            
    
            /* STEPS SECTION */
            let job_steps = [];
            if(data && data.job_steps && data.job_steps.length > 0){
              job_steps = data.job_steps.map((item, i) => {
                // let orderStatusItem = apiUtil.customFilter(this.state.orderStatuses, 'value', item.job_step_status_id);
                // let job_step_status = (orderStatusItem) ? orderStatusItem : null;
                
                return {
                  uuid: uuid(),
                  id: item.id,
                  job_step_id: item.id,
                  job_step_name: item.job_step_name,
                  job_step_status_id: item.job_step_status_id,
                  job_step_status: item.job_step_status,
                  location: item.location,
                  is_signature_required: item.is_signature_required,
                  is_scan_required: item.is_scan_required,
                  job_step_pic: item.job_step_pic,
                  job_step_pic_contact: item.job_step_pic_contact,
                  description: item.description,
                  order_attempts: item.order_attempts,
                  order_sequence: (i + 1),
                  remove: false,
                }
              });
            }
            this.setState({
              job_steps: job_steps,
            });
            /* END STEPS SECTION */
    
    
            /* ASSIGN WORKERS SECTION */
            let manPowerItem = (data && data.extra_workers && data.extra_workers.length > 0) ? data.extra_workers[0] : null;
            this.setState({
              workerItem: data.drop_off_worker,
              workerName: data.drop_off_worker_name,
              workerId: data.drop_off_worker_id,
              manPowerItem: manPowerItem,
              manPowerName: (manPowerItem) ? ((manPowerItem.first_name && manPowerItem.first_name !== '') ? manPowerItem.first_name : '') + ' ' + ((manPowerItem.last_name && manPowerItem.last_name !== '') ? manPowerItem.last_name : '') : '',
              manPowerId: (manPowerItem) ? manPowerItem.id : 0,
            });
            /* END ASSIGN WORKERS SECTION */
        });
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('orders/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null, (data && data.sorted_order_details) ? data.sorted_order_details : []);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  callCustomerDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      customerDetailsIsLoading: true,
      customerDetails: null,
    }, () => {
      wmsApi.details('customers', id, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data && data.customer) ? data.customer : null;

            if(result){
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: result,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                customerDetailsIsLoading: false,
                customerDetails: null,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              customerDetailsIsLoading: false,
              customerDetails: null,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  
  callOrderStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      orderStatusesIsLoading: true,
      orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                orderStatusesIsLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              orderStatusesIsLoading: false,
              orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  inlineRowTemplate = (component1, component2) => {
    return <Box>
        <Grid container>
            <Box clone pb={1}>
                <Grid item xs={12} md={3}>{component1}</Grid>
            </Box>
            <Box clone pb={1}>
                <Grid item xs={12} md={true}>{component2()}</Grid>
            </Box>
        </Grid>
    </Box>
  }

  setOverview = () => {
    return <Box pt={3}>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Status'}
                        />,
                        () => {
                            return (this.state.order_status_name !== '') && <Chip className={'status-label ' + apiUtil.statusToClassName(this.state.order_status_name)} label={this.state.order_status_name} />
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Description'}
                        />,
                        () => {
                            return  <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.drop_off_description,
                            }}
                          />
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Delivery Time'}
                        />,
                        () => {
                            let date = (this.state.drop_off_date) ? this.state.drop_off_date : '';
                            let time = (date && date !== '') ? (this.state.drop_off_time_planned) ? this.state.drop_off_time_planned : '' : '';
                            
                            let dateTime = '';
                            if(date && time){
                                dateTime = date + ' ' + time;
                            } else if (date){
                                dateTime = date;
                            } else if (time){
                                dateTime = time;
                            }
                        
                            return dateTime;
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Recipient'}
                        />,
                        () => {
                            return this.state.drop_off_name;
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Driver'}
                        />,
                        () => {
                            return this.state.workerName;
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <Divider light />
                    <Box pt={2}>
                        <CustomLabel
                            label={'Items in the job'}
                            weight={'bold'}
                        />
                        <ItemsInJobList 
                            preview={true}
                            items={this.state.itemsInJobList}
                        />
                    </Box>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }

  setSteps = () => {
    return <Box>
        <StepsList 
            preview={true}
            items={this.state.job_steps}
        />
    </Box>
  }

  setHistory = () => {
    return <Box>
        <HistoryList 
            preview={true}
            items={this.state.order_histories}
        />
    </Box>
  }

  setForm = () => {
      if(this.state.isLoading){
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'300px'}>
            <CircularProgress size={35} />
        </Box>
      } else {
        return <Box>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomTabs
                            selected={this.state.tabsValue}
                            items={[
                                {
                                    text: <CustomLabel label={'Overview'} weight={'bold'} />,
                                    children: this.setOverview(),
                                },
                                {
                                    text: <CustomLabel label={'Steps'} weight={'bold'} />,
                                    children: this.setSteps(),
                                },
                                {
                                    text: <CustomLabel label={'History'} weight={'bold'} />,
                                    children: this.setHistory(),
                                }
                            ]}
                            onChange={(val) => {
                                this.setState({
                                    tabsValue: val
                                });
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
        </Box>
      }
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */

      
  render() {
    return <Box>
        <Grid container>
            <Box clone pt={1} pb={2}>
                <Grid item xs={12}>
                    {this.setForm()}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
}

export default JobPreviewForm;