import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";

import { 
  Box,
  Grid,
  Container,
} from '@material-ui/core';

import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';


export class Onboarding extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      isLoading: false,
      openDialog: false,
    }
  }
  

  /* API */
  callUpdateAdminProfileApi = (callback = null) => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let params = {
        "is_onboarding": false,
      };

      api.updateAdminProfile('profile/admins', params, accessToken).then((result) => {
        apiUtil.parseResult(result, (data) => {
          this.setState({
            isLoading: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);
            
            if(callback){
              callback(data.result);
            }
          });
        }, (error, type) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(callback){
              callback(null);
            }
          });
        });
      });
    });
  }
  /* END API */


  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openDialog: false,
        });
      }}
      onOk={() => {
        this.callUpdateAdminProfileApi((data) => {
          this.setState({
            openDialog: false,
          }, () => {
            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="onboarding-page" >
        <Container 
            className={'custom-container'}
            maxWidth="sm"
        >
            <Grid container>
                <Box clone pb={2} textAlign={'center'}>
                    <Grid item xs={12}>
                        <CustomLabel
                            className={'muted'}
                            label={'Here is what we\'re going to do'}
                            weight={'bold'}
                            size={'xl'}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <Card className={'no-margin'}>
                            <Grid container>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Box className={'custom-item'}>
                                            <Box className={'custom-circle'}>1</Box>
                                            <Box className={'custom-title'}>Create Customer</Box>
                                            <Box className={'custom-desc'}>Create a customer for your company.</Box>
                                            <Box pt={1}><hr /></Box>
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Box className={'custom-item'}>
                                            <Box className={'custom-circle'}>2</Box>
                                            <Box className={'custom-title'}>Create Vehicle</Box>
                                            <Box className={'custom-desc'}>Create a vehicle for your worker to drive.</Box>
                                            <Box pt={1}><hr /></Box>
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Box className={'custom-item'}>
                                            <Box className={'custom-circle'}>3</Box>
                                            <Box className={'custom-title'}>Create Operator</Box>
                                            <Box className={'custom-desc'}>Create a worker who is going to do the job.</Box>
                                            <Box pt={1}><hr /></Box>
                                        </Box>
                                    </Grid>
                                </Box>

                                <Box clone>
                                    <Grid item xs={12}>
                                        <Box className={'custom-item'}>
                                            <Box className={'custom-circle'}>4</Box>
                                            <Box className={'custom-title'}>Create Job</Box>
                                            <Box className={'custom-desc'}>With the vehicle and driver created, we're going to give a job for them.</Box>
                                        </Box>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Card>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <Box pt={3}>
                            <Grid container alignItems={'center'} >
                                <Box clone textAlign={'right'} pr={2}>
                                    <Grid item xs={true}>
                                        <CustomButton 
                                            onClick={() => {
                                                this.setState({
                                                    openDialog: true,
                                                });
                                            }} 
                                            variant={'text'} 
                                            color={'default'}
                                            isLoading={this.state.isLoading}
                                        >
                                            Skip Tutorial
                                        </CustomButton>
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={'auto'}>
                                        <CustomButton 
                                            className={"ok-btn " + this.state.type}
                                            onClick={() => {
                                                this.props.history.push('/customers');
                                            }} 
                                            variant={'contained'} 
                                            color={'primary'}
                                            isLoading={this.state.isLoading}
                                        >
                                            Begin Tutorial
                                        </CustomButton>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Container>

        {this.customDialog()}
    </Box>;
  }
}

