import React from 'react';

import { 
    Box,
    Grid,
    IconButton,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
import Toolbar from 'react-big-calendar/lib/Toolbar';

import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';


class CustomToolbar extends Toolbar {

    constructor(props){
        super(props);
    
        this.state = {
          view: 2,
        }
    }


    render() {
        return (
          <Box pb={2}>
              <Grid container alignItems={'center'}>
                  <Box clone>
                      <Grid item xs={12} md={4}></Grid>
                  </Box>
                  <Box clone>
                      <Grid item xs={12} md={4}>
                            <Grid container justify={'center'} alignItems={'center'}>
                                <Box clone>
                                    <Grid item xs={'auto'}>
                                        <IconButton
                                            onClick={() => {
                                                this.props.onNavigate('PREV')
                                            }}
                                        >
                                            <Icon component={'i'}>chevron_left</Icon>
                                        </IconButton>
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={'auto'}>
                                        <CustomLabel
                                            className={'no-margin'}
                                            label={this.props.label}
                                            weight={'bold'}
                                            size={'lg'}
                                        />
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={'auto'}>
                                        <IconButton
                                            onClick={() => {
                                                this.props.onNavigate('NEXT')
                                            }}
                                        >
                                            <Icon component={'i'}>chevron_right</Icon>
                                        </IconButton>
                                    </Grid>
                                </Box>
                            </Grid>
                      </Grid>
                  </Box>
                  <Box clone textAlign={'right'}>
                      <Grid item xs={12} md={4}>
                        <GroupButton
                            className={'contained rounded h-25'}
                            color={'secondary'}
                            selected={this.state.view}
                            buttons={[ "Daily", "Weekly", "Monthly" ]}
                            onClick={(selected, btn) => {
                                this.setState({
                                    view: selected
                                }, () => {
                                    if(this.state.view === 0){
                                        this.view('day');
                                    } else if(this.state.view === 1){
                                        this.view('week');
                                    } else if(this.state.view === 2){
                                        this.view('month');
                                    } else {
                                        this.view('month');
                                    }
                                });
                            }}
                        />
                      </Grid>
                  </Box>
              </Grid>
          </Box>
        );
    }
}


export default CustomToolbar;