import React, { Component } from 'react';

import { 
  Paper,
} from '@material-ui/core';

class Card extends Component {

  constructor(props){
    super(props);

    let className = (props.className) ? props.className : '';

    this.state = {
        className: className,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.className !== this.state.className) {
      this.setState({
        className: nextProps.className
      });
    }
  }


  render() {
    return <Paper elevation={0} className={'custom-card-component ' + this.state.className}>
        {this.props.children}
    </Paper>
  }
}

export default Card;
