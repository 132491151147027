import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import CustomLabel from "./CustomLabel.jsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip(props) {
  const theme = useTheme();
  const [helperText, setHelperText] = React.useState(props.helperText ? props.helperText : "");
  const [className] = React.useState(props.className ? props.className : "");
  const [label, setLabel] = React.useState(props.label ? props.label : "");
  const [value, setValue] = React.useState(props.value ? props.value : []);
  const [items, setItems] = React.useState(
    props.items && props.items.length > 0 ? props.items : []
  );
  const [placeholder] = React.useState(
    props.placeholder ? props.placeholder : null
  );
  const [labelComponent, setLabelComponent] = React.useState(
    props.labelComponent ? props.labelComponent : null
  );
  const [disabled, setDisabled] = React.useState(
    props.disabled === true || props.disabled === false ? props.disabled : false
  );
  const [error, setError] = React.useState(
    props.error === true || props.error === false ? props.error : false
  );
  const [required, setRequired] = React.useState(
    props.required === true || props.required === false ? props.required : false
  );
  const [initFirstItem, setInitFirstItem] = React.useState(
    props.initFirstItem === true || props.initFirstItem === false
      ? props.initFirstItem
      : false
  );

  React.useEffect(() => {
    setHelperText(props.helperText ? props.helperText : "");
    setLabel(props.label ? props.label : "");
    setValue(props.value ? props.value : "");
    setItems(props.items && props.items.length > 0 ? props.items : []);
    setLabelComponent(props.labelComponent ? props.labelComponent : null);
    setDisabled(
      props.disabled === true || props.disabled === false
        ? props.disabled
        : false
    );
    setError(
      props.error === true || props.error === false ? props.error : false
    );
    setRequired(
      props.required === true || props.required === false
        ? props.required
        : false
    );
    setInitFirstItem(
      props.initFirstItem === true || props.initFirstItem === false
        ? props.initFirstItem
        : false
    );
  }, [
    props.helperText,
    props.items,
    props.value,
    props.label,
    props.labelComponent,
    props.disabled,
    props.error,
    props.required,
    props.initFirstItem,
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const currentValue = typeof value === "string" ? value.split(",") : value;
    setValue(currentValue);
    if (props.onChange) {
      props.onChange(currentValue);
    }
  };

  const setMenuItems = () => {
    if (items && items.length > 0) {
      return items.map((item, i) => {
        return (
          <MenuItem key={i} value={item.value}>
            {item.label}
          </MenuItem>
        );
      });
    }
  };

  const getItem = (value = null) => {
    if (value && items && items.length > 0) {
      let item = items.filter((x) => x.value === value);
      return item && item.length > 0 ? item[0] : null;
    } else {
      return null;
    }
  };
  return (
    <Box className={"custom-select-component " + className}>
      {label && (
        <CustomLabel
          className={"custom-label"}
          label={label}
          labelComponent={labelComponent}
          disabled={disabled}
          error={error}
          required={required}
        />
      )}
      <FormControl>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={value}
          disabled={disabled}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => {
                let item = getItem(value) ? getItem(value).label : "";
                return <Chip key={item} label={item} />;
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {setMenuItems()}
        </Select>
        {helperText}
      </FormControl>
    </Box>
  );
}
