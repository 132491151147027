import React, { Component } from 'react';

import moment from 'moment';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';
import ldsApi from "../../../api/ldsApi.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomSelectOption from '../../../components/CustomFields/CustomSelectOption.jsx';
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker.jsx';


export class GenerateDialogForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
      orderStatuses: [],
      selectedStatusId: 0,
    }
  }
  

  componentDidMount() {
    this.callStatusApi();
  }


  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
        isLoading: true,
        orderStatuses: [],
    }, () => {
      ldsApi.read('order_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.length > 0){
              arr = data.map((item, i) => {
                let value = item.id;
                let label = item.status;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              arr.unshift({
                value: 0,
                label: 'All',
                item: null
              });

              this.setState({
                isLoading: false,
                orderStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                isLoading: false,
                orderStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                isLoading: false,
                orderStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone pb={2}>
                <Grid item xs={12}>
                    <CustomDateRangePicker
                        label={'Report Range'}
                        disablePortal={false}
                        range={this.state.rangeDate}
                        onChange={(range) => {
                            this.setState({
                                rangeDate: range,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomSelectOption
                        className={'head-select'}
                        label={'Job Status'}
                        placeholder={'All'}
                        value={this.state.selectedStatusId}
                        items={this.state.orderStatuses}
                        onChange={(value, item) => {
                            this.setState({
                                selectedStatusId: value
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Generate Report
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    if (form.checkValidity() === true) {
        if(this.props.onOk){
            this.props.onOk(this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        rangeDate: this.state.rangeDate,
        selectedStatusId: this.state.selectedStatusId,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default GenerateDialogForm;