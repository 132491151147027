import React, { Component } from 'react';

import { 
    Box,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';


class CustomAlert extends Component {

    constructor(props){
        super(props);
    
        let color = (props.color) ? props.color : 'success';
        let icon = (props.icon) ? props.icon : false;

        this.state = {
            color: color,
            icon: icon,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.color !== this.state.color) {
			this.setState({
				color: nextProps.color
			});
		}
		if (nextProps.icon !== this.state.icon) {
			this.setState({
				icon: (nextProps.icon) ? nextProps.icon : false
			});
		}
	}


    render() {
        return <Box className={"custom-alert-component"}>
            <Alert 
                icon={this.state.icon} 
                color={this.state.color}
            >
                {this.props.children}
            </Alert>
        </Box>
    }
}


export default CustomAlert;