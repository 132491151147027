import React, { Component } from 'react';

import { 
    Box,
    Stepper,
    Step,
    StepLabel,
} from '@material-ui/core';


class CustomStepper extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let activeStep = (props.activeStep) ? props.activeStep : -1;
        let steps = (props.steps && props.steps.length > 0) ? props.steps : [];
        let color = (props.color) ? props.color : '';

        this.state = {
            className: className,
            activeStep: activeStep,
            steps: steps,
            color: color,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.activeStep !== this.state.activeStep) {
            let activeStep = (nextProps.activeStep) ? nextProps.activeStep : 0;
			this.setState({
				activeStep: activeStep
			});
		}
		if (nextProps.steps !== this.state.steps) {
            let steps = (nextProps.steps && nextProps.steps.length > 0) ? nextProps.steps : [];
			this.setState({
				steps: steps
			});
		}
	}


    render() {
        return <Box className={'custom-stepper-component ' + this.state.className + ' ' + this.state.color}>
            <Stepper 
                activeStep={this.state.activeStep} 
                orientation={"vertical"}
                connector={null}
            >
                {this.state.steps.map((step, index) => {
                    return <Step 
                        key={index} 
                        active={true}
                    >
                        <StepLabel icon={<Box className={(step.icon) ? 'custom-step-icon' : 'custom-step-icon-null'}>{step.icon}</Box>}>{step.label}</StepLabel>
                    </Step>
                })}
            </Stepper>
        </Box>
    }
}


export default CustomStepper;