import React, { Component } from 'react';

import {
    Box,
    Grid,
    Dialog,
    IconButton,
    Divider,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import CustomLabel from '../CustomFields/CustomLabel.jsx';


class CustomFormDialog extends Component {

    constructor(props) {
        super(props);

        let open = ((props.open === true || props.open === false) ? props.open : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let type = (props.type) ? props.type : '';
        let title = (props.title) ? props.title : '';
        let btnOkColor = (props.btnOkColor) ? props.btnOkColor : 'secondary';
        let btnCloseColor = (props.btnCloseColor) ? props.btnCloseColor : 'default';
        let btnOkVariant = (props.btnOkVariant) ? props.btnOkVariant : 'contained';
        let btnCloseVariant = (props.btnCloseVariant) ? props.btnCloseVariant : 'text';
        let btnCloseText = (props.btnCloseText) ? props.btnCloseText : 'Cancel';
        let btnOkText = (props.btnOkText) ? props.btnOkText : 'Yes';
        let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : true);
        let maxWidth = (props.maxWidth) ? props.maxWidth : 'xs';

        this.state = {
            isLoading: isLoading,
            open: open,
            type: type,
            title: title,
            btnOkColor: btnOkColor,
            btnCloseColor: btnCloseColor,
            btnOkVariant: btnOkVariant,
            btnCloseVariant: btnCloseVariant,
            btnCloseText: btnCloseText,
            btnOkText: btnOkText,
            fullWidth: fullWidth,
            maxWidth: maxWidth
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.title !== this.state.title) {
            this.setState({
                title: nextProps.title
            });
        }
        if (nextProps.open !== this.state.open) {
            this.setState({
                open: nextProps.open
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            this.setState({
                isLoading: nextProps.isLoading
            });
        }
    }


    handleClose = () => {
        this.setState({
            open: false
        });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleOk = () => {
        this.setState({
            open: false
        });

        if (this.props.onOk) {
            this.props.onOk();
        }
    }


    render() {
        return <Dialog
            disableEnforceFocus
            className={"custom-dialog-component"}
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
        >
            <Box>
                <Grid container alignItems={'center'}>
                    <Box clone>
                        <Grid item xs={true}>
                            <CustomLabel
                                label={this.state.title}
                                size={'xl'}
                            />
                        </Grid>
                    </Box>
                    <Box clone mb={1}>
                        <Grid item xs={'auto'}>
                            <IconButton aria-label="close" onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            <Divider light />
            <Box pt={2} pb={2}>
                <Grid container>
                    <Box clone>
                        <Grid item xs={12}>
                            {this.props.children}
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </Dialog>
    }
}


export default CustomFormDialog;