import React from "react";
import { Prompt } from "react-router";
import uuid from "uuid";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CustomButtom from '../../components/CustomFields/CustomButton.jsx';

class PreventTransitionPrompt extends React.Component {
  /**
   * Dialog state
   */
  state = { open: false };

  constructor() {
    super();
    
    this.location = null;

    this.__trigger = Symbol.for(`__PreventTransitionPrompt_${uuid()}`);
  }

  componentDidMount() {
    window[this.__trigger] = this.show;
  }

  componentWillUnmount() {
    delete window[this.__trigger];
  }

  render() {
    const { when, title, message } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Prompt when={when} message={this.handleTransition} />

        <Dialog open={open} onClose={this.handleClose}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButtom onClick={this.handleClose} color="default">
                    Cancel
                </CustomButtom>
                <CustomButtom onClick={this.handleOk} color="secondary" variant={'contained'} autoFocus>
                    Leave
                </CustomButtom>
            </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }


  show = allowTransitionCallback => {
    this.setState({ open: true }, () => allowTransitionCallback(false));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOk = () => {
    if(this.props.onOk){
        this.props.onOk(this.location);
    }

    this.setState({ open: false });
  };

  handleTransition = location => {
    this.location = location;

    return Symbol.keyFor(this.__trigger);
  };
}

export default PreventTransitionPrompt;
