export const externalVehicleConditions = 2;
export const fluids = 3;
export const vehicleInteriorAndEquipment = 4;
export const functionalCheckStart = 5;
export const functionalCheckDuring = 6;
export const vehicleServicing = 8;
export const tyreChange = 9;

export const getCategoryCheckList = (dataList, categoryId) => {
    // console.log("getCategoryCheckList dataList", dataList)
    const data = dataList.filter((item) => {
        const data = item.vehicle_check_list_fields.filter((filterItem) => {
          // console.log("filteritem", filterItem.vehicle_check_list_master_field)
          if(filterItem.vehicle_check_list_master_field && filterItem.vehicle_check_list_master_field?.vehicle_check_list_category_id == categoryId){
            return filterItem;
          }
        })
        if(data.length > 0) {
          return item
        }
      })
    return data;
}
export function externalVehicleCondition() {
    return [
        { label: 'Condition of vehicle bodywork, windscreen, windows, lights', value: 'Condition of vehicle bodywork, windscreen, windows, lights' },
        { label: 'Condition of windscreen wiper blades', value: 'Condition of windscreen wiper blades' },
        { label: 'Cleanness of windscreen, windows, mirrors, lights, number plate', value: 'Cleanness of windscreen, windows, mirrors, lights, number plate' },
        { label: 'Condition of tyres, tyre pressure, tyre wear', value: 'Condition of tyres, tyre pressure, tyre wear' },
        { label: 'Availability of spare wheel, jack and tools', value: 'Availability of spare wheel, jack and tools' },
        { label: 'Under vehicle inspection: leaks, loose parts, signs of tempering', value: 'Under vehicle inspection: leaks, loose parts, signs of tempering' },
        { label: 'Locking mechanism: Truck cabin & cargo area, signs of tempering', value: 'Locking mechanism: Truck cabin & cargo area, signs of tempering' },
        { label: 'Satisfactory available', value: 'Satisfactory available' },
        { label: 'Defective missing', value: 'Defective missing' },
        { label: 'Critical fault', value: 'Critical fault' },
        { label: 'Not applicable', value: 'Not applicable' },
    ];
}
// export function fluids() {
//     return [
//         { label: 'Engine oil level', value: 'Engine oil level' },
//         { label: 'Coolant level', value: 'Coolant level' },
//         { label: 'Windscreen wash level', value: 'Windscreen wash level' },
//         { label: 'Brake/clutch fluid', value: 'Brake/clutch fluid' },
//         { label: 'Power steering fluid', value: 'Power steering fluid' },
//         { label: 'Condition of battery, acid level, fixation, and connections', value: 'Condition of battery, acid level, fixation, and connections' },
//         { label: 'Oil or waste leaks', value: 'Oil or waste leaks' },
//         { label: 'Satisfactory available', value: 'Satisfactory available' },
//         { label: 'Defective missing', value: 'Defective missing' },
//         { label: 'Critical fault', value: 'Critical fault' },
//         { label: 'Not applicable', value: 'Not applicable' },
//     ];
// }

// export function vehicleInteriorAndEquipment() {
//     return [
     
//         { label: 'Condition & function of seat belts', value: 'Condition & function of seat belts' },
//         { label: 'Head restraint adjustment', value: 'Head restraint adjustment' },
//         { label: 'Mirror adjustment', value: 'Mirror adjustment' },
//         { label: 'Interior compartments (e.g. glove box) any paperwork / signs of tempering/ foreign materials', value: 'Interior compartments (e.g. glove box) any paperwork / signs of tempering/ foreign materials' },
//         { label: 'First aid kit', value: 'First aid kit' },
//         { label: 'Fire extinguisher', value: 'Fire extinguisher' },
//         { label: 'Torch Light', value: 'Torch Light' },
//         { label: 'Warning triangle', value: 'Warning triangle' },
//         { label: 'Vehicle handbook', value: 'Vehicle handbook' },
//         { label: 'Satisfactory available', value: 'Satisfactory available' },
//         { label: 'Defective missing', value: 'Defective missing' },
//         { label: 'Critical fault', value: 'Critical fault' },
//         { label: 'Not applicable', value: 'Not applicable' },
//     ];
// }