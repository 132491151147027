import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';

import { 
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/img/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/icons/arrow-down.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CircleIcon from '../../components/CircleIcon/CircleIcon.jsx';
import StatusItem from '../../components/Status/StatusItem.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';


export class Vendor extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    this.state = {
      vendorTab: 1,

      isLoadingStatus: false,
      total_in: 0,
      total_out: 0,

      isLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,

      openDialog: false,
      openDialogItem: null,
    }
  }
  

  componentDidMount() {
    this.callReadApi();
  }


  /* API */
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        page: this.state.page,
        take: this.state.limit,
        search: search,
      };

      api.read('vendors/list', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(this.state.vendorTab === 1){
              this.setState({
                data: (data.result.out && data.result.out.length > 0) ? data.result.out : [],
                total: data.total_out,
                total_in: data.total_in,
                total_out: data.total_out,
                isLoading: false,
              });
            } else {
              this.setState({
                data: (data.result.in && data.result.in.length > 0) ? data.result.in : [],
                total: data.total_in,
                total_in: data.total_in,
                total_out: data.total_out,
                isLoading: false,
              });
            }
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              total_in: 0,
              total_out: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        destroy_all: true,
      };

      api.delete('customers/' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Box clone pr={1}>
          <Grid item xs={'auto'}>
            <CustomButton 
                color={'primary'}
                onClick={() => {
                  console.log("Click");
                }}
              >
                <ImportIcon />
              </CustomButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              color={'secondary'}
              onClick={() => {
                this.props.history.push('/vendor-form');
              }}
            >
              + New Vendors
            </CustomButton>
          </Grid>
        </Box>
        <Box clone pt={{ xs: 4, md: 0 }}>
          <Grid item xs={12}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.vendorTab}
              buttons={[ "Companies Vendor", "Our Vendors" ]}
              onClick={(selected, btn) => {
                this.setState({
                  vendorTab: selected
                }, () => {
                  this.callReadApi();
                });
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* STATUS */
  customStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
          <CircleIcon />
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Companies Vendor'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Companies that assigned us as their vendors'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total IN'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{this.state.total_in}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total OUT'}
            count={<Box className={'custom-count'}>
              <ArrowDownIcon />
              <span className={'custom-count-pl'}>{this.state.total_out}</span>
            </Box>}
          />
        </Grid>
      </Box>
    </Grid>
  }
  /* END STATUS */


  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      Toolbar={this.customToolbar}
      columns={[
        { 
          title: "Company Name", 
          field: "company_name",
        },
        { 
          title: "Person In Charge", 
          field: "name",
        },
        { 
          title: "Email Address", 
          field: "email",
        },
        { 
          title: "ACTION", 
          width: '200px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/vendor-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          openDialog: true,
                          openDialogItem: row,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={true}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search Vendor'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 0, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  console.log("Click Export", this.refTable.current);
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export</Box>
              </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="vendor-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      
      <Card>
        {this.customStatus()}
      </Card>

      <Card>
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
    </Box>;
  }
}

