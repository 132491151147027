import React from 'react';

import {
    Box,
    Grow,
    ClickAwayListener
} from '@material-ui/core';


// import {
//     Box,
//     Popper,
//     Grow,
//     ClickAwayListener
// } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';

const CustomDropdown = props => {
    // const { children, element, className } = props;
    const { children, element } = props;

    const [open, setOpen] = React.useState((props.open === true || props.open === false) ? props.open : false);
    // const [disablePortal, setDisablePortal] = React.useState((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true);
    
    const anchorRef = React.useRef();


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setOpen(open => !open);

      if(props.onChange){
        props.onChange(open);
      }
    };
  
    const handleClose = event => {
      setOpen(false);

      if(props.onChange){
        props.onChange(open);
      }
    };


    React.useEffect(() => {
      setOpen(((props.open === true || props.open === false) ? props.open : false));
      //setDisablePortal(((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true));
    }, [props.open, props.disablePortal]);


    if(children) {
        return (
          <Box position={'relative'}>
            <Box
            ref={anchorRef}
            aria-haspopup="true"
            onClick={handleToggle}
            >
              {element}
            </Box>
            {/*<Popper open={open} 
                    anchorEl={anchorRef.current} 
                    transition
                    disablePortal={disablePortal}
                    className={className}
                ><Grow>*/}
            <Dialog onClose={handleClose} open={open}>
              <Grow>
                <ClickAwayListener onClickAway={handleClose} mouseEvent={'onClick'}>
                  <Box position={'relative'}>{children}</Box>
                </ClickAwayListener>
              </Grow>
            </Dialog>
            {/*</Grow>
            </Popper>*/}
          </Box>
        );
    } else {
        return null;
    }
  }

  export default CustomDropdown;