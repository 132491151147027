import React, { Component } from 'react';

import uuid from 'react-uuid'

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';
import wmsApi from "../../../api/wmsApi.jsx";

import { 
  Box,
  Grid,
  Chip,
  CircularProgress,
} from '@material-ui/core';

import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomTabs from '../../../components/Tabs/CustomTabs.jsx'

import CompanyAddressList from './CompanyAddressList.jsx';
import CompanyDepartmentList from './CompanyDepartmentList.jsx';


export class CustomerPreviewDialog extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let id = (props.id && props.id > 0) ? props.id : 0;
    let tab = (props.tab && props.tab > 0) ? props.tab : 0;

    this.state = {
        tabsValue: tab,

        isLoading: true,

        id: id,

        company_name: '',
        company_nameValidated: false,
        company_code: '',
        company_codeValidated: false,
        is_active: true,
        addresses: [],
        departments: [],
    }
  }
  

  componentDidMount() {
    if(this.state.id && this.state.id > 0){
      this.callDetailsApi(this.state.id, (data) => {
          let addresses = (data.customer_addresses && data.customer_addresses.length > 0) ? data.customer_addresses : [];
          if(addresses && addresses.length > 0){
            addresses = addresses.map((item, i) => {
              item['uuid'] = uuid();
              item['remove'] = false;
              return item;
            });
          }
  
          let departments = (data.departments && data.departments.length > 0) ? data.departments : [];
          if(departments && departments.length > 0){
            departments = departments.map((item, i) => {
              item['uuid'] = uuid();
              item['department_name'] = item.name;
              item['department_id'] = item.id;
              item['delete'] = false;
  
              if(item.contact_people && item.contact_people.length > 0){
                item.contact_people = item.contact_people.map((subItem, j) => {
                  subItem['uuid'] = uuid();
                  subItem['delete'] = false;
                  return subItem;
                });
              }
  
              return item;
            });
          }
  
          this.setState({
            company_name: data.company_name,
            company_code: data.company_code,
            is_active: data.is_active,
            addresses: addresses,
            departments: departments,
          });
        });
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.read('customers/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.customer) ? data.customer : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FORM */
  inlineRowTemplate = (component1, component2) => {
    return <Box>
        <Grid container>
            <Box clone pb={1}>
                <Grid item xs={12} md={3}>{component1}</Grid>
            </Box>
            <Box clone pb={1}>
                <Grid item xs={12} md={true}>{component2()}</Grid>
            </Box>
        </Grid>
    </Box>
  }

  setOverview = () => {
    return <Box pt={3}>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Company Name'}
                        />,
                        () => {
                            return this.state.company_name;
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Company Code'}
                        />,
                        () => {
                            return this.state.company_code;
                        }
                    )}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.inlineRowTemplate(
                        <CustomLabel
                            label={'Status'}
                        />,
                        () => {
                            return <Chip className={'status-label ' + (this.state.is_active ? 'active' : 'inactive')} label={(this.state.is_active ? 'Active' : 'Inactive')} />
                        }
                    )}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setAddresses = () => {
    return <Box>
        <CompanyAddressList 
            preview={true}
            items={this.state.addresses}
        />
    </Box>
  }

  setDepartments = () => {
    return <Box>
        <CompanyDepartmentList 
            preview={true}
            items={this.state.departments}
        />
    </Box>
  }

  setForm = () => {
      if(this.state.isLoading){
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'300px'}>
            <CircularProgress size={35} />
        </Box>
      } else {
        return <Box>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setOverview()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomTabs
                            selected={this.state.tabsValue}
                            items={[
                                {
                                    text: <CustomLabel label={'Addresses'} weight={'bold'} />,
                                    children: this.setAddresses(),
                                },
                                {
                                    text: <CustomLabel label={'Departments'} weight={'bold'} />,
                                    children: this.setDepartments(),
                                },
                            ]}
                            onChange={(val) => {
                                this.setState({
                                    tabsValue: val
                                });
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
        </Box>
      }
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */

      
  render() {
    return <Box>
        <Grid container>
            <Box clone pt={1} pb={2}>
                <Grid item xs={12}>
                    {this.setForm()}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
}

export default CustomerPreviewDialog;