import React, { Component } from 'react';

import { 
    Box,
    Stepper,
    Step,
    StepButton,
} from '@material-ui/core';


class CustomGuideStepper extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let orientation = (props.orientation) ? props.orientation : 'horizontal';
        let activeStep = (props.activeStep) ? props.activeStep : -1;
        let steps = (props.steps && props.steps.length > 0) ? props.steps : [];
        let color = (props.color) ? props.color : '';
        let alternativeLabel = ((props.alternativeLabel === false) || (props.alternativeLabel === true)) ? props.alternativeLabel : true;
        let nonLinear = ((props.nonLinear === false) || (props.nonLinear === true)) ? props.nonLinear : true;

        this.state = {
            className: className,
            orientation: orientation,
            activeStep: activeStep,
            steps: steps,
            color: color,
            alternativeLabel: alternativeLabel,
            nonLinear: nonLinear,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.activeStep !== this.state.activeStep) {
            let activeStep = (nextProps.activeStep) ? nextProps.activeStep : 0;
			this.setState({
				activeStep: activeStep
			});
		}
		if (nextProps.steps !== this.state.steps) {
            let steps = (nextProps.steps && nextProps.steps.length > 0) ? nextProps.steps : [];
			this.setState({
				steps: steps
			});
		}
	}


    render() {
        return <Box className={'custom-guide-stepper-component ' + this.state.className + ' ' + this.state.color}>
            <Stepper 
                activeStep={this.state.activeStep} 
                orientation={this.state.orientation}
                alternativeLabel={this.state.alternativeLabel}
                nonLinear={this.state.nonLinear}
            >
                {this.state.steps.map((step, index) => {
                    return <Step key={index}>
                        <StepButton onClick={() => {
                            if(this.props.onClick){
                                this.props.onClick(index);
                            }
                        }}>
                            {step.label}
                        </StepButton>
                        {/* <StepLabel>{step.label}</StepLabel> */}
                    </Step>
                })}
            </Stepper>
        </Box>
    }
}


export default CustomGuideStepper;