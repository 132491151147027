import React, { Component } from 'react';
 
import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import defaultImage from '../../assets/img/default_image.jpg';


class CustomListItem extends Component {
    constructor(props) {
        super(props);
        
        let className = (props.className) ? props.className : '';
        let text = (props.text) ? props.text : '';
        let icon = (props.icon) ? props.icon : null;
        let avatar = (props.avatar) ? props.avatar : null;
        let active = (props.active) ? props.active : null;
        let data = (props.data) ? props.data : null;
        let open = ((props.open === false) || (props.open === true)) ? props.open : false;

        this.state = {
            className: className,
            text: text,
            icon: icon,
            avatar: avatar,
            active: active,
            data: data,
            open: open,
        };
    }
 

    componentWillReceiveProps(nextProps) {
        if (nextProps.text !== this.state.text) {
            let text = (nextProps.text) ? nextProps.text : '';
            this.setState({
                text: text
            });
        }
        if (nextProps.icon !== this.state.icon) {
            let icon = (nextProps.icon) ? nextProps.icon : null;
            this.setState({
                icon: icon
            });
        }
        if (nextProps.avatar !== this.state.avatar) {
            let avatar = (nextProps.avatar) ? nextProps.avatar : null;
            this.setState({
                avatar: avatar
            });
        }
        if (nextProps.active !== this.state.active) {
            let active = (nextProps.active) ? nextProps.active : null;
            this.setState({
                active: active
            });
        }
        if (nextProps.data !== this.state.data) {
            let data = (nextProps.data) ? nextProps.data : null;
            this.setState({
                data: data
            });
        }
        if (nextProps.open !== this.state.open) {
            let open = ((nextProps.open === false) || (nextProps.open === true)) ? nextProps.open : false;
            this.setState({
                open: open
            });
        }
    }
    

    deactive = () => {
        this.setState({
            active: false
        });
    }

 
    render() {
        return <React.Fragment>
            <ListItem
                button
                className={'custom-list-item ' + (this.props.children ? 'sub-item' : 'item') + ' ' + ((this.state.className && this.state.className !== '') ? this.state.className : '') + ' ' + ((this.state.active !== null) ? ((typeof this.state.active === "function") ? this.state.active() : (this.state.active ? "active" : "")) : "")}
                onClick={(e) => {
                    if(this.props.children){
                        e.preventDefault();
                        e.stopPropagation();
            
                        this.setState({
                            open: !this.state.open,
                            active: !this.state.open,
                        }, () => {
                            if(this.props.onClick){
                                this.props.onClick(this);
                            }
                        });
                    } else {
                        if(this.props.onClick){
                            this.props.onClick(this);
                        }
                    }
                }}
            >
                {this.state.icon && <ListItemIcon>
                    {this.state.icon}
                </ListItemIcon>}
                {this.state.avatar && <ListItemAvatar>
                    <Avatar
                        alt={(this.state.avatar && this.state.avatar.text) ? this.state.avatar.text : 'Avatar'}
                        src={(this.state.avatar && this.state.avatar.url) ? this.state.avatar.url : defaultImage}
                    />
                </ListItemAvatar>}
                <ListItemText primary={this.state.text} />
                {(this.props.children) ? (this.state.open ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
            {(this.props.children) && <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List className={'sub-nav'} component="div" disablePadding>
                    {this.props.children}
                </List>
            </Collapse>}
        </React.Fragment>
    }
}

export default CustomListItem;