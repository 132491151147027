import React, { Component } from 'react';

import { 
    Box,
} from '@material-ui/core';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";

import CustomToolbar from './CustomToolbar.jsx';

import moment from 'moment'
const localizer = momentLocalizer(moment);


class CustomCalendar extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let events = (props.events && props.events.length > 0) ? props.events : [];
        let date = (props.date) ? props.date : new Date();
        let view = (props.view) ? props.view : 'month';

        this.state = {
            className: className,
            events: events,
            date: date,
            view: view,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.view !== this.state.view) {
			this.setState({
				view: nextProps.view
			});
		}
		if (nextProps.events !== this.state.events) {
            let events = (nextProps.events && nextProps.events.length > 0) ? nextProps.events : [];
			this.setState({
				events: events
			});
        }
        if (nextProps.date !== this.state.date) {
            let date = (nextProps.date) ? nextProps.date : new Date();
			this.setState({
				date: date
			});
		}
	}


    render() {
        return <Box className={'custom-calendar-component ' + this.state.className}>
             <Calendar
                localizer={localizer}
                events={this.state.events}
                defaultDate={this.state.date}
                view={this.state.view}
                views={['day', 'week', 'month']}
                components = {{
                    toolbar : CustomToolbar
                }}
                onSelectEvent={(item) => {
                    if(this.props.onClick){
                        this.props.onClick(item);
                    }
                }}
                onNavigate={(date, type) => {
                    if(this.props.onNavigate){
                        this.props.onNavigate(date, type);
                    }
                }}
                onView={(type) => {
                    if(this.props.onView){
                        this.props.onView(this.state.date, type);
                    }
                }}
                style={{ height: 650 }}
            />
        </Box>
    }
}


export default CustomCalendar;