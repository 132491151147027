import React, { Component } from 'react';
import uuid from 'react-uuid'

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import Api from "../../api/api.jsx";

import {
  Box,
  Grid,
  Container,
  Divider,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomTooltip from '../../components/Tooltip/CustomTooltip.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import CompanyAddressList from './Partial/CompanyAddressList.jsx';
import CompanyDepartmentList from './Partial/CompanyDepartmentList.jsx';
import CustomerAdminAccountForm from './Partial/CustomerAdminAccountForm.jsx';
import CustomerAdminAccountList from './Partial/CustomerAdminAccountList.jsx';

import Tour from 'reactour'


export class CustomersForm extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;

    this.state = {
      id: id,

      isLoading: false,

      company_name: '',
      company_nameValidated: false,
      company_code: '',
      company_codeValidated: false,
      is_active: true,
      addresses: [],
      departments: [],
      customer_info: [],

      isTourOpen: false,
      openSkipDialog: false,
    }
  }


  componentDidMount() {
    const { isOnBoarding } = this.context;

    if (this.state.id && this.state.id > 0) {
      this.callDetailsApi(this.state.id, (data) => {
        let addresses = (data && data.customer_addresses && data.customer_addresses.length > 0) ? data.customer_addresses : [];
        if (addresses && addresses.length > 0) {
          addresses = addresses.map((item, i) => {
            item['uuid'] = uuid();
            item['remove'] = false;
            return item;
          });
        }

        let customer_info = (data.admins && data.admins.length > 0) ? data.admins : [];
        if (customer_info && customer_info.length > 0) {
          customer_info = customer_info.map((item, i) => {
            item['uuid'] = uuid();
            item['remove'] = false;
            return item;
          });
        }

        let departments = (data.departments && data.departments.length > 0) ? data.departments : [];
        if (departments && departments.length > 0) {
          departments = departments.map((item, i) => {
            item['uuid'] = uuid();
            item['department_name'] = item.name;
            item['department_id'] = item.id;
            item['delete'] = false;

            if (item.contact_people && item.contact_people.length > 0) {
              item.contact_people = item.contact_people.map((subItem, j) => {
                subItem['uuid'] = uuid();
                subItem['delete'] = false;
                return subItem;
              });
            }

            return item;
          });
        }

        this.setState({
          company_name: data.company_name,
          company_code: data.company_code,
          is_active: data.is_active,
          addresses: addresses,
          customer_info: customer_info,
          departments: departments,
        });
      });
    }

    if (isOnBoarding) {
      this.setState({
        isTourOpen: true,
      });
    }
  }


  checkIsMainExists = (addresses) => {
    if (addresses && addresses.length > 0) {
      let isMain = addresses.filter(x => !x.remove).filter(x => x.is_main === true);

      return (isMain && isMain.length > 0) ? true : false;
    }

    return false;
  }


  /* API */
  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.read('customers/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if (callback) {
                callback((data && data.customer) ? data.customer : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if (callback) {
                callback(null);
              }
            });
          });
        });
    });
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('customers', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if (callback) {
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if (callback) {
                callback();
              }
            });
          });
        });
    });
  }

  callUpdateApi = (id, row, callback = null, errorCallback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.update('customers/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if (callback) {
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if (errorCallback) {
                errorCallback(error);
              }
            });
          });
        });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isOnBoarding } = this.context;

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
            <IconButton
              onClick={() => {
                this.props.history.push('/customers');
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
            <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={isOnBoarding ? 'auto' : true}>
            <CustomLabel
              className={'no-margin'}
              label={(this.state.id > 0) ? 'Edit Customer' : 'Add New Customer'}
              weight={'bold'}
              size={'md'}
            />
          </Grid>
        </Box>
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {this.setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton
              className={'create-customer-step'}
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            >
              <Box pl={2} pr={2}>Submit</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */


  /* FORM */
  customerInformationForm = () => {
    return <Box pb={5}>
      <Grid container>
        <Grid item xs={12}>
          <CustomLabel
            label={'Customer Information'}
            weight={'bold'}
            size={'md'}
          />
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth={'xs'}>
            <Box clone pt={5}>
              <Grid container>

                <Box clone>
                  <Grid item xs={12}>
                    <CustomInput
                      label={'Company Name'}
                      placeholder={'Enter company name'}
                      value={this.state.company_name}
                      error={this.state.company_nameValidated}
                      required={true}
                      autoFocus={true}
                      onChange={(e) => {
                        this.setState({
                          company_name: e.target.value,
                          company_nameValidated: (e.target.value !== '') ? false : true,
                        });
                      }}
                    />
                  </Grid>
                </Box>

                <Box clone>
                  <Grid item xs={12}>
                    <CustomInput
                      label={'Company Code'}
                      placeholder={'Enter company code'}
                      value={this.state.company_code}
                      error={this.state.company_codeValidated}
                      required={true}
                      autoFocus={true}
                      onChange={(e) => {
                        this.setState({
                          company_code: e.target.value,
                          company_codeValidated: (e.target.value !== '') ? false : true,
                        });
                      }}
                    />
                  </Grid>
                </Box>

                <Box clone pb={2}>
                  <Grid item xs={12}>
                    <CustomLabel
                      label={'Customer Status'}
                      labelComponent={<CustomTooltip title={'Disable your customers temporary by setting then to incative.'} />}
                    />
                  </Grid>
                </Box>

                <Box clone pr={{ xs: 0, md: 2 }} pb={{ xs: 2, md: 0 }}>
                  <Grid item xs={12} md={6}>
                    <CustomButton
                      className={'outlined-default-primary ' + (this.state.is_active ? 'active' : '')}
                      variant={'outlined'}
                      color={'primary'}
                      fullWidth={true}
                      onClick={() => {
                        this.setState({
                          is_active: true
                        });
                      }}
                    >
                      Activate Customer
                    </CustomButton>
                  </Grid>
                </Box>

                <Box clone>
                  <Grid item xs={12} md={6}>
                    <CustomButton
                      className={'outlined-default-primary ' + (!this.state.is_active ? 'active' : '')}
                      variant={'outlined'}
                      color={'secondary'}
                      fullWidth={true}
                      onClick={() => {
                        this.setState({
                          is_active: false
                        });
                      }}
                    >
                      Disable Customer
                    </CustomButton>
                  </Grid>
                </Box>

              </Grid>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { isOnBoarding, setOnBoardingCustomer } = this.context;

    this.setState({
      isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
      company_nameValidated: false,
      company_codeValidated: false,
    });


    if (this.state.company_name === '') {
      this.setState({
        company_nameValidated: true
      });
    }
    if (this.state.company_code === '') {
      this.setState({
        company_codeValidated: true
      });
    }


    if (!this.checkIsMainExists(this.state.addresses)) {
      this.setState({
        isLoading: false
      }, () => {
        apiUtil.toast('You must check the primary address!');
      });
      return;
    }


    if (form.checkValidity() === true) {
      let data = {
        company_name: this.state.company_name,
        company_code: this.state.company_code,
        is_active: this.state.is_active,
        addresses: this.state.addresses,
        departments: this.state.departments,
        // customer_info: this.state.customer_info,
      };

      if (this.state.id && this.state.id > 0) {
        const customerInfo = this.state.customer_info && this.state.customer_info.length > 0 ? this.state.customer_info[0] : null;
        console.log("before delete", customerInfo)
        if (customerInfo) {
          delete customerInfo.is_default_password;
        }

        console.log("after delete", customerInfo)
        data.customer_admins = [customerInfo];
        this.callUpdateApi(this.state.id, data, () => {
          console.log("data", data);
          if (data) {
            let filter_customer = this.state.customer_info.filter(x => !x.remove && x.id);
            if (filter_customer.length > 0) {
              let is_update_data = filter_customer.filter(x => !x.permanent_delete);
              if (is_update_data.length > 0) {
                this.updateCustomerAccount(filter_customer[0], (data) => {
                  if (data.status == false) {
                    delete data.status;
                    let error = JSON.stringify(data);
                    apiUtil.toast(error, 'check_circle', 'error');
                  } else {
                    this.setState({
                      isLoading: false
                    }, () => {
                      this.props.history.push('/customers');
                      apiUtil.toast('Successfully updated', 'check_circle');
                    });
                  }
                })

              } else {
                let is_delete_data = filter_customer.filter(x => x.permanent_delete);
                if (is_delete_data.length > 0) {

                  this.deleteCustomerAccount(is_delete_data[0], () => {
                    let new_customer_data = this.state.customer_info.filter(x => !x.remove && (x.id == undefined));
                    // if(data.status == false) {
                    if (data.status != false) {
                      if (new_customer_data.length > 0) {

                        let account_data = {
                          ...new_customer_data[0],
                          "is_lds_only": true,
                          "is_own_data_only": true,
                          "is_system_role": true,
                          "is_customer_admin": true,
                          "customer_ids": this.state.id.toString()
                        };
                        this.createCustomerAccount(account_data, (data) => {
                          if (data.status == false) {
                            delete data.status;
                            let error = JSON.stringify(data);
                            apiUtil.toast("Email existed. Please use another email.", 'check_circle', 'error');
                          } else {
                            this.setState({
                              isLoading: false
                            }, () => {
                              this.props.history.push('/customers');
                              apiUtil.toast('Successfully updated', 'check_circle');
                            });
                          }
                        })
                      } else {
                        this.setState({
                          isLoading: false
                        }, () => {
                          this.props.history.push('/customers');
                          apiUtil.toast('Successfully updated', 'check_circle');
                        });
                      }
                    } else {
                      delete data.status;
                      let error = JSON.stringify(data);
                      apiUtil.toast(error, 'check_circle', 'error');
                    }

                  })
                }
              }

            } else {
              if (this.state.customer_info.length > 0) {
                let account_data = {
                  ...this.state.customer_info[0],
                  "is_lds_only": true,
                  "is_own_data_only": true,
                  "is_system_role": true,
                  "is_customer_admin": true,
                  "customer_ids": this.state.id.toString()
                };
                this.createCustomerAccount(account_data, (data) => {
                  if (data.status == false) {
                    delete data.status;
                    // let error = JSON.stringify(data);
                    apiUtil.toast("Email existed. Please use another email.", 'check_circle', 'error');
                  } else {
                    this.setState({
                      isLoading: false
                    }, () => {
                      this.props.history.push('/customers');
                      apiUtil.toast('Successfully updated', 'check_circle');
                    });
                  }

                })
              } else {
                this.setState({
                  isLoading: false
                }, () => {
                  this.props.history.push('/customers');
                  apiUtil.toast('Successfully Created!!', 'check_circle');
                });
              }
            }
          } else {
            this.setState({
              isLoading: false
            }, () => {
              this.props.history.push('/customers');
              apiUtil.toast('Successfully Created!!', 'check_circle');
            });
          }
        }, (error) => {
          if (error) {
            apiUtil.toast(error, 'warning', 'error');
          } else {
            apiUtil.toast('Error!!', 'warning', 'error');
          }
        });
      } else {
        if (data && data.addresses && data.addresses.length > 0) {
          data.addresses = data.addresses.filter(x => !x.remove);
        }

        if (data && data.departments && data.departments.length > 0) {
          data.departments = data.departments.filter(x => !x.delete).map((item, i) => {
            item.contact_people = item.contact_people.filter(x => !x.delete);
            return item;
          });
        }
        if (this.state.customer_info.length > 0) {
          let account_data = {
            ...this.state.customer_info[0],
            "is_lds_only": true,
            "is_own_data_only": true,
            "is_system_role": true,
            "is_customer_admin": true,
          };
          data.customer_admins = this.state.customer_info
        }

        if (!isOnBoarding) {
          this.callCreateApi(data, (result) => {
            if (result) {
              if (this.state.customer_info.length > 0) {
                let account_data = {
                  ...this.state.customer_info[0],
                  // "is_default_password": true, 
                  "is_lds_only": true,
                  "is_own_data_only": true,
                  "is_system_role": true,
                  "is_customer_admin": true,
                  "customer_ids": result.customer.id.toString()
                };
                this.createCustomerAccount(account_data, (data) => {

                  if (data.status == false) {
                    apiUtil.toast("Email existed. Please use another email.", 'check_circle', 'error');
                  } else {
                    this.setState({
                      isLoading: false
                    }, () => {
                      this.props.history.push('/customers');
                      apiUtil.toast('Successfully Created', 'check_circle');
                    });
                  }

                })
              }

            } else {
              this.setState({
                isLoading: false
              }, () => {
                this.props.history.push('/customers');
                apiUtil.toast('Something went wrong', 'check_circle', 'error');
              });
            }


          });
        } else {
          setOnBoardingCustomer(JSON.stringify(data));
          this.props.history.push('/resources/vehicle');
          apiUtil.toastOnBoarding('Successfully Created', 'check_circle');
        }
      }
    } else {
      this.setState({
        isLoading: false
      });
    }
  }
  /* END SUBMIT */
  createCustomerAccount = (row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.createCustomerAccount('admins', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          // this.state.isLoading(false);       
          if (callback) {
            callback(data.result);
          }
        }, (error, type) => {
          // this.state.isLoading(false);
          if (callback) {
            console.log(error);
            callback({ status: false, ...error });
          }
        });
      }).catch(
        error => {
          console.log("here");
          console.log(error)
        }
      )
  }
  updateCustomerAccount = (row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.updateCustomerAccount('admins/' + row.id, row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          if (callback) {
            callback(data.result);
          }
        }, (error, type) => {
          console.log("error", error)
          if (callback) {
            callback({ status: false, ...error });
          }
        });
      }).catch(
        error => console.log(error)
      )
  }
  deleteCustomerAccount = (row, callback = null) => {
    // this.state.isLoading(true);
    const { accessToken } = this.context;
    Api.deleteCustomerAccount('admins/' + row.id, {}, accessToken)
      .then((result) => {
        if (callback) {
          callback(result);
        }
      }).catch(
        error => {
          console.log(error);
          callback({ status: false, ...error });
        }

      )
  }

  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={0}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Operator',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if (index === 0) {
            this.props.history.push('/customers');
          } else if (index === 1) {
            this.props.history.push('/resources/vehicle');
          } else if (index === 2) {
            this.props.history.push('/resources/transport-operator');
          } else if (index === 3) {
            this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>Fill in the form and create a customer.</Box>
      <Box textAlign={'center'}>
        <CustomButton
          color={'primary'}
          href={'/'}
          onClick={(e) => {
            e.preventDefault();

            this.setState({
              openSkipDialog: true,
            });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.create-customer-step',
            content: <Box>
              <h2>Create Customer</h2>
              <p>Fill in the required data to create a customer.</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        accentColor={'#64CCC9'}
        badgeContent={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  render() {
    return <Box className="customers-form-page" >
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <StickyPageHeader isSmall={true}>
          {this.customStickyPageHeader()}
        </StickyPageHeader>

        <Container maxWidth="lg">
          {
            (!this.state.isLoading)
              ?
              <Card>
                {this.customerInformationForm()}
                <CompanyAddressList
                  items={this.state.addresses}
                  onAddNew={(data) => {
                    this.setState({
                      addresses: data,
                    });
                  }}
                  onEdit={(data) => {
                    this.setState({
                      addresses: data,
                    });
                  }}
                  onRemove={(data) => {
                    this.setState({
                      addresses: data,
                    });
                  }}
                />
                <CustomerAdminAccountList
                  items={this.state.customer_info}
                  onAddNew={(data) => {
                    this.setState({
                      customer_info: data,
                    });
                  }}
                  onEdit={(data) => {
                    this.setState({
                      customer_info: data,
                    });
                  }}
                  onRemove={(data) => {
                    this.setState({
                      customer_info: data,
                    });
                  }}
                />
                <CompanyDepartmentList
                  items={this.state.departments}
                  onAddNew={(data) => {
                    this.setState({
                      departments: data,
                    });
                  }}
                  onEdit={(data) => {
                    this.setState({
                      departments: data,
                    });
                  }}
                  onRemove={(data) => {
                    this.setState({
                      departments: data,
                    });
                  }}
                />
              </Card>
              :
              <Card>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
                  <CircularProgress size={35} />
                </Box>
              </Card>
          }
        </Container>

      </form>

      {this.setTour()}
      {this.skipDialog()}
    </Box>
  }
}
