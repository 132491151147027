import React, { Component } from 'react';

import { 
    Box,
    Button,
    ButtonGroup,
} from '@material-ui/core';


class GroupButton extends Component {
  
	constructor(props){
		super(props);

        let className = props.className ? props.className : "";
        let selected = props.selected ? props.selected : 0;
        let color = props.color ? props.color : "default";
        let size = props.size ? props.size : "medium";
        let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let buttons = (props.buttons && props.buttons.length > 0) ? props.buttons : [];
        
		this.state = {
            color: color,
            fullWidth: fullWidth,
            size: size,
            disabled: disabled,
            buttons: buttons,
            selected: selected,
            className: className
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.color !== this.state.color) {
			this.setState({
				color: nextProps.color
			});
		}
        if (nextProps.fullWidth !== this.state.fullWidth) {
			this.setState({
				fullWidth: nextProps.fullWidth
			});
		}
        if (nextProps.size !== this.state.size) {
			this.setState({
				size: nextProps.size
			});
		}
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
		}
        if (nextProps.selected !== this.state.selected) {
			this.setState({
				selected: nextProps.selected
			});
		}
        if (nextProps.buttons !== this.state.buttons) {
            let buttons = (nextProps.buttons && nextProps.buttons.length > 0) ? nextProps.buttons : [];
			this.setState({
				buttons: buttons
			});
		}
    }
    
    
    setButtons = () => {
        if(this.state.buttons && this.state.buttons.length > 0) {
            return this.state.buttons.map((btn, i) => {
                return <Button 
                    className={(this.state.selected === i) ? 'selected' : ''}
                    key={i}
                    onClick={(e) => {
                        if(this.props.onClick){
                            this.props.onClick(i, btn);
                        }
                    }}
                >
                    {btn}
                </Button>
            });
        }
    }

	render() {
        return <Box 
            className={"group-button-component " + this.state.className}
        >
            <ButtonGroup
                variant={'contained'}
                color={this.state.color}
                size={this.state.size}
                disabled={this.state.disabled}
                fullWidth={this.state.fullWidth}
            >
                {this.setButtons()}
            </ButtonGroup>
		</Box>;
	}
}

export default GroupButton;
