import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import logo from '../../assets/img/logo_black.png';
import signup_image from '../../assets/img/signup-bg.png';

export default function SignUp() {

  // let history = useHistory();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
  };

  return (
    <div className="signup container">
    <div className="signup-left">
      <div className="top-menu">
        <div className="logo"><img src={logo} alt=""/></div>
      </div>
      <div className="middle-text">
        <div className="middle-text-title">Workforce Management Reimagiine</div>
        <div className="middle-text-description">Keep your mobile workers organized, on track and optimize your workflow
      with worknode workforce management software.</div>
      </div>
      
      <div className="signup-bg"><img src={signup_image} /></div>
    </div>
    <div className="signup-right" component="main" maxWidth="sm">
      <div className="top-menu">
        <div className="logo"></div>
        <div className="signin-acc"> 
        <div>Already have an account?</div>
        <Button variant="contained" color="primary" href="/Sign-in">
          Login
        </Button>
        </div>
      </div>
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={3} mb={3}>
          <Alert severity="error">
            {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })}
          </Alert>
        </Box>

        
      }
      <div className="form-wrapper">
      <form noValidate onSubmit={handleSubmit}>

        <Box textAlign="center" mb={3} >
          <div className="form-description"  color="success.main">
          <Box color="success.main"> A confirmation mail has been sent to you. Please check your email.</Box>
           
          </div>
        </Box>
    
      </form>
      {/* <Box mt={6}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="/">TaskkMe</Link>
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box> */}
      </div>
    </div>
    </div>
  );
}