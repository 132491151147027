import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";
import {
  getCategoryCheckList,
  externalVehicleConditions,
  fluids,
  vehicleInteriorAndEquipment,
  functionalCheckStart,
  functionalCheckDuring,
  vehicleServicing,
} from "./helper";
import CustomDateRangePicker from "../../../components/CustomFields/CustomDateRangePicker.jsx";
import { 
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Divider 
} from "@material-ui/core";
import { ReactComponent as ExportIcon } from "../../../assets/img/icons/export.svg";
import CustomButton from "../../../components/CustomFields/CustomButton.jsx";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CustomLabel from "../../../components/CustomFields/CustomLabel.jsx";
import CustomSelectOption from "../../../components/CustomFields/CustomSelectOption.jsx";
import moment from 'moment';

/**
 * @todo
 * fix pagination.
 */
export const VehicleCheckList = (props) => {
  const { accessToken } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [activeInactiveCustomers, setActiveInactiveCustomers] = useState(0);
  const [data, setData] = useState([]);
  const [sourceData, setSouceData] = useState([]);
  const [recordedIdList, setRecordedIdList] = useState([]);
  
  let rangeDateInitial = {
    startDate: moment().format(apiUtil.getDefaultDateFormat()),
    endDate: moment().format(apiUtil.getDefaultDateFormat()),
  };
  const [currentSubmittedId, setCurrentSubmittedId] = useState(null);

  let rangeDateItem = localStorage.getItem("vehicle_servicing" + "_rangeDate");
  if (rangeDateItem && rangeDateItem !== "") {
    try {
      rangeDateInitial = JSON.parse(rangeDateItem);
    } catch (e) {}
  }
  const [rangeDate, setRangeDate] = useState(rangeDateInitial);
  useEffect(() => {
    callReadApi(rangeDate.startDate, rangeDate.endDate);
    return () => {};
  }, [page, searchVal, activeInactiveCustomers]);

  /* API */
  const callReadApi = (startDate, endDate) => {
    let param = {
      start_date: moment(startDate).format(apiUtil.getDefaultDateTimeFormat()),
      end_date: moment(endDate).format(apiUtil.getDefaultDateTimeFormat()),
      vehicle_id: props.vehicleId,
      description: 'Vehicle checklist'
    };
    Api.read("vehicle_check_list_records", param, accessToken).then(
      (results) => {
        apiUtil.parseResult(
          results,
          (data) => {
            const dataArr =
              data.result && data.result.length > 0 ? data.result : [];
            const dataList = dataArr.filter((item) => {
              if (item.vehicle_check_list_fields.length > 0) {
                return item;
              }
            });
            const ids = dataArr.map((item) => {
              return { value: item.id, label: moment(item.created_at).format(apiUtil.getDefaultDateTimeFormat()) };
            });
            setRecordedIdList(ids);
            setData(dataList.length > 0 ? [dataList[0]] : []);
            setSouceData(dataList);
            setCurrentSubmittedId(dataList.length > 0 ? dataList[0].id: null);
          },
          (error, type) => {
            setData([]);
          }
        );
      }
    );
  };
  
  const callExportApi = (startDate, endDate) => {
    let param = {
      start_date: moment(startDate).format(apiUtil.getDefaultDateFormat()),
      end_date: moment(endDate).format(apiUtil.getDefaultDateFormat()),
      vehicle_id: props.vehicleId,
      description: 'Vehicle checklist'
    };
    Api.exportXLS("export_check_list", param, accessToken).then(
      (results) => {
        apiUtil.parseResult(
          results,
          (response) => {
            apiUtil.saveToFile(
              response,
              `Vehicle Checklist ${moment(startDate).format("YYYY-MM-DD")} - ${moment(
                endDate
              ).format("YYYY-MM-DD")}.xls`
            );
          },
          (error, type) => {
            console.log("error", error, type);
            apiUtil.toast(error, 'warning', 'error');
          }
        );
      }
    );
  };

  const renderData = (data) => {
    return (
      <>
        {data.map((item, key) => {
          return (
            <>
              <span style={{ display: "block" }}>
                {item.vehicle_check_list_master_field && (
                  <div style={{fontWeight: 'bold'}}>
                  {key === 0 && item.vehicle_check_list_master_field.vehicle_check_list_category?.name}
                  </div>
                )}
                {item.vehicle_check_list_master_field && (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: "50%", display: "inline-block" }}>
                      {item.vehicle_check_list_master_field?.name}
                    </span>
                    :{" "}
                    {item.vehicle_check_list_master_field?.name == 'Image' && item.text_field && item.text_field != ''
                      ? <div style={{ width: "49%", display: "inline-block" }}>
                          <GridList>
                              {item.text_field.split(',').map((item, i) => {
                                  return <GridListTile key={i}>
                                      <img src={item} alt={'vehicle-img'} />
                                      <GridListTileBar
                                          title={<Box style={{ color: 'white' }}>{item}</Box>}
                                          actionIcon={
                                              <IconButton
                                                  className={'icon-white'}
                                                  onClick={() => {
                                                      var win = window.open(item, '_blank');
                                                      win.focus();
                                                  }}
                                              >
                                                  <OpenInNewIcon />
                                              </IconButton>
                                          }
                                      />
                                  </GridListTile>
                              })}
                          </GridList>
                        </div>
                      : <span>{item.vehicle_check_list_field_selection?.name}</span>}
                  </div>
                )}
              </span>
            </>
          );
        })}
      </>
    );
  };

  const renderCheckList = (dataList) => {
    if (!data || data?.length === 0) {
      return null;
    }
    console.log("dataList", dataList);
    return (
      <>
        <div style={{ fontWeight: "bold", margin: "13px auto" }}>
          {/* {categoryName} */}
        </div>
        <div style={{ margin: "13px auto" }}>
          Submitted by: {dataList && dataList?.length > 0 && dataList[0].worker ? dataList[0].worker.first_name.trim() + " " + dataList[0].worker.last_name.trim() : "-"}
        </div>
        {dataList.map((itemList) => {
          return renderData(itemList.vehicle_check_list_fields);
        })}
      </>
    );
  };

  return (
    <Box className="vehicle-checklist-page">
      <div>
        <Grid item xs={12}>
          <CustomLabel
            label={"Vehicle CheckList"}
            weight={"bold"}
            size={"md"}
          />
          <Divider light />
        </Grid>
        <span style={{ display: "inline-block", width: "30%" }}>
          <CustomDateRangePicker
            className={"select-dates-step"}
            range={rangeDate}
            onChange={(range) => {
              localStorage.setItem(
                "vehicle" + "_rangeDate",
                JSON.stringify(range)
              );
              setRangeDate(range);
              callReadApi(range.startDate, range.endDate);
            }}
          />
        </span>
        <div style={{ display: "flex", marginTop: "13px", alignItems: "end" }}>
          <div style={{ width: "30%" }}>
          <CustomSelectOption
            required={true}
            label={"Submitted list"}
            value={currentSubmittedId}
            items={recordedIdList}
            onChange={(value, item) => {
              setCurrentSubmittedId(value);
              const newData = sourceData.filter((item) => item.id === value);
              setData(newData);
            }}
          />
          </div>
          <div style={{ marginLeft: "15px", marginBottom: "20px" }}>
          <CustomButton 
              color={'primary'}
              onClick={() => {
                callExportApi(rangeDate.startDate, rangeDate.endDate);
              }}
          >
            <ExportIcon />
            <Box display={'inline-block'} pl={1}>Export</Box>
          </CustomButton>
          </div>
        </div>
        {renderCheckList(
          data,
        )}
        {/* {renderCheckList(data, fluids, "Fluids")}
        {renderCheckList(
          data,
          vehicleInteriorAndEquipment,
          "VEHICLE INTERIOR AND EQUIPMENT"
        )}
        {renderCheckList(
          data,
          functionalCheckStart,
          "FUNCTIONAL CHECKS BEFORE STARTING THE JOURNEY"
        )}
        {renderCheckList(
          data,
          functionalCheckDuring,
          "FUNCTIONAL CHECKS DURING THE JOURNEY"
        )} */}
      </div>
    </Box>
  );
};
