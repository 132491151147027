import React, { useState, useEffect, useRef, useContext } from 'react'
import BreadCrumb from "../../../components/Navs/Breadcrumb"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import StickyPageHeader from '../../../components/PageHeader/StickyPageHeader.jsx';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import Card from '../../../components/Card/Card.jsx';
import { useHistory } from 'react-router-dom';
import Table from '../../../components/Table/Table.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CloseIcon from '@material-ui/icons/Close';
import CircleIcon from '../../../components/CircleIcon/CircleIcon.jsx';
import StatusItem from '../../../components/Status/StatusItem.jsx';
import CustomGuideStepper from '../../../components/Stepper/CustomGuideStepper.jsx';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/search.svg';
import { ReactComponent as EditIcon } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../../assets/img/icons/export.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/img/icons/arrow-up.svg';
import apiUtil from "../../../api/apiUtil.jsx";
// import Api from "../../../api/Api.jsx";
import Api from "../../../api/api.jsx";

import Tour from 'reactour'


/**
 * @todo
 * fix pagination.
 */
export const Vehicle = props => {
  const history = useHistory();
  const refTableSearch = useRef();
  const refTable = useRef();
  const { accessToken, isOnBoarding, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = useContext(AuthContext)
  const [statusBar, setStatusBar] = useState({
    totalTrips: 0,
    totalVechicles: 0,
  })
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [total, setTotal] = useState(0);
  const [activeInactiveCustomers, setActiveInactiveCustomers] = useState(0)
  const [limit, setLimit] = useState(apiUtil.getDefaultPageSize());
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogItem, setOpenDialogItem] = useState(null);
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [openSkipDialog, setOpenSkipDialog] = useState(false)

  useEffect(() => {
    if (isOnBoarding) {
      setIsTourOpen(true);
    }

    callReadApi(searchVal);
    return () => {
    }
  }, [page, searchVal, activeInactiveCustomers])

  /* STICKY PAGE HEADER */
  const CustomStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb xs={isOnBoarding ? 'auto' : true} />
        {isOnBoarding && <Box clone pr={1}>
          <Grid item xs={12} sm={true}>
            {setStepper()}
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton
              className={'new-vehicle-step'}
              color={'secondary'}
              onClick={() => {
                history.push('/resources/vehicle-form')
              }}
            >
              + New Vehicle
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* API */
  const callReadApi = (search = '') => {
    let param = {
      page: page,
      take: limit,
      search: search
    };
    setIsLoading(true);
    Api.read('vehicles', param, accessToken).then((results) => {

      apiUtil.parseResult(results, (data) => {
        const dataArr = (data.result && data.result.length > 0) ? data.result : []
        setData(dataArr);
        setTotal(data.total);
        setStatusBar({
          totalVechicles: data.total,
          totalTrips: 555
        })
        setIsLoading(false);
      }, (error, type) => {
        setData([]);
        setTotal(0);
        setIsLoading(false);
      });
    });
    // const totalinActivedata = Api.read('ve', {...param, disabled : true}, accessToken)
    // const totalActivedata = Api.read('workers', {...param, disabled : false}, accessToken)
    // Promise.all([getData,totalinActivedata,totalActivedata]).then( (results) => {
    //   console.log(results)
    //     apiUtil.parseResult(results[0], (data) => {
    //       const dataArr = (data.result && data.result.length > 0) ? data.result : []
    //       setData(dataArr);
    //       setTotal(data.total);
    //       const disabledTotal = results[1].data.data.total
    //       const activeTotal = results[2].data.data.total
    //       setStatusBar({
    //         total: activeTotal + disabledTotal,
    //         active: activeTotal,
    //         disabled: disabledTotal 
    //       })
    //       setIsLoading(false);
    //     }, (error, type) => {
    //       setData([]);
    //       setTotal(0);
    //       setIsLoading(false);
    //     });
    // });
  }
  const callUpdateIsActiveApi = (row, callback = null) => {
    Api.update('vehicles/' + row.id, row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          if (callback) {
            callback();
          }
        }, (error, type) => {

        });
      });
  }

  const callDeleteApi = (row, callback = null) => {
    // setIsLoading(true);
    let param = {
      destroy_all: true,
    };
    Api.delete('vehicles/' + row.id, param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          console.log("asdf", data);
          // setIsLoading(false);
          if (callback) {
            callback();
          }
        }, (error, type) => {
          // setIsLoading(false);
          if (callback) {
            callback();
          }
        });
      });
  }

  const callExportCsvApi = (search = '') => {
    let param = {
      page: 1,
      take: 999,
      search: search
    };

    setIsLoading(true);

    Api.read('vehicles', param, accessToken).then((results) => {
      apiUtil.parseResult(results, (data) => {
        const dataArr = (data.result && data.result.length > 0) ? data.result : []

        if (dataArr.length > 0) {
          for (var i = 0; i < dataArr.length; i++) {
            dataArr[i].transport_operator = dataArr[i].workers.map(x => x.first_name + " " + x.last_name).join(", ");
          }
        }

        const fileHeaders = [
          'plate_no',
          'transport_operator',
          'manufacturer',
          'model',
          'load_capacity'
        ];

        const fileHeaderNames = [
          'Plate Number',
          'Transport Operator',
          'Manufacturer',
          'Model(kg)',
          'Load Capicity(kg)'
        ];

        apiUtil.downloadCSV("Vehicles.csv", dataArr, fileHeaders, fileHeaderNames);

        setIsLoading(false);
      }, (error, type) => {
        setIsLoading(false);
      });
    });
  }
  /* End API */
  /* STATUS */
  const CustomStatus = () => {
    return <Grid container alignItems={'center'}>
      <Box clone>
        <Grid item xs={'auto'}>
          <CircleIcon />
        </Grid>
      </Box>
      <Box clone pl={3}>
        <Grid item xs={true}>
          <CustomLabel
            className={'no-margin'}
            label={'Transport Vehicle Overview'}
            weight={'bold'}
            size={'md'}
          />
          <CustomLabel
            className={'no-margin'}
            label={'Based on recent activities'}
            size={'xs'}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Transport Vehicle'}
            count={<Box className={'custom-count'}>
              <ArrowUpIcon />
              <span className={'custom-count-pl'}>{statusBar.totalVechicles}</span>
            </Box>}
          />
        </Grid>
      </Box>
      <Box clone ml={4}>
        <Grid item xs={12} lg={2}>
          <StatusItem
            text={'Total Trips Completed'}
            count={<Box className={'custom-count'}>
              <span className={'custom-count-pl'}>{statusBar.totalTrips}</span>
            </Box>}
          />
        </Grid>
      </Box>
    </Grid>
  }

  const customToolbar = () => {
    return <Box>
      <Grid container>
        <Box clone order={{ xs: 3, md: 1 }}>
          <Grid item xs={12} md={3}>
            <CustomInput
              ref={refTableSearch}
              placeholder={'Search plate number'}
              value={searchVal}
              endAdornment={
                searchVal.length < 1 ? <IconButton
                  onClick={(e) => {
                    setSearchVal(refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton> : <IconButton>
                  <CloseIcon
                    fontSize="small"
                    onClick={(e) => {
                      setSearchVal("");
                    }} /></IconButton>
              }
              onPressEnter={(e) => {
                setSearchVal(refTableSearch.current.state.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={{ xs: 2, md: 0 }} order={{ xs: 1, md: 2 }}>
          <Grid item xs={12} md={true}>
            <CustomButton
              color={'primary'}
              onClick={() => {
                console.log("Click");
                callExportCsvApi(searchVal);
              }}
            >
              <ExportIcon />
              <Box display={'inline-block'} pl={1}>Export</Box>
            </CustomButton>
          </Grid>
        </Box>

      </Grid>
    </Box>
  }
  /* TABLE */
  const CustomTable = () => {
    return <Table
      ref={refTable}
      isLoading={isLoading}
      grouping={true}
      limit={limit}
      page={page}
      total={total}
      data={data}
      onPageChange={(page) => {
        setPage(page);
        // useEffect  will call the api again when it load
      }}
      Toolbar={customToolbar}
      columns={[
        {
          title: "Plate Number",
          field: "plate_no",
        },
        {
          title: "Transport Operator",
          render: (row) => {
            return row.workers.map(x => x.first_name + " " + x.last_name).join(", ")
          }
        },
        {
          title: "Manufacturer",
          field: "manufacturer"
        },
        {
          title: "Model(kg)",
          field: "model"
        },
        {
          title: "Load Capicity(kg)",
          field: "load_capacity"
        },
        {
          title: "Trips Completed",
          field: ""
        },
        {
          title: "ACTION",
          width: '230px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container justify={'center'}>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        history.push({
                          pathname: '/resources/vehicle-form',
                          state: {
                            id: row.id
                          }
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={'auto'}>
                    <IconButton
                      onClick={() => {
                        setOpenDialog(true);
                        setOpenDialogItem(row)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }
  /* DIALOG */
  const CustomDialogPopup = () => {
    // console.log(openDialogItem)
    return <CustomDialog
      open={openDialog}
      title={'Delete'}
      onClose={() => {
        setOpenDialog(false);
        setOpenDialogItem(null);
      }}
      onOk={() => {
        let row = openDialogItem;
        callDeleteApi(row, () => {
          setOpenDialog(false);
          setOpenDialogItem(null);
          callReadApi(searchVal);
          apiUtil.toast('Successfully Deleted', 'check_circle');
          // });
        });
      }}
    >
      {/* add openDialog item worker name */}
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  /* STEPPER */
  const setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={1}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Operator',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if (index === 0) {
            history.push('/customers');
          } else if (index === 1) {
            // history.push('/resources/vehicle');
          } else if (index === 2) {
            history.push('/resources/transport-operator');
          } else if (index === 3) {
            history.push('/job-list');
          }
        }}
      />
      <Box textAlign={'center'}>This is the Vehicle list page. Click <b className={'txt-second'}>New Vehicle</b> to begin</Box>
      <Box textAlign={'center'}>
        <CustomButton
          color={'primary'}
          href={'/'}
          onClick={(e) => {
            e.preventDefault();

            setOpenSkipDialog(true);
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  const setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.new-vehicle-step',
            content: <Box>
              <h2>Create Vehicle</h2>
              <p>Click here to create a new vehicle</p>
            </Box>,
          },
        ]}
        isOpen={isTourOpen}
        rounded={10}
        accentColor={'#64CCC9'}
        badgeContent={'#64CCC9'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={false}
        prevButton={<></>}
        nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          setIsTourOpen(false);
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  const skipDialog = () => {
    return <CustomDialog
      open={openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        setOpenSkipDialog(false);
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          setOnBoardingCustomer(null);
          setOnBoardingVehicle(null);
          setOnBoardingDriver(null);
          setOnBoardingJob(null);

          setOpenSkipDialog(false);

          apiUtil.toastOnBoarding('Done', 'check_circle');
          history.push('/')
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */


  return <Box className="vehicle-page">
    <StickyPageHeader>
      <CustomStickyPageHeader />
    </StickyPageHeader>
    <Card>
      <CustomStatus />
    </Card>
    <Card>
      <CustomTable />
    </Card>
    <CustomDialogPopup />
    {setTour()}
    {skipDialog()}
  </Box>;
}