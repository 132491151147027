import React, { Component } from 'react';
import { read as readXsl, utils as xslUtils } from 'xlsx';
import uuid from 'uuid';
import moment from 'moment';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import wmsApi from "../../../api/wmsApi.jsx";
import Api from "../../../api/api.jsx";

import { 
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomSelectOption from '../../../components/CustomFields/CustomSelectOption.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';
import CustomAlert from '../../../components/Alert/CustomAlert.jsx';
import Table from '../../../components/Table/Table.jsx';
import {RoleAccessService} from '../../../data/role-access'; 

const DataFieldType = {
    Job: 1,
    Step: 2,
    Item: 3,
}


export class JobImportDialog extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let formatTime = localStorage.getItem('Time Format') ? localStorage.getItem('Time Format') : '12-Hour';
    let timeFormat = (formatTime === '12-Hour') ? 'hh:mm A' : 'HH:mm';

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let file = (props.file) ? props.file : null;
    let dataFields = [
        { type: DataFieldType.Job, label: 'Job date', value: '1', col: 'drop_off_date', required: true, disabled: false, dataType: 'date' },
        { type: DataFieldType.Job, label: 'Job time', value: '2', col: 'drop_off_time_planned', required: false, disabled: false, dataType: 'datetime' },
        { type: DataFieldType.Job, label: 'Recipient name', value: '3', col: 'drop_off_name', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Job description', value: '4', col: 'drop_off_description', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Recipient address', value: '5', col: 'drop_off_address', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Company code', value: '6', col: 'company_code', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Tracking/Do number', value: '7', col: 'item_tracking_number', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Notify Customer', value: '8', col: 'is_notify_customer', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Job, label: 'Send Tracking link', value: '9', col: 'is_tracking_link_required', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Job, label: 'Person in charge', value: '10', col: 'drop_off_pic', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Recipient email', value: '11', col: 'notification_email', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Job, label: 'Person in charge tel', value: '12', col: 'drop_off_contact_no', required: false, disabled: false, dataType: 'string' },
        
        { type: DataFieldType.Job, label: 'Job Type', value: '23', col: 'job_type', required: false, disabled: false, dataType: 'string', colIndex: 13 },
        { type: DataFieldType.Job, label: 'Driver Notes', value: '24', col: 'driver_notes', required: false, disabled: false, dataType: 'string', colIndex: 14 },

        { type: DataFieldType.Step, label: 'Step Address', value: '14', col: 'address', required: true, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Step Name', value: '15', col: 'name', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Require signature', value: '16', col: 'signature', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Step, label: 'Require scanning', value: '17', col: 'scanning', required: false, disabled: false, dataType: 'checkbox' },
        { type: DataFieldType.Step, label: 'Recipient Name', value: '25', col: 'job_step_pic', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Recipient Email', value: '26', col: 'notification_email', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Step, label: 'Recipient Phone', value: '27', col: 'job_step_pic_contact', required: false, disabled: false, dataType: 'string' },

        { type: DataFieldType.Item, label: 'Description', value: '18', col: 'desc', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'Quantity', value: '19', col: 'qty', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'UOM', value: '20', col: 'uom', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'Weight', value: '21', col: 'weight', required: false, disabled: false, dataType: 'string' },
        { type: DataFieldType.Item, label: 'Remarks', value: '22', col: 'remarks', required: false, disabled: false, dataType: 'string' },
    ];
    const grantedAccess = new RoleAccessService();
    const currentRole = window.localStorage.getItem('current_role') || null;
    if(grantedAccess.isShouldShowJobAccess(currentRole, "job_assign")){
        dataFields.push({ type: DataFieldType.Job, label: 'Assigned Driver ID', value: '13', col: 'drop_off_worker_id', required: false, disabled: false, dataType: 'string' });
    }
    this.dataFieldsPom = JSON.parse(JSON.stringify(dataFields));

    this.state = {
        isLoading: isLoading,
        file: file,
        importedSheetData: [],
        headers: [],
        excelRows: [],

        dataFields: dataFields,

        columns: [],
        rows: [],

        isLoadingSteps: false,
        columnsSteps: [],
        rowsSteps: [],

        isLoadingItems: false,
        columnsItems: [],
        rowsItems: [],

        status: [],
        isLoadingInitCalls: true,

        refresh: false,

        timeFormat: timeFormat,

        isLoadingAppSettings: false,
        rule: null,

        isFirstTime: false,
    }
  }
  

  componentDidMount() {
    if(this.state.file){
        this.callStatusApi((data, total) => {
            if(data && data.length > 0){
              let status = data.map((item, i) => {
                return {
                  id: item.status_details.id,
                  status: item.status_details.status,
                  text: item.status_details.status,
                  total: item.total_job,
                  item: item.status_details,
                };
              });
        
              this.setState({
                status: status
              });
            }
        });

        this.callAppSettingsApi((rule) => {
            if(rule){
                let dataFields = this.state.dataFields;

                if(rule.json_data && rule.json_data !== ''){
                    try {
                        let dataFieldsItems = JSON.parse(rule.json_data);
                        if(Array.isArray(dataFieldsItems)){
                          dataFields = dataFieldsItems;
                          this.dataFieldsPom = JSON.parse(JSON.stringify(dataFieldsItems));
                        }
                    } catch(e){
                        this.setState({
                            isFirstTime: true
                        });
                    }
                }

                this.setState({
                    rule: rule,
                    dataFields: dataFields,
                }, () => {
                    // // Reset Rule - TEST
                    // let newRule = this.state.rule;
                    // newRule.json_data = {empty:true};
                    // this.callUpdateAppSettingsApi(newRule);

                    this.loadWorkSheet();
                });
            } else {
                this.loadWorkSheet();
            }
        });
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.file !== this.state.file) {
        let file = (nextProps.file) ? nextProps.file : null;
        this.setState({
            file: file
        });
    }
  }


  /* API */
  callAppSettingsApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingAppSettings: true
    }, () => {
      Api.read('application_company_settings', {}, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result && data.result.length > 0){
                let rule = null;
                let ruleItem = data.result.filter(x => x.rule === 'import_job_mapped');
                if(ruleItem && ruleItem.length > 0){
                  rule = ruleItem[0];
                }
  
                this.setState({
                    isLoadingAppSettings: false,
                }, () => {
                  if(callback){
                    callback(rule);
                  }
                });
            } else {
              this.setState({
                isLoadingAppSettings: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                isLoadingAppSettings: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callUpdateAppSettingsApi = (row, callback = null) => {
    const { accessToken } = this.context;

    Api.update('application_company_settings/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(callback){
                callback();
            }
          }, (error, type) => {
            if(callback){
                callback();
            }
          });
    });
  }

  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoadingStatus: true
    }, () => {
      let startDate = moment().startOf('month').format(apiUtil.getDefaultDateFormat());
      let endDate = moment().endOf('month').format(apiUtil.getDefaultDateFormat());
  
      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoadingStatus: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoadingStatus: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoadingStatus: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }

  callGeoCoderAddressApi = (address = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      geocoderAddressIsLoading: true,
    }, () => {
      let params = {
        address: address,
      };

      ldsApi.create('geo_functions/geocoder_address', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              this.setState({
                geocoderAddressIsLoading: false,
              }, () => {
                if(callback){
                  callback(data.result);
                }
              });
            } else {
              this.setState({
                geocoderAddressIsLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                geocoderAddressIsLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callCheckCompanyCodeApi = (company_code = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
        companyCodeIsLoading: true,
    }, () => {
      let params = {
        company_codes: [ company_code ],
      };

      wmsApi.create('customers/check_customer', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              this.setState({
                companyCodeIsLoading: false,
              }, () => {
                if(callback){
                  callback(data.result);
                }
              });
            } else {
              this.setState({
                companyCodeIsLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                companyCodeIsLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */


  /* FUNCTIONS */
  loadWorkSheet = () => {
    if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                let wb = readXsl(reader.result, {type: 'binary'});
                
                let wsname = wb.SheetNames[0];
                let ws = wb.Sheets[wsname];
               
                let data = xslUtils.sheet_to_json(ws, { defval: null, raw: false, header: 1 });

                let filteredData = data.filter(function (el) {
                  return el[0] !== null;
                });
                
                this.setState({
                    importedSheetData: filteredData,
                }, () => {
                    this.initColumns();
                });
            };
            reader.readAsBinaryString(this.state.file);
        }
    } else {
        console.log("This browser does not support HTML5.");
    }
  }

  initColumns = () => {
    let { isCustomerRequired } = this.context;
    let columns = [];
    let columnsSteps = [];
    let columnsItems = [];

    let headers = [];

    if(this.state.importedSheetData && this.state.importedSheetData.length > 0){
        let heads = this.state.importedSheetData[0];
        headers = heads.map((head, i) => {
            let labelItem = this.state.dataFields.filter(x => x.value === head);

            return {
                value: head,
                label: (labelItem && labelItem.length > 0) ? labelItem[0].value : head,
            }
        });
    }

    this.state.dataFields.map((feild, i) => {
        let headerIndex = headers.findIndex(x => x.value === feild.value);
        if(headerIndex !== -1){
            feild.selected = feild.value;
            feild.isSelected = true;
        } else {
            feild.isSelected = false;
        }

        if(feild.type === DataFieldType.Step){
            columnsSteps.push({ 
                field: feild.selected,
                realField: feild.value,
                title: <Box fontWeight={'bold'}>{feild.label}{((feild.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>,
                required: feild.required,
                dataType: feild.dataType,
                isSelected: feild.isSelected,
                colDB: feild.col,
                width: 200,
                sorting: false,
                searchable: false,
                grouping: false,
                filtering: false,
                render: (row, type) => {
                    return this.customCellSteps(row, feild, i);
                }
            });
        } else if(feild.type === DataFieldType.Item){
            columnsItems.push({ 
                field: feild.selected,
                realField: feild.value,
                title: <Box fontWeight={'bold'}>{feild.label}{((feild.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>,
                required: feild.required,
                dataType: feild.dataType,
                isSelected: feild.isSelected,
                colDB: feild.col,
                width: 200,
                sorting: false,
                searchable: false,
                grouping: false,
                filtering: false,
                render: (row, type) => {
                    return this.customCellItems(row, feild, i);
                }
            });
        } else {
            columns.push({ 
                field: feild.selected,
                realField: feild.value,
                title: feild.label,
                required: feild.required,
                dataType: feild.dataType,
                isSelected: feild.isSelected,
                colDB: feild.col,
                width: 200,
                sorting: false,
                searchable: false,
                grouping: false,
                filtering: false,
                render: (row, type) => {
                    return this.customCell(row, feild, feild.colIndex !== undefined ? feild.colIndex : i);
                }
            });
        }
    });

    columnsSteps.push({ 
        title: "", 
        width: 'auto',
        sorting: false,
        searchable: false,
        grouping: false,
        filtering: false,
        action: true,
        render: (row) => {
            return <Box>
                {(this.state.rowsSteps && this.state.rowsSteps.length > 1) && <IconButton
                    onClick={() => {
                        this.removeStep(row.uuid);
                    }}
                >
                    <DeleteIcon />
                </IconButton>}
            </Box>
        }
    });

    columnsItems.push({ 
        title: "", 
        width: 'auto',
        sorting: false,
        searchable: false,
        grouping: false,
        filtering: false,
        action: true,
        render: (row) => {
            return <Box>
                {(this.state.rowsItems && this.state.rowsItems.length >= 1) && <IconButton
                    onClick={() => {
                        this.removeItem(row.uuid);
                    }}
                >
                    <DeleteIcon />
                </IconButton>}
            </Box>
        }
    });

    columns.forEach((item, index) => {
      if (!isCustomerRequired) {
        if (item.title === 'Company code') {
          columns[index].required = false;
        }

        if (item.title === 'Person in charge tel') {
          columns[index].hidden = true;
        }
      } else {
        if (item.title === 'Recipient name') {
          columns[index].title = 'Company name';
        }

        if (item.title === 'Recipient email' || item.title === 'Person in charge' || item.title === 'Person in charge tel') {
          columns[index].hidden = true;
        }
      }
    });

    this.setState({
        columns: columns,
        columnsSteps: columnsSteps,
        columnsItems: columnsItems,
        headers: headers,
    }, () => {
        if(!this.state.isFirstTime){
            this.initRows();
        } else {
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  initStepColumns = (dataFields = [], callback = null) => {
    let columnsSteps = [];

    let headers = [];

    if(this.state.importedSheetData && this.state.importedSheetData.length > 0){
        let heads = this.state.importedSheetData[0];
        headers = heads.map((head, i) => {
            let labelItem = dataFields.filter(x => x.value === head);

            return {
                value: head,
                label: (labelItem && labelItem.length > 0) ? labelItem[0].label : head,
            }
        });
    }

    dataFields.map((feild, i) => {
        let headerIndex = headers.findIndex(x => x.value === feild.value);
        if(headerIndex !== -1){
            feild.selected = feild.value;
        }

        if(feild.type === DataFieldType.Step){
            columnsSteps.push({ 
                field: feild.selected,
                realField: feild.value,
                title: <Box fontWeight={'bold'}>{feild.label}{((feild.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>,
                required: feild.required,
                dataType: feild.dataType,
                colDB: feild.col,
                width: 200,
                sorting: false,
                searchable: false,
                grouping: false,
                filtering: false,
                render: (row, type) => {
                    return this.customCellSteps(row, feild);
                }
            });
        }
    });

    columnsSteps.push({ 
        title: "", 
        width: 'auto',
        sorting: false,
        searchable: false,
        grouping: false,
        filtering: false,
        action: true,
        render: (row) => {
            return <Box>
                {(this.state.rowsSteps && this.state.rowsSteps.length > 1) && <IconButton
                    onClick={() => {
                        this.removeStep(row.uuid);
                    }}
                >
                    <DeleteIcon />
                </IconButton>}
            </Box>
        }
    });

    this.setState({
        columnsSteps: columnsSteps,
    }, () => {
        if(callback){
            callback();
        }
    });
  }

  initRows = (callback = null) => {
    let excelRows = [];
    
    this.setState({
        isLoadingInitCalls: true,
    });

    if (this.state.importedSheetData && this.state.importedSheetData.length > 1) {
        excelRows = this.state.importedSheetData.map((item, i) => {
            if(i > 0){
                let row = this.state.importedSheetData[0].reduce((sum, key, index) => Object.assign(sum, { [key]: item[index] }), {});
                return row;
            } else {
                return null;
            }
        });
    }

    if(excelRows && excelRows.length > 0){
        excelRows.splice(0, 1);
    }
    
    let rows = this.initChangeColumns(excelRows, DataFieldType.Job);
    let rowsSteps = this.initChangeColumns(excelRows, DataFieldType.Step);
    let rowsItems = this.initChangeColumns(excelRows, DataFieldType.Item);
    
    this.setState({
        excelRows: excelRows,
        rows: rows,
        rowsSteps: rowsSteps,
        rowsItems: rowsItems,
    }, () => {
        if(!this.state.isFirstTime){
            this.initApiCalls()
        }
        
        this.setState({
            isLoadingInitCalls: false,
            isFirstTime: false,
        });

        if(this.state.rowsSteps && this.state.rowsSteps.length === 0){
            this.addStep();
        }

        // if(this.state.rowsItems && this.state.rowsItems.length === 0){
        //     this.addItem();
        // }

        if(callback){
            callback();
        }
    });
  }

  initApiCalls = () => {
    let rows = this.state.rows;

    this.setState({
        isLoadingInitCalls: true,
    }, () => {
        const promises = [];

        if(rows && rows.length > 0){
            rows.forEach((item, i) => {
                if(item['drop_off_address'] && item['drop_off_address'] !== ''){
                    promises.push(this.asyncCallGeoCoderAddressApi(item, 'drop_off_address'));
                }
    
                if(item['company_code'] && item['company_code'] !== ''){
                    promises.push(this.asyncCallCheckCompanyCodeApi(item, 'company_code'));
                }
            });
        }

        if(promises && promises.length > 0){
            Promise.all(promises).then((results) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            }).catch((e) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            });
        }else{
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  checkAddressApiCalls = (field) => {
    let rows = this.state.rows;
    
    this.setState({
        isLoadingInitCalls: true,
    }, () => {
        const promises = [];

        if(rows && rows.length > 0){
            rows.forEach((item, i) => {
                promises.push(this.asyncCallGeoCoderAddressApi(item, field));
            });
        }

        if(promises && promises.length > 0){
            Promise.all(promises).then((results) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            }).catch((e) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            });
        }else{
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }
  checkCodeApiCalls = (field) => {
    let rows = this.state.rows;
    
    this.setState({
        isLoadingInitCalls: true,
    }, () => {
        const promises = [];

        if(rows && rows.length > 0){
            rows.forEach((item, i) => {
                promises.push(this.asyncCallCheckCompanyCodeApi(item, field));
            });
        }

        if(promises && promises.length > 0){
            Promise.all(promises).then((results) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            }).catch((e) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            });
        }else{
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  asyncCallGeoCoderAddressApi = (item, field) => {
    return new Promise(resolve => { 
        this.callGeoCoderAddressApi(item[field], (data) => {
            if(data){
                let coord = {
                    lat: data.lat,
                    lng: data.lng,
                };

                item['coord'] = coord;
                resolve(coord);
            } else {
                item['coord'] = null;
                resolve(null);
            }
        }); 
    });
  }

  asyncCallCheckCompanyCodeApi = (item, field) => {
    return new Promise(resolve => { 
        this.callCheckCompanyCodeApi(item[field], (data) => {
            if(data){
                if(data.exist && data.exist.length > 0){
                    item['isValidCode'] = true;
                    resolve(true);
                } else {
                    item['isValidCode'] = false;
                    resolve(false);
                }
            } else {
                item['isValidCode'] = false;
                resolve(false);
            }
        }); 
    });
  }

  formatDateField = (row, realField, dataType) => {
    let value = (row && realField && row[realField] && row[realField] !== '') ? row[realField] : '';

    if(dataType && dataType === 'date'){
        try {
            let date = moment(value);
            return (date.isValid()) ? date.format(apiUtil.getDefaultDateFormat()) : value;
        } catch(e) {
            return value
        }
    } else if(dataType && dataType === 'datetime'){
        try {
            let datetime = moment(value);
            return (datetime.isValid()) ? datetime.format(apiUtil.getDefaultDateFormat() + ' ' + this.state.timeFormat) : value;
        } catch(e) {
            return value
        }
    } else {
        return value
    }
  }

  initChangeColumns = (excelRows = [], type = 1) => {
    if(type === DataFieldType.Job){
        let rows = [];
    
        if(excelRows && excelRows.length > 0){
            excelRows.map((row, i) => {
                let rowArray = [];
                let columns = JSON.parse(JSON.stringify(this.state.columns));

                let counter = 0;
                Object.entries(row).map(([ key, value ]) => {
                    let realKey = key;
                    if(this.state.dataFields && this.state.dataFields[counter] && this.state.dataFields[counter].value){
                        realKey = this.state.dataFields[counter].value;
                    };
                    let columnsIndex = columns.findIndex(x => x.realField === realKey);
                    if(columnsIndex !== -1){
                        if(columns[columnsIndex].realField && columns[columnsIndex].realField !== ''){
                            let dataType = columns[columnsIndex].dataType;
                            let realField = columns[columnsIndex].realField;
                            rowArray[realField] = this.formatDateField(row, realField, dataType);
                            rowArray[realField + '_validated'] = false;

                            columns.splice(columnsIndex, 1);
                        } else {
                            rowArray[realKey] = '';
                            rowArray[realKey + '_validated'] = false;
                        }
                    } else {
                        rowArray[realKey] = '';
                        rowArray[realKey + '_validated'] = false;
                    }
                    
                    counter++;
                });

                rows.push(Object.assign({}, rowArray));
            });
        }

        return rows;
    } else {
        let rows = [];
    
        let dataFields = [];
        if(this.state.dataFields && this.state.dataFields.length > 0){
            dataFields = this.state.dataFields.filter(x => x.type === type);
        };

        if(excelRows && excelRows.length > 0){
            let row = excelRows[0];

            let currentRow = Object.entries(row).map(([ key, value ]) => key);
                
            let columns = [];
            let data = {};
            dataFields.forEach(fields => {
                columns.push(fields.value);

                data[fields.value] = [];
                currentRow.forEach(x => {
                    const regex = new RegExp(fields.value + "[0-9]", "g");
                    if(x.match(regex)){
                        let index = x.replace(fields.value, '');
                        data[fields.value].push({ [index]: x });
                    }
                });
            });

            let counts = [];
            columns.forEach(column => {
                if(data[column]){
                    counts.push(data[column].length)
                }
            });
            let maxCount = (counts.length > 0) ? Math.max(...counts) : 0;

            if(maxCount > 0){
                for(let c = 0; c < maxCount; c++) {
                    let newObj = {};

                    columns.forEach(column => {
                        if(data[column] && data[column].length > 0 && data[column][c]){
                            Object.entries(data[column][c]).map(([ key, value ]) => {
                                newObj[column] = ((key-1) === c) ? value : '';
                            });
                        }
                    });

                    Object.assign(newObj, { uuid: uuid() });

                    rows.push(newObj);
                }
            }
        }
        
        return rows;
    }
  }

  changeColumns = (realField, selectedField) => {
    let excelRows = this.state.excelRows
    let rows = this.state.rows
    let newRows = [];

    if((rows && rows.length > 0) && (excelRows && excelRows.length > 0)){
        newRows = rows.map((row, i) => {
            row[realField] = (excelRows[i][selectedField] && excelRows[i][selectedField] !== '') ? excelRows[i][selectedField] : '';
            return row;
        });
    }

    return newRows;
  }

  addStepBtn = () => {
    this.setState({
        isLoadingSteps: true,
    }, () => {
        let rowsSteps = this.state.rowsSteps;
        rowsSteps.push({
            uuid: uuid(),
            address: '',
            name: '',
            signature: true,
            scanning: '',
        });
        this.setState({
            rowsSteps: rowsSteps,
            isLoadingSteps: false,
        });
    });
  }
  addStep = () => {
    let item = '';
    let dataFields = this.state.dataFields;
    let itemIndex = dataFields.findIndex(x => x.label === 'Recipient address');
    let itemIndexAddress = dataFields.findIndex(x => x.col === 'address');
    if(itemIndex !== -1){
        item = dataFields[itemIndex].value;
        dataFields[itemIndexAddress].value = item;
    }

    this.initStepColumns(dataFields, () => {
        this.setState({
            dataFields: dataFields,
        }, () => {
            this.setState({
                isLoadingSteps: true,
            }, () => {
                let rowsSteps = this.state.rowsSteps;
                rowsSteps.push({
                    uuid: uuid(),
                    [item]: item,
                    address: '',
                    name: '',
                    signature: true,
                    scanning: '',
                });
                this.setState({
                    rowsSteps: rowsSteps,
                    isLoadingSteps: false,
                });
            });
        });
    });
  }
  removeStep = (uuid) => {
    this.setState({
        isLoadingSteps: true,
    }, () => {
        let rowsSteps = this.state.rowsSteps;
        let index = rowsSteps.findIndex(x => x.uuid === uuid);
        if(index !== -1){
            rowsSteps.splice(index, 1);
        }
        
        this.setState({
            rowsSteps: rowsSteps,
            isLoadingSteps: false,
        });
    });
  }
  
  addItem = () => {
    this.setState({
        isLoadingItems: true,
    }, () => {
        let rowsItems = this.state.rowsItems;
        rowsItems.push({
            uuid: uuid(),
            desc: '',
            qty: '',
            uom: '',
            weight: '',
            remarks: '',
        });
        this.setState({
            rowsItems: rowsItems,
            isLoadingItems: false,
        });
    });
  }
  removeItem = (uuid) => {
    this.setState({
        isLoadingItems: true,
    }, () => {
        let rowsItems = this.state.rowsItems;
        
        let index = rowsItems.findIndex(x => x.uuid === uuid);
        if(index !== -1){
            rowsItems.splice(index, 1);
        }

        this.setState({
            rowsItems: rowsItems,
            isLoadingItems: false,
        });
    });
  }

  getForm(){
    let columns = this.state.columns;
    let rows = this.state.rows;
    let excelRows = this.state.excelRows;
    
    if(rows && rows.length > 0){
        rows = rows.map((objData, o) => {
            let excelRow = excelRows[o];

            if(columns && columns.length > 0){
                columns.forEach(c => {
                    if(c.title === 'Job time'){
                        objData[c.colDB] = moment(objData[c.realField]).toDate();
                    } else if(c.title === 'Notify Customer'){
                        objData[c.colDB] = c.checked;
                    } else if(c.title === 'Send Tracking link'){
                        objData[c.colDB] = c.checked;
                    } else if(c.title === 'Recipient address'){
                        objData[c.colDB] = objData[c.realField];

                        if(objData.coord){
                          objData.drop_off_latitude = objData.coord.lat;
                          objData.drop_off_longitude = objData.coord.lng;
                        }
                    } else if(c.title === 'Assigned Driver ID'){
                        objData[c.colDB] = objData[c.realField];

                        if(objData[c.field] && objData[c.field] != ''){
                          let statusItem = this.state.status.filter(s => s.text === "Assigned");
                          if(statusItem && statusItem.length > 0){
                            objData['order_status'] = statusItem[0].item;
                            objData['order_status_id'] = statusItem[0].id;
                          }
                        } else {
                          let statusItem = this.state.status.filter(s => s.text === "Not Assigned");
                          if(statusItem && statusItem.length > 0){
                            objData['order_status'] = statusItem[0].item;
                            objData['order_status_id'] = statusItem[0].id;
                          }
                        }
                    } else {
                      objData[c.colDB] = objData[c.realField];
                    }
                })
            }

            let items = [];
            let totalPackage = 0;
            let itemFields = this.state.rowsItems;
            let columnsItems = this.state.columnsItems;
            if(itemFields && itemFields.length > 0){
                items = itemFields.map((item, f) => {
                    let row = {};

                    if(columnsItems && columnsItems.length > 0){
                        columnsItems.forEach(c => {
                            if(c.colDB === 'desc'){
                                row['description'] = excelRow[item[c.colDB]];
                            } else if(c.colDB === 'qty'){
                                row['quantity'] = excelRow[item[c.colDB]];
                            } else if(c.colDB === 'uom'){
                                row['uom'] = excelRow[item[c.colDB]];
                            } else if(c.colDB === 'weight'){
                                row['weight'] = excelRow[item[c.colDB]] || 0;
                            } else if(c.colDB === 'remarks'){
                                row['remarks'] = excelRow[item[c.colDB]] || '';
                            } else {
                                row[c.colDB] = excelRow[item[c.colDB]];
                            }
                        })
                    }
                        
                    return row;
                });
            }
            objData.order_details = items.length > 0 ? items : [];
            objData.total_package = totalPackage;

            let steps = [];
            let rowsSteps = this.state.rowsSteps;
            let columnsSteps = this.state.columnsSteps;
            if(rowsSteps && rowsSteps.length > 0){
                steps = rowsSteps.map((step, s) => {
                    let row = {};

                    if(columnsSteps && columnsSteps.length > 0){
                        columnsSteps.forEach(c => {
                            if(c.colDB === 'address'){
                                row['location'] = excelRow[step[c.field]];
                            } else if(c.colDB === 'name'){
                                row['name'] = excelRow[step[c.colDB]] || 'Step ' + (s + 1);
                            } else if(c.colDB === 'signature'){
                                row['is_signature_required'] = (step[c.colDB]) ? true : false;
                            } else if(c.colDB === 'scanning'){
                                row['is_scan_required'] = (step[c.colDB]) ? true : false;
                            } else {
                                row[c.colDB] = excelRow[step[c.colDB]];
                            }
                        })
                    }

                    row['job_step_status_id'] = 1;
                    // row['job_step_pic'] = step.drop_off_pic;
                    // row['job_step_pic_contact'] = step.drop_off_contact_no;

                    return row;
                });
            }
            objData.job_steps = steps.length > 0 ? steps : [];

            console.log("objData", objData);
            return objData;
        });
    }
    
    return rows;
  }
  /* END FUNCTIONS */


  /* TABLE */
  customTableItems = () => {
    if(!this.state.isLoadingItems){
        return <Table 
            className={'import-table'}
            paging={false}
            tableLayout={'fixed'}
            data={this.state.rowsItems}
            columns={this.state.columnsItems}
            isLoading={this.state.isLoadingItems}
        />
    }
  }

  customTableSteps = () => {
      if(!this.state.isLoadingSteps){
        return <Table 
            className={'import-table'}
            paging={false}
            tableLayout={'fixed'}
            data={this.state.rowsSteps}
            columns={this.state.columnsSteps}
            isLoading={this.state.isLoadingSteps}
        />
      }
  }

  customTable = () => {
    return <Table 
        className={'import-table has-overflow'}
        Header={this.customHeader}
        paging={false}
        tableLayout={'fixed'}
        data={this.state.rows}
        columns={this.state.columns}
        maxBodyHeight={'500px'}
    />
  }

  customHeader = (props) => {
    let { isCustomerRequired } = this.context;

    return <thead className={'custom-head-thead'}>
        <tr className={'custom-head-tr'}>
            {props.columns.map((col, i) => {
                return (col.hidden === undefined ? <td key={i} className={'custom-head-th'} width={col.width}>
                    <Box>
                        {
                            (col.dataType === 'checkbox')
                            ?
                            <React.Fragment>
                                <CustomLabel 
                                    className={"custom-label"}
                                    label={<Box fontWeight={'bold'}>{col.title}{((col.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>}
                                />
                                <CustomCheckbox
                                    label={(col.checked) ? 'Unmark All' : 'Mark All'}
                                    checked={col.checked}
                                    onChange={(checked) => {
                                        if(this.state.isFirstTime){
                                            this.initRows(() => {
                                                col.checked = checked;
                                                let rows = this.state.rows;
                                                if(rows && rows.length > 0){
                                                    rows.map((item, i) => {
                                                        item[col.field] = checked;
                                                        item[col.colDB] = checked;
                                                        return item;
                                                    });
                                                }
                                                this.setState({
                                                    rows: rows,
                                                });
                                            })
                                        } else {
                                            col.checked = checked;
                                            let rows = this.state.rows;
                                            if(rows && rows.length > 0){
                                                rows.map((item, i) => {
                                                    item[col.field] = checked;
                                                    item[col.colDB] = checked;
                                                    return item;
                                                });
                                            }
                                            this.setState({
                                                rows: rows,
                                            });
                                        }
                                    }}
                                />
                            </React.Fragment>
                            :
                            (col.field === 'Company Code' && !isCustomerRequired) ? "" : 
                            <CustomSelectOption
                                className={'no-margin white'}
                                label={<Box fontWeight={'bold'}>{col.title}{((col.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>}
                                placeholder={'Choose one'}
                                initFirstItem={true}
                                value={col.field}
                                items={this.state.headers}
                                onChange={(value, item) => {
                                    if(this.state.isFirstTime){
                                        this.initRows(() => {
                                            let columns = this.state.columns;
                                            let headers = this.state.headers;

                                            let selected = col.field;
                                            
                                            if(value !== 0){
                                                col.field = item.value;
                                                selected = col.field;
                                                col.isSelected = true;
                                            } else {
                                                col.field = '';
                                                selected = col.realField;
                                                col.isSelected = false;
                                            }

                                            columns[i] = col;
                                            let dataFieldsIndex = this.state.dataFields.findIndex(x => x.value === col.realField);
                                            if(dataFieldsIndex !== -1){
                                                this.dataFieldsPom[dataFieldsIndex].value = selected
                                            }
                                            
                                            this.setState({
                                                columns: columns,
                                                headers: headers,
                                            }, () => {
                                                let rows = this.changeColumns(col.realField, col.field);
                                                
                                                this.setState({
                                                    rows: rows,
                                                }, () => {
                                                    if((col.field !== '') && col.title === 'Recipient address'){
                                                        this.checkAddressApiCalls(col.realField);
                                                    } else if((col.field !== '') && col.title === 'Company code'){
                                                        this.checkCodeApiCalls(col.realField);
                                                    }

                                                    if(col.title === "Recipient address"){
                                                        let item = '';
                                                        let dataFields = this.state.dataFields;
                                                        let itemIndex = dataFields.findIndex(x => x.label === 'Recipient address');
                                                        let itemIndexAddress = dataFields.findIndex(x => x.col === 'address');
                                                        if(itemIndex !== -1){
                                                            item = col.field;
                                                            dataFields[itemIndexAddress].value = col.field;
                                                        }
                                                        
                                                        this.initStepColumns(dataFields, () => {
                                                            let rowsSteps = this.state.rowsSteps;
                                                            if(rowsSteps && rowsSteps.length > 0){
                                                                rowsSteps = rowsSteps.map((step, i) => {
                                                                    step[item] = item;
                                                                    return step;
                                                                });
                                                                this.setState({
                                                                    rowsSteps: rowsSteps,
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            });
                                        });
                                    } else {
                                        let columns = this.state.columns;
                                        let headers = this.state.headers;

                                        let selected = col.field;
                                        
                                        if(value !== 0){
                                            col.field = item.value;
                                            selected = col.field;
                                            col.isSelected = true;
                                        } else {
                                            col.field = '';
                                            selected = col.realField;
                                            col.isSelected = false;
                                        }

                                        columns[i] = col;

                                        let dataFieldsIndex = this.state.dataFields.findIndex(x => x.value === col.realField);
                                        if(dataFieldsIndex !== -1){
                                            this.dataFieldsPom[dataFieldsIndex].value = selected
                                        }
                                        
                                        this.setState({
                                            columns: columns,
                                            headers: headers,
                                        }, () => {
                                            let rows = this.changeColumns(col.realField, col.field);
                                            
                                            this.setState({
                                                rows: rows,
                                            }, () => {
                                                if((col.field !== '') && col.title === 'Recipient address'){
                                                    this.checkAddressApiCalls(col.realField);
                                                } else if((col.field !== '') && col.title === 'Company code'){
                                                    this.checkCodeApiCalls(col.realField);
                                                }

                                                if(col.title === "Recipient address"){
                                                    let item = '';
                                                    let dataFields = this.state.dataFields;
                                                    let itemIndex = dataFields.findIndex(x => x.label === 'Recipient address');
                                                    let itemIndexAddress = dataFields.findIndex(x => x.col === 'address');
                                                    if(itemIndex !== -1){
                                                        item = col.field;
                                                        dataFields[itemIndexAddress].value = col.field;
                                                    }

                                                    this.initStepColumns(dataFields, () => {
                                                        let rowsSteps = this.state.rowsSteps;
                                                        if(rowsSteps && rowsSteps.length > 0){
                                                            rowsSteps = rowsSteps.map((step, i) => {
                                                                step[item] = item;
                                                                return step;
                                                            });

                                                            this.setState({
                                                                rowsSteps: rowsSteps,
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        });
                                    }
                                }}
                            />
                        }
                    </Box>
                </td> : "")
            })}
        </tr>
    </thead>
  }

  customCell = (row, feild, i) => {
    let { isCustomerRequired } = this.context;
    row[feild.value + '_validated'] = (row[feild.value] && row[feild.value] !== '') ? false : feild.required;
    let isError = (feild.required) ? row[feild.value + '_validated'] : false;
    isError = ((isError === false) || (isError === true)) ? isError : false;
    let isSelected = this.state.columns[i] !== undefined ? this.state.columns[i].isSelected : false;

    if(isSelected){
        return <Box>
            {
                (feild.label === 'Company code')
                ?
                (isCustomerRequired ?
                <CustomInput
                    className={'no-margin'}
                    placeholder={feild.label}
                    value={row[feild.value]}
                    required={feild.required}
                    error={(!row.isValidCode && row.hasCheck) ? true : isError}
                    onChange={(e) => {
                        row[feild.value] = e.target.value;
                        row[feild.value + '_validated'] = (e.target.value !== '') ? false : feild.required;
                    }}
                    onBlur={() => {
                        this.setState({
                            isLoading: true,
                        }, () => {
                            this.asyncCallCheckCompanyCodeApi(row, feild.value).then((results) => {
                                row['isValidCode'] = results;
                                row['hasCheck'] = true;

                                this.setState({
                                    isLoading: false,
                                });
                            }).catch((e) => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                        });
                    }}
                />
                : "")
                :
                (feild.label === 'Recipient address')
                ?
                <CustomGoogleMapSearch
                    className={'no-margin'}
                    placeholder={feild.label}
                    value={row[feild.value]}
                    required={feild.required}
                    error={isError}
                    onChange={(e) => {
                        let value = (e.target.value && e.target.value !== '') ? e.target.value : '';
                        
                        row[feild.value] = value;
                        row[feild.value + '_validated'] = (value !== '') ? false : feild.required;

                        if(value === ''){
                            row['coord'] = null;

                            let rows = this.state.rows;
                            rows[row.tableData.id] = row;
                            this.setState({
                                rows: rows
                            });
                        }
                    }}
                    onPlacesChanged={(place) => {
                        if(place){
                            let address = apiUtil.getGmapAddress(place);
                            
                            row[feild.value] = address;
                            row[feild.value + '_validated'] = feild.required;
                            
                            this.callGeoCoderAddressApi(address, (data) => {
                                if(data){
                                    row['coord'] = {
                                        lat: data.lat,
                                        lng: data.lng,
                                    };
                                }

                                let rows = this.state.rows;
                                rows[row.tableData.id] = row;
                                this.setState({
                                    rows: rows
                                });
                            });
                        } else {
                            row[feild.value] = '';
                            row[feild.value + '_validated'] = feild.required;
                            row['coord'] = null;

                            let rows = this.state.rows;
                            rows[row.tableData.id] = row;
                            this.setState({
                                rows: rows
                            });
                        }
                    }}
                />
                :
                (feild.dataType === 'checkbox')
                ?
                <CustomCheckbox
                    label={feild.label}
                    checked={row[feild.value]}
                    onChange={(checked) => {
                        row[feild.value] = checked;
                    }}
                />
                :
                <CustomInput
                    className={'no-margin'}
                    placeholder={feild.label}
                    value={row[feild.value]}
                    required={feild.required}
                    error={isError}
                    onChange={(e) => {
                        row[feild.value] = e.target.value;
                        row[feild.value + '_validated'] = (e.target.value !== '') ? false : feild.required;
                    }}
                />
            }
        </Box>;
    } else {
        return <Box></Box>
    }
  }

  customCellSteps = (row, feild) => {
    row[feild.value + '_validated'] = (row[feild.value] && row[feild.value] !== '') ? false : feild.required;
    let isError = (feild.required) ? row[feild.value + '_validated'] : false;
    
    return <Box>
        {
            (feild.dataType === 'checkbox')
            ?
            <CustomCheckbox
                label={feild.label}
                checked={row[feild.col]}
                onChange={(checked) => {
                    row[feild.value] = checked;
                    row[feild.col] = checked;
                }}
            />
            :
            <CustomSelectOption
                className={'no-margin white'}
                placeholder={'Choose one'}
                initFirstItem={true}
                value={row[feild.value]}
                items={this.state.headers}
                required={feild.required}
                error={isError}
                onChange={(value, item) => {
                    let columns = this.state.columns;
                    let headers = this.state.headers;
                    
                    let selected = feild.value;

                    if(value !== 0){
                        row[feild.col] = item.value;
                        row[feild.value] = item.value;
                        row[feild.value + '_validated'] = false;
                        selected = row[feild.value];
                    } else {
                        row[feild.col] = '';
                        row[feild.value] = '';
                        row[feild.value + '_validated'] = feild.required;
                    }

                    let dataFieldsIndex = this.state.dataFields.findIndex(x => x.value === feild.value);
                    if(dataFieldsIndex !== -1){
                        this.dataFieldsPom[dataFieldsIndex].value = selected
                    }
                    
                    this.setState({
                        columns: columns,
                        headers: headers,
                    });
                }}
            />
        }
    </Box>;
  }

  customCellItems = (row, feild) => {
    row[feild.value + '_validated'] = (row[feild.value] && row[feild.value] !== '') ? false : feild.required;
    let isError = (feild.required) ? row[feild.value + '_validated'] : false;
    
    return <Box>
        {
            (feild.dataType === 'checkbox')
            ?
            <CustomCheckbox
                label={feild.label}
                checked={row[feild.value]}
                onChange={(checked) => {
                    row[feild.value] = checked;
                    row[feild.col] = checked;
                }}
            />
            :
            <CustomSelectOption
                className={'no-margin white'}
                placeholder={'Choose one'}
                initFirstItem={true}
                value={row[feild.value]}
                items={this.state.headers}
                required={feild.required}
                error={isError}
                onChange={(value, item) => {
                    let columns = this.state.columns;
                    let headers = this.state.headers;

                    let selected = feild.value;

                    if(value !== 0){
                        row[feild.col] = item.value;
                        row[feild.value] = item.value;
                        row[feild.value + '_validated'] = false;
                        selected = row[feild.value];
                    } else {
                        row[feild.col] = '';
                        row[feild.value] = '';
                        row[feild.value + '_validated'] = feild.required;
                    }

                    let dataFieldsIndex = this.state.dataFields.findIndex(x => x.value === feild.value);
                    if(dataFieldsIndex !== -1){
                        this.dataFieldsPom[dataFieldsIndex].value = selected
                    }

                    this.setState({
                        columns: columns,
                        headers: headers,
                    });
                }}
            />
        }
    </Box>;
  }
  /* END TABLE */


  /* FORM */
  isValid = (callback = null) => {
    let state = true;

    let rows = this.state.rows;
    if(rows && rows.length > 0){
        for(let i = 0; i < rows.length; i++){
            if(callback !== null){
                state = callback(state, rows[i]);
                if(!state)
                    break;
            }
        };
    }

    return state;
  }

  setError = () => {
    const { isCustomerRequired } = this.context;
    if (isCustomerRequired) {
      let state = this.isValid((isValidCode = true, item) => {
          if((!item.isValidCode || item.isValidCode === null || item.isValidCode === undefined || item.isValidCode === '') && item.hasCheck){
              isValidCode = false;
          }

          return isValidCode;
      });

      let isSelected = false;
      if(this.state.columns && this.state.columns.length > 0){
          let companyCodeItem = this.state.columns.filter(x => x.title === 'Company code');
          if(companyCodeItem && companyCodeItem.length > 0){
              isSelected = (companyCodeItem[0].field && companyCodeItem[0].field !== '') ? true : false;
          }
      }

      if(isSelected && !state){
          return <Box clone pt={1} pb={1}>
              <Grid item xs={12}>
                  <CustomAlert
                      color={'error'}
                      icon={<i className={'material-icons'}>error</i>}
                  >
                      Invalid company code. Please create the missing company codes or the system won't be able to import their jobs.
                  </CustomAlert>
              </Grid>
          </Box>
      }
    }
  }

  setWarning = () => {
    let state = this.isValid((isValidCoord = true, item) => {
        if(!item.coord || item.coord === null || item.coord === undefined || (!item.coord.lat || !item.coord.lng)){
            isValidCoord = false;
        }

        return isValidCoord;
    });

    let isSelected = false;
    if(this.state.columns && this.state.columns.length > 0){
        let recipientAddressItem = this.state.columns.filter(x => x.title === 'Recipient address');
        if(recipientAddressItem && recipientAddressItem.length > 0){
            isSelected = (recipientAddressItem[0].field && recipientAddressItem[0].field !== '') ? true : false;
        }
    }

    if(isSelected && !state){
        return <Box clone pt={1} pb={1}>
            <Grid item xs={12}>
                <CustomAlert
                    color={'warning'}
                    icon={<i className={'material-icons'}>warning</i>}
                >
                    There are some addresses that we could not recognize. You can still import the job but we would not be able to give coordinates to your driver(s).
                </CustomAlert>
            </Grid>
        </Box>
    }
  }

  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone pb={1}>
                <Grid item xs={12}>
                    <CustomLabel
                        className={'no-margin'}
                        label={<Box fontWeight={'bold'}>Match your uploaded sheet headers to our form. Fields marked with <span className={'text-danger font-size-18'}>*</span> is mandatory.</Box>}
                        size={'md'}
                    />
                    <CustomLabel
                        className={'no-margin'}
                        label={'You may perform changes to your customer details in the columns below.'}
                        size={'sm'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTable()}
                </Grid>
            </Box>
            <Box clone pt={2} pb={1}>
                <Grid item xs={12}>
                    <Box>
                        <Grid container alignItems={'center'}>
                            <Box clone>
                                <Grid item xs={12} md={true}>
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={'JOB STEPS'}
                                        size={'lg'}
                                        weight={'bold'}
                                    />
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={<Box>Each step added here represents an <span style={{ fontWeight: 'bold' }}>step per job</span>. No preview available.</Box>}
                                        size={'md'}
                                    />
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={'auto'}>
                                    <CustomButton 
                                        color={'primary'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            this.addStepBtn();
                                        }}
                                    >
                                        Add Step
                                    </CustomButton>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTableSteps()}
                </Grid>
            </Box>
            <Box clone pt={2} pb={1}>
                <Grid item xs={12}>
                    <Box>
                        <Grid container alignItems={'center'}>
                            <Box clone>
                                <Grid item xs={12} md={true}>
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={'ITEMS (PER JOB)'}
                                        size={'lg'}
                                        weight={'bold'}
                                    />
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={<Box>Each item added here represents an <span style={{ fontWeight: 'bold' }}>item per job</span>. No preview available.</Box>}
                                        size={'md'}
                                    />
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={'auto'}>
                                    <CustomButton 
                                        color={'primary'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            this.addItem();
                                        }}
                                    >
                                        Add Item
                                    </CustomButton>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTableItems()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        Confirm
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }

  setIsLoading = () => {
      if(this.state.isLoadingInitCalls){
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'35vh'} width={'100%'}>
            <CircularProgress size={55} />
        </Box>
      } else {
        return <React.Fragment>
            {this.setError()}
            {this.setWarning()}
            <Box clone pt={2} pb={2}>
                <Grid item xs={12}>
                    {this.setForm()}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </React.Fragment>
      }
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;
    
    let isSelected = true;
    if(this.state.columns && this.state.columns.length > 0){
        let cols = this.state.columns.filter(x => x.required === true);
        if(cols && cols.length > 0){
            for(let c = 0; c <= cols.length; c++){
                try {
                    if(cols[c].isSelected !== true){
                        isSelected = false;
                        break;
                    }
                } catch(e){}
            }
        }
    }

    if (form.checkValidity() === true && isSelected) {
        if(this.state.rule){
            let newRule = this.state.rule;
            newRule.json_data = JSON.stringify(this.dataFieldsPom);
        
            this.callUpdateAppSettingsApi(newRule);
        }

        if(this.props.onImport){
            this.props.onImport(this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        }, () => {
            apiUtil.toast('There are some field(s) which are empty. Please fill in these empty fields to continue.', 'check_circle', 'error');
        });
    }
  }
  /* END SUBMIT */


  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                {this.setIsLoading()}
            </Grid>
        </form>
    </Box>
  }
}

export default JobImportDialog;