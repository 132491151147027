import React from 'react';

import apiUtil from '../../api/apiUtil.jsx';

import { 
    Box,
    Grid,
    Paper,
} from '@material-ui/core';

import MaterialTable, { MTableGroupbar, MTableBodyRow, MTableHeader } from "material-table";
import Pagination from '@material-ui/lab/Pagination';


const noResults = "No results!";

const Table = React.forwardRef((props, ref) => {
    let [isLoading, setIsLoading] = React.useState((props.isLoading === false || props.isLoading === true) ? props.isLoading : false);
    let [grouping, setGrouping] = React.useState((props.grouping === false || props.grouping === true) ? props.grouping : false);
    let [paging, setPaging] = React.useState((props.paging === false || props.paging === true) ? props.paging : true);
    let [selection, setSelection] = React.useState((props.selection === false || props.selection === true) ? props.selection : false);
    let [defaultExpanded, setDefaultExpanded] = React.useState((props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false);
    let [limit, setLimit] = React.useState(props.limit ? props.limit : apiUtil.getMaxPageSize());
    let [total, setTotal] = React.useState(props.total ? props.total : 0);
    let [page, setPage] = React.useState(props.page ? props.page : 1);
    let [data, setData] = React.useState((props.data && props.data.length > 0)? props.data : []);
    let [columns, setColumns] = React.useState((props.columns && props.columns.length > 0)? props.columns : []);
    let [emptyText, setEmptyText] = React.useState(props.emptyText? props.emptyText : noResults);
    let [maxBodyHeight, setMaxBodyHeight] = React.useState(props.maxBodyHeight ? props.maxBodyHeight : '');
    let [minBodyHeight, setMinBodyHeight] = React.useState(props.minBodyHeight ? props.minBodyHeight : '');
    let [tableLayout, setTableLayout] = React.useState(props.tableLayout ? props.tableLayout : 'auto');
    let [className] = React.useState(props.className ? props.className : '');
    let [enableSorting, setEnableSorting] = React.useState((props.enableSorting === false || props.enableSorting === true) ? props.enableSorting : true);

    React.useEffect(() => {
        setIsLoading((props.isLoading === false || props.isLoading === true) ? props.isLoading : false);
        setGrouping((props.grouping === false || props.grouping === true) ? props.grouping : false);
        setPaging((props.paging === false || props.paging === true) ? props.paging : true);
        setSelection((props.selection === false || props.selection === true) ? props.selection : false);
        setDefaultExpanded((props.defaultExpanded === false || props.defaultExpanded === true) ? props.defaultExpanded : false);
        setLimit(props.limit ? props.limit : apiUtil.getMaxPageSize());
        setTotal(props.total ? props.total : 0);
        setPage(props.page ? props.page : 1);
        setData((props.data && props.data.length > 0)? props.data : []);
        setColumns((props.columns && props.columns.length > 0)? props.columns : []);
        setEmptyText(props.emptyText? props.emptyText : noResults);
        setMaxBodyHeight(props.maxBodyHeight ? props.maxBodyHeight : '');
        setMinBodyHeight(props.minBodyHeight ? props.minBodyHeight : '');
        setTableLayout(props.tableLayout ? props.tableLayout : '');
        setEnableSorting((props.enableSorting === false || props.enableSorting === true) ? props.enableSorting : true);
        console.log(props.enableSorting);
    }, [props.isLoading, props.grouping, props.paging, props.limit, props.total, props.page, props.data, props.columns, props.emptyText, props.selection, props.maxBodyHeight, props.minBodyHeight, props.tableLayout, props.defaultExpanded, props.enableSorting]);


    return <Box className={'custom-table-component ' + className}>
        <MaterialTable
            tableRef={ref}

            components={{
                Container: (p) => {
                    return <Paper className={'custom-container'} elevation={0} square {...p} />
                },
                Toolbar: (p) => {
                    if(props.Toolbar){
                        return <Box>
                            {props.Toolbar(p)}
                        </Box>
                    } else {
                        return null;
                    }
                },
                // Body: p => {
                //     let renderData = p.renderData
                //     renderData.forEach((d, i)=>{
                //         renderData[i].tableData.showDetailPanel = p.detailPanel[0].render
                //     })
                //     p = {...p,renderData:renderData}
                //     return <MTableBody {...p} />
                // },
                // Row: p => {
                //     return <React.Fragment>
                //         <MTableBodyRow {...p} />
                //         <tr>
                //             <td 
                //                 colSpan={columns.length}
                //                 style={{
                //                     borderBottom: '1px solid #EAEDF2'
                //                 }}
                //             >
                //             {(props.detailPanel) && props.detailPanel(p.data)}
                //             </td>
                //         </tr>
                //     </React.Fragment>
                // },
                Header: (p) => {
                    if(props.Header) {
                        return <React.Fragment>
                            {props.Header(p)}
                        </React.Fragment>
                    } else {
                        return <MTableHeader {...p} />
                    }
                },
                Row: (p) => {
                    if(props.Row) {
                        return <React.Fragment>
                            <MTableBodyRow {...p} />
                            {props.Row(p, p.data)}
                        </React.Fragment>
                    } else {
                        return <MTableBodyRow {...p} />
                    }
                },
                Groupbar: (p) => {
                    return <Box className={'custom-groupbar'}>
                        <MTableGroupbar {...p} />
                    </Box>
                },
                Pagination: (p) => {
                    return <td style={{ display: 'block' }}>
                        <Box pt={3}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={true}>{page + ' of ' + Math.ceil(total / limit)}</Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={'auto'}>
                                        <Pagination
                                            count={Math.ceil(total / limit)}
                                            size="small"
                                            page={page}
                                            color="primary"
                                            onChange={(e, p) => {
                                                if(props.onPageChange){
                                                    props.onPageChange(p);
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </td>
                }
            }}

            localization={{
                body: {
                    emptyDataSourceMessage: emptyText
                }
            }}

            options={{
                pageSize: limit,
                grouping: grouping,
                paging: paging,
                selection: selection,
                maxBodyHeight: maxBodyHeight,
                minBodyHeight: minBodyHeight,
                tableLayout: tableLayout,
                defaultExpanded: defaultExpanded,
                sorting: enableSorting
            }}

            onSelectionChange={(rows) => {
                if(props.onSelectionChange){
                    return props.onSelectionChange(rows);
                }
            }}

            isLoading={isLoading}
            columns={columns}
            data={data}
            detailPanel={(props.detailPanel) ? [
                {
                    render: (rowData) => {
                        if(props.detailPanel){
                            return props.detailPanel(rowData);
                        }
                    }
                }
            ] : null}
        />
    </Box>
})

export default Table;
