import React, { Component } from 'react';

import { 
    Box,
    Collapse,
} from '@material-ui/core';


class CustomCollapse extends Component {

    constructor(props){
        super(props);
    
        let className = (props.className) ? props.className : '';
        let item = (props.item) ? props.item : null;
        let details = (props.details) ? props.details : null;
        let open = ((props.open === false) || (props.open === true)) ? props.open : false;

        this.state = {
            open: open,

            className: className,
            item: item,
            details: details,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.className !== this.state.className) {
			this.setState({
				className: nextProps.className
			});
		}
		if (nextProps.item !== this.state.item) {
            let item = (nextProps.item) ? nextProps.item : null;
			this.setState({
				item: item
			});
		}
		if (nextProps.details !== this.state.details) {
            let details = (nextProps.details) ? nextProps.details : null;
			this.setState({
				details: details
			});
        }
        if (nextProps.open !== this.state.open) {
            let open = ((nextProps.open === false) || (nextProps.open === true)) ? nextProps.open : false;
			this.setState({
				open: open
			});
		}
	}


    toggle = () => {
        this.setState({
            open: !this.state.open
        });
    }


    render() {
        return <Box className={'custom-collapse-component ' + this.state.className}>
            <Box className={'custom-collapse-item'}>{this.state.item && this.state.item(this, this.state.open)}</Box>
            {this.state.details && <Collapse className={'custom-collapse-details'} in={this.state.open} timeout="auto" unmountOnExit>{this.state.details}</Collapse>}
        </Box>
    }
}


export default CustomCollapse;