import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';


export class CompanyAddressForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let allItems = (props.allItems && props.allItems.length > 0) ? props.allItems : [];
    let item = (props.item) ? props.item : null;
    
    let name = (item && item.name) ? item.name : '';
    let address_line1 = (item && item.address_line1) ? item.address_line1 : '';
    let contact_no = (item && item.contact_no) ? item.contact_no : '';
    let is_main = (item && (item.is_main === true || item.is_main === false) ? item.is_main : false);

    this.state = {
        isLoading: isLoading,
        allItems: allItems,
        item: item,

        name: name,
        nameValidated: false,
        address_line1: address_line1,
        address_line1Validated: false,
        contact_no: contact_no,
        contact_noValidated: false,
        is_main: (item) ? is_main : !this.checkIsMainExists(allItems),
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* FUNCTIONS */
  checkIsMainExists = (allItems = []) => {
    if(allItems && allItems.length > 0){
        let isMain = allItems.filter(x => !x.remove).filter(x => x.is_main === true);

        return (isMain && isMain.length > 0) ? true : false;
    }

    return false;
  }

  isEditRow = (allItems = [], item = null) => {
    if(allItems && allItems.length > 0 && item){
        let row = allItems.filter(x => x.uuid === item.uuid);

        return (row && row.length > 0) ? true : false;
    }

    return false;
  }
  /* END FUNCTIONS */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Label'}
                        placeholder={'Eg. Headquaters, Main Office'}
                        value={this.state.name}
                        error={this.state.nameValidated}
                        required={true}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                name: e.target.value,
                                nameValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomGoogleMapSearch
                        label={'Full Address'}
                        placeholder={'All'}
                        value={this.state.address_line1}
                        error={this.state.address_line1Validated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                address_line1: e.target.value,
                                address_line1Validated: (e.target.value !== '') ? false : true,
                            });
                        }}
                        onPlacesChanged={(place) => {
                            if(place){
                                let address = apiUtil.getGmapAddress(place);
                      
                                this.setState({
                                    address_line1: address,
                                    address_line1Validated: (address !== '') ? false : true,
                                });
                            } else {
                                this.setState({
                                    address_line1: '',
                                    address_line1Validated: true,
                                });
                            }
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Phone Number'}
                        placeholder={'+65'}
                        value={this.state.contact_no}
                        error={this.state.contact_noValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                contact_no: e.target.value,
                                contact_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomCheckbox
                        label={'Primary Address'}
                        checked={this.state.is_main}
                        onChange={(checked) => {
                            this.setState({
                                is_main: checked,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Confirm' : 'Add Address')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;


    this.setState({
      nameValidated: false,
      address_line1Validated: false,
      contact_noValidated: false,
    });
    
    if(this.state.name === ''){
      this.setState({
        nameValidated: true
      });
    }
    if(this.state.address_line1 === ''){
      this.setState({
        address_line1Validated: true
      });
    }
    if(this.state.contact_no === ''){
      this.setState({
        contact_noValidated: true
      });
    }

    if(!this.checkIsMainExists(this.state.allItems) && !this.state.is_main){
        this.setState({
            isLoading: false
        }, () => {
            apiUtil.toast('You must check the primary address!');
        });
        return;
    }


    if (form.checkValidity() === true) {
        if(this.props.onSave){
            this.props.onSave((this.state.item ? true : false), this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
      name: this.state.name,
      address_line1: this.state.address_line1,
      contact_no: this.state.contact_no,
      is_main: this.state.is_main,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default CompanyAddressForm;