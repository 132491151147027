import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import moment from 'moment';

import { 
  Box,
  Grid,
  Hidden,
} from '@material-ui/core';

import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenu.jsx';

import GenerateDialogForm from './Partial/GenerateDialogForm.jsx';
import JobPreviewDialog from '../Jobs/Partial/JobPreviewDialog.jsx';

import DeferredRenderer from '../../components/DeferredRenderer.jsx';

export class ReportTransportOperation extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let isBack = (this.props.location && this.props.location.state && this.props.location.state.isBack && this.props.location.state.isBack !== '') ? this.props.location.state.isBack : '';
    let startDate = (this.props.location && this.props.location.state && this.props.location.state.rangeDate &&this.props.location.state.rangeDate.startDate && this.props.location.state.rangeDate.startDate !== '') ? moment(this.props.location.state.rangeDate.startDate) : moment().startOf('month');
    let endDate = (this.props.location && this.props.location.state && this.props.location.state.endDate && this.props.location.state.endDate !== '') ? moment(this.props.location.state.endDate) : moment().endOf('month');
    let selectedStatusId = (this.props.location && this.props.location.state && this.props.location.state.selectedStatusId && this.props.location.state.selectedStatusId > 0) ? this.props.location.state.selectedStatusId : 0;
    let page = (this.props.location && this.props.location.state && this.props.location.state.page && this.props.location.state.page > 0) ? this.props.location.state.page : 1;

    this.state = {
      reportTab: 0,

      isBack: isBack,
      startDate: startDate,
      endDate: endDate,
      selectedStatusId: selectedStatusId,
      paging: false,
      isLoading: false,
      grouping: false,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: page,

      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,
      openPreviewDialogStep: 0,
    }
  }

  componentDidMount() {
    if(this.state.isBack !== ''){
      this.callReadApi();
    }
  }


  /* API */
  callReadApi = () => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status_ids: (this.state.selectedStatusId && this.state.selectedStatusId > 0) ? this.state.selectedStatusId : '',
        drop_off_worker_id: "",
      };

      ldsApi.create('job/summary', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              data: (data.result && data.result.length > 0) ? data.result : [],
              total: data.total,
              isLoading: false,
            });
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }
  
  callExportXLSApi = (order_status_ids = '', ids = '', isCustomer = false) => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status_ids: order_status_ids,
      };

      if(isCustomer){
        param['customer_id'] = ids;
      } else {
        param['drop_off_worker_id'] = ids;
      }

      ldsApi.exportXLS('job/summary/xls', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Report ${moment(param.start_date).format('YYYY-MM-DD')} - ${moment(param.end_date).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }

  callExportPDFApi = (order_status_ids = '', ids = '', isCustomer = false) => {
    const { accessToken } = this.context;

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        order_status_ids: order_status_ids,
      };

      if(isCustomer){
        param['customer_id'] = ids;
      } else {
        param['drop_off_worker_id'] = ids;
      }

      ldsApi.exportPDF('job/summary/pdf', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            if(response && response.pdf_url && response.pdf_url !== ''){
              const link = document.createElement('a');
              link.href = response.pdf_url;
              link.setAttribute('download', `Report ${moment(param.startDate).format('YYYY-MM-DD')} - ${moment(param.endDate).format('YYYY-MM-DD')}.pdf`);
              link.setAttribute('target', '_blank');
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
            
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END API */


  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    const { isCustomerRequired } = this.context;
    let tabs = [];
    tabs.push("Transport operator");
    if (isCustomerRequired) {
      tabs.push("Customers");
    }

    return <Box clone pl={4} pr={3} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
        <Hidden mdUp>
          <Grid item xs={12} md={12}></Grid>
        </Hidden>
        <Box clone pr={{ xs: 0, md: 2 }} mb={{ xs: 1, md: 0 }}>
            <Grid item xs={12} md={2}>
              <DropdownMenu
                text={'Export report'}
                color={'primary'}
                variant={'outlined'}
                leftIcon={<ExportIcon />}
                items={[
                  {
                    id: 1,
                    text: 'Export to Excel',
                  },
                  {
                    id: 2,
                    text: 'Export to Pdf',
                  },
                ]}
                onClick={(item) => {
                  let selectedStatusId = (this.state.openDialogItem && this.state.openDialogItem.selectedStatusId && this.state.openDialogItem.selectedStatusId > 0) ? this.state.openDialogItem.selectedStatusId : '';

                  if(item.id === 1){
                    this.callExportXLSApi(selectedStatusId, '', isCustomerRequired);
                  } else {
                    this.callExportPDFApi(selectedStatusId, '', isCustomerRequired);
                  }
                }}
              />
            </Grid>
        </Box>
        <Box clone mb={{ xs: 4, md: 0 }}>
          <Grid xs={12} md={'auto'}>
          <CustomButton 
                color={'secondary'}
                fullWidth={true}
                onClick={() => {
                  this.setState({
                    openDialog: true,
                    openDialogItem: null,
                  });
                }}
              >
                Generate report
              </CustomButton>
          </Grid>
        </Box>
        <Box clone pr={1}>
          <Grid xs={12} lg={10}>
            <GroupButton
              className={'head-tabs'}
              color={'secondary'}
              selected={this.state.reportTab}
              buttons={tabs}
              onClick={(selected, btn) => {
                if(selected === 1){
                  this.props.history.push('/report-customers');
                }
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */
 /* DIALOG */
 previewDialog = () => {
  return <CustomDialog 
    open={this.state.openPreviewDialog}
    title={'Job Details'}
    maxWidth={'lg'}
    padding={'0'}
    hideButtons={true}
    onClose={() => {
      this.setState({
        openPreviewDialog: false,
        openPreviewDialogItem: null,
        openPreviewDialogStep: 0,
      });
    }}
  >
    <JobPreviewDialog
      id={this.state.openPreviewDialogItem}
      tab={this.state.openPreviewDialogStep}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
          openPreviewDialogStep: 0,
        });
      }}
    />
  </CustomDialog>
}
/* END DIALOG */

  /* TABLE */
  defaultTable = (data) => {
    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi();
        });
      }}
      // Toolbar={this.customToolbar}
      columns={[
      {
        title: "Driver Name", 
      }, 
      {
        title: "Reference No", 
      }, 
      {
        title: "Job Date", 
      },
      {
        title: "Description", 
      },
      {
        title: "Description", 
      },
      {
        title: "Start Time", 
      },
      {
        title: "Completion Time", 
      },
      {
        title: "Address", 
      },
      {
        title: "Steps", 
      },
       
      ]}
    />
  }

  printRows = (datas) => {
    let rows = [];
    if (datas === undefined) return rows;
    for (let i = 0; i < datas.length; i++) {
      rows.push(<tr key={i}>
        <td align="center">{i+1}</td>
        <td align="center">
          <Box>
            <CustomButton
            className={'underline'}
            color={'primary'}
            href={'/'}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({
                openPreviewDialog: true,
                openPreviewDialogItem: datas[i].order_id,
                openPreviewDialogStep: 0,
              });
            }}
            >
              {datas[i].reference_no}
            </CustomButton>
          </Box>
        </td>
        <td align="center">{moment(datas[i].drop_off_date).format('YYYY-MM-DD')}</td>
        <td align="center"> <span
                      dangerouslySetInnerHTML={{
                        __html: datas[i].descriptions,
                      }}
                    /></td>
        <td align="center">{datas[i].drop_off_time}</td>
        <td align="center">{datas[i].drop_off_time_end}</td>
        <td align="center">{datas[i].address}</td>
        <td align="center">
          <Box>
            <CustomButton
            className={'underline'}
            color={'primary'}
            href={'/'}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({
                openPreviewDialog: true,
                openPreviewDialogItem: datas[i].order_id,
                openPreviewDialogStep: 1,
              });
            }}
            >
            {(datas[i].steps.length > 0) ? datas[i].steps.length + ' step' : '0 step'}
            </CustomButton>
          </Box>
        </td>
      </tr>);
    }
    return rows;
  }

  renderTable = (datas) => {
    let table = [];
    for (let i = 0; i < datas.length; i++) {
      table.push(<div key={i} className="custom-table-component">
        <h1 className="report-header">{datas[i].title}</h1>
        <table className="report-table">
          <thead>
            <tr>
              <th width="4%">No</th>
              <th width="12%">Reference No</th>
              <th width="12%">Job Date</th>
              <th width="20%">Description</th>
              <th width="10%">Start Time</th>
              <th width="10%">Completion Time</th>
              <th width="20%">Address</th>
              <th width="12%">Steps</th>
            </tr>
          </thead>

          <tbody>
            {this.printRows(datas[i].content)}
          </tbody>
        </table>
      </div>);
    }

    return <DeferredRenderer idleTimeout={1000}>{table}</DeferredRenderer>;
  }

  customToolbar = (e) => {
    return <Box>
      <Grid container alignItems={'center'}>
        <Box clone>
          <Grid item xs={12} md={true}>
            <CustomLabel
              label={'Transport Operator Report'}
              size={'lg'}
            />
          </Grid>
        </Box>
        {/* <Box clone textAlign={'right'} pb={2} pr={2}>
          <Grid item xs={12} md={2}>
            <DropdownMenu
              text={'Export report'}
              color={'primary'}
              variant={'outlined'}
              leftIcon={<ExportIcon />}
              items={[
                {
                  id: 1,
                  text: 'Export to Excel',
                },
                {
                  id: 2,
                  text: 'Export to Pdf',
                },
              ]}
              onClick={(item) => {
                let selectedStatusId = (this.state.openDialogItem && this.state.openDialogItem.selectedStatusId && this.state.openDialogItem.selectedStatusId > 0) ? this.state.openDialogItem.selectedStatusId : '';

                if(item.id === 1){
                  this.callExportXLSApi(selectedStatusId, '', false);
                } else {
                  this.callExportPDFApi(selectedStatusId, '', false);
                }
              }}
            />
          </Grid>
        </Box> */}
        <Box clone textAlign={'right'} pb={2}>
          <Grid item xs={12} md={2}>
              <CustomButton 
                color={'secondary'}
                fullWidth={true}
                onClick={() => {
                  this.setState({
                    openDialog: true,
                    openDialogItem: null,
                  });
                }}
              >
                Generate report
              </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END TABLE */

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Generate Driver Report'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
    >
      <GenerateDialogForm
        onClose={() => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          });
        }}
        onOk={(form) => {
          this.setState({
            openDialog: false,
            openDialogItem: form,
          }, () => {
            let startDate = (this.state.openDialogItem && this.state.openDialogItem.rangeDate && this.state.openDialogItem.rangeDate.startDate) ? moment(this.state.openDialogItem.rangeDate.startDate).format() : moment().startOf('month').format();
            let endDate = (this.state.openDialogItem && this.state.openDialogItem.rangeDate && this.state.openDialogItem.rangeDate.endDate) ? moment(this.state.openDialogItem.rangeDate.endDate).format() : moment().endOf('month').format();
            let selectedStatusId = (this.state.openDialogItem && this.state.openDialogItem.selectedStatusId && this.state.openDialogItem.selectedStatusId > 0) ? this.state.openDialogItem.selectedStatusId : 0;

            this.setState({
              startDate: startDate,
              endDate: endDate,
              selectedStatusId: selectedStatusId,
            }, () => {
              this.callReadApi();
            });
          });
        }}
      />
    </CustomDialog>
  }
  /* END DIALOG */

  render() {
    return <Box className="report-page report-transport-operation-page" >
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      <div>
      <Card>
        {this.state.data.length > 0 ? 
          this.state.data &&
            this.renderTable(this.state.data)
        : 
          this.defaultTable()
        }
      </Card>
      </div>
      {this.customDialog()}
      {this.previewDialog()}

    </Box>;
  }
}