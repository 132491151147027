export class RoleAccessService {

    constructor() { }
    
    getCustomerTemplateRoleMenuList(){
      return this.customer_template_role_menu_list;
    }
    getCustomerTemplateRoleJobAccessList(){
      return this.customer_template_role_menu_list;
    }

    customer_template_role_menu_list = ["jobs", "user_profile", "job_form", "report", "report_customers"];
    customer_template_role_job_denied_list = ["job_status", "job_assign", "dashboard", "job_date", "job_time", "step_status", "step_address", "add_more_job_step" ];
  
    isShouldShowMenu(currentRole, menu_name) { 
      if(currentRole === 'Customer Template Role' && this.customer_template_role_menu_list.includes(menu_name)) {
        return true;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
  
      return false;
    }  
    isShouldShowJobAccess (currentRole, section_name) {
      // console.log("currentRole", currentRole);
      // console.log("section_name", section_name);
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
        return false;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
      return true;
    }
    isShouldReadyOnlyAccess (currentRole, section_name) {
      // console.log("currentRole", currentRole);
      // console.log("section_name", section_name);
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
        return true;
      } else if(currentRole !== 'Customer Template Role') {
        return false;
      }
      return true;
    }
    isShouldShowEditButton (currentRole, section_name, worker_id) {
      // console.log("currentRole", currentRole);
      // console.log(section_name);
      // console.log(worker_id+ "worker_id");
      // console.log(typeof worker_id);
      // console.log("section_name");
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name) &&  worker_id != null) {
        return false;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
      return true;
    }
}
