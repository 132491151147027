import React from 'react';

import {
    Box,
    Select,
    MenuItem,
    Input
} from '@material-ui/core';
import Chip from "@mui/material/Chip";
import CustomLabel from './CustomLabel.jsx';


const CustomSelectOption = props => {
    const [className] = React.useState(props.className ? props.className : '');
    const [label, setLabel] = React.useState(props.label ? props.label : '');
    const [value, setValue] = React.useState(props.value ? props.value : '');
    const [items, setItems] = React.useState((props.items && props.items.length > 0) ? props.items : []);
    const [placeholder] = React.useState(props.placeholder ? props.placeholder : null);
    const [labelComponent, setLabelComponent] = React.useState(props.labelComponent ? props.labelComponent : null);
    const [disabled, setDisabled] = React.useState((props.disabled === true || props.disabled === false) ? props.disabled : false);
    const [error, setError] = React.useState((props.error === true || props.error === false) ? props.error : false);
    const [required, setRequired] = React.useState((props.required === true || props.required === false) ? props.required : false);
    const [initFirstItem, setInitFirstItem] = React.useState((props.initFirstItem === true || props.initFirstItem === false) ? props.initFirstItem : false);


    React.useEffect(() => {
        setLabel(props.label ? props.label : '');
        setValue(props.value ? props.value : '');
        setItems((props.items && props.items.length > 0) ? props.items : []);
        setLabelComponent(props.labelComponent ? props.labelComponent : null);
        setDisabled((props.disabled === true || props.disabled === false) ? props.disabled : false);
        setError((props.error === true || props.error === false) ? props.error : false);
        setRequired((props.required === true || props.required === false) ? props.required : false);
        setInitFirstItem((props.initFirstItem === true || props.initFirstItem === false) ? props.initFirstItem : false);
    }, [props.items, props.value, props.label, props.labelComponent, props.disabled, props.error, props.required, props.initFirstItem]);


    const handleChange = event => {
        setValue(event.target.value);

        if(props.onChange){
            props.onChange(event.target.value, getItem(event.target.value));
        }
    };

    const setFirstItem = () => {
        if(initFirstItem){
            return <MenuItem value={0}>Choose One</MenuItem>
        }
    };
    
    const setMenuItems = () => {
        if(items && items.length > 0){
            return items.map((item, i) => {
                return <MenuItem key={i} disabled={item.disabled} value={item.value}>{item.label}</MenuItem>
            });
        }
    };

    const getItem = (value = null) => {
        if(value && items && items.length > 0){
            let item = items.filter(x => x.value === value);
            return (item && item.length > 0) ? item[0] : null;
        } else {
            return null;
        }
    };

    return (
        <Box className={"custom-select-component " + className}>
            {label && <CustomLabel 
                className={"custom-label"}
                label={label}
                labelComponent={labelComponent}
                disabled={disabled}
                error={error}
                required={required}
            />}
            <Select 
                value={(getItem(value)) ? getItem(value).label : ''} 
                onChange={handleChange} 
                disabled={disabled}
                error={error}
                required={required}
                renderValue={(selected) => {
                    let item = getItem(value) ? getItem(value).label : '';
                    return <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {item}
                    </Box>
                }}
                MenuProps={{
                    className: 'custom-select-menu'
                }}
                input={<Input 
                    placeholder={placeholder} />}
            >
                {setFirstItem()}
                {setMenuItems()}
            </Select>
        </Box>
    );
  }

  export default CustomSelectOption;