import React, { Component } from 'react';

import Autosuggest from 'react-autosuggest';

import { 
  Box,
  CircularProgress,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import CustomLabel from './CustomLabel.jsx';
import CustomInput from './CustomInput.jsx';


class CustomAutosuggest extends Component {
    
	constructor(props){
        super(props);
        
        let className = props.className ? props.className : '';
        let value = props.value ? props.value : '';
        let items = (props.items && props.items.length > 0) ? props.items : [];
        let name = props.name ? props.name : '';
        let placeholder = props.placeholder ? props.placeholder : '';
        let label = props.label ? props.label : '';
        let removeMessage = props.removeMessage ? props.removeMessage : '';
        let labelComponent = props.labelComponent ? props.labelComponent : null;
        let helperText = props.helperText ? props.helperText : null;
        let readOnly = ((props.readOnly === true || props.readOnly === false) ? props.readOnly : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let required = ((props.required === true || props.required === false) ? props.required : false);
        let error = ((props.error === true || props.error === false) ? props.error : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let isAjax = ((props.isAjax === true || props.isAjax === false) ? props.isAjax : true);
        let showAllSuggestion = ((props.showAllSuggestion === true || props.showAllSuggestion === false) ? props.showAllSuggestion : true);
        let hideClose = ((props.hideClose === true || props.hideClose === false) ? props.hideClose : false);
        
        let valueName = props.valueName ? props.valueName : 'value';
        let textName = props.textName ? props.textName : 'label';


		this.state = {
			value: value,
            suggestions: [],
            className: className,
            items: items,
            name: name,
            placeholder: placeholder,
            label: label,
            labelComponent: labelComponent,
            helperText: helperText,
            readOnly: readOnly,
            disabled: disabled,
            required: required,
            error: error,
            isLoading: isLoading,
            valueName: valueName,
            textName: textName,
            isAjax: isAjax,
            showAllSuggestion: showAllSuggestion,
            removeMessage: removeMessage,
            hideClose: hideClose,
            isRemoveDialog: false,
            removeRow: null,
		}
	}
    

    componentDidMount() {
        if(!this.state.disabled && this.state.isAjax && this.props.onInit) {
            this.props.onInit(this);
        }
    }

    
    componentWillReceiveProps(nextProps) {
		if (nextProps.items !== this.state.items) {
            let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
			this.setState({
				items: items
			});
        }
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
		}
        if (nextProps.readOnly !== this.state.readOnly) {
			this.setState({
				readOnly: nextProps.readOnly
			});
		}
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
		}
        if (nextProps.error !== this.state.error) {
			this.setState({
				error: nextProps.error
			});
		}
        if (nextProps.required !== this.state.required) {
			this.setState({
				required: nextProps.required
			});
		}
        if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
        if (nextProps.isAjax !== this.state.isAjax) {
            let isAjax = ((nextProps.isAjax === true || nextProps.isAjax === false) ? nextProps.isAjax : true);
        
            if (isAjax !== this.state.isAjax) {
                this.setState({
                    isAjax: nextProps.isAjax
                });
            }
		}
    }
    
  
    showNewSuggestions = (arr = null) => {
        if(arr !== null){
            this.setState({
                items: arr,
            }, () => {
                this.setState({
                    suggestions: this.getSuggestions(''),
                    isLoading: false
                });
            });
        } else {
            this.setState({
                suggestions: this.getSuggestions(''),
                isLoading: false
            });
        }
    }
    showAllSuggestions = () => {
        this.setState({
            suggestions: this.getSuggestions(''),
        });
    }
    hideAllSuggestions = () => {
        this.setState({
            suggestions: [],
        });
    }
    
    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
    getSuggestions = (value) => {
        try {
            const escapedValue = this.escapeRegexCharacters(value.trim());
            const regex = new RegExp('^' + escapedValue, 'i');
            
            if(this.state.items && this.state.items.length > 0){
                return this.state.items.filter(x => regex.test(x[this.state.textName]));
            } else {
                return [''];
            }
        } catch(e) {
            return [''];
        }
    }
    
    getSuggestionValue = (suggestion) => {
        return suggestion[this.state.textName];
    }
    
    shouldRenderSuggestions = () => {
        return !this.state.isLoading;
    }
    
    renderSuggestion = (suggestion) => {
        if(suggestion[this.state.valueName] === -1){
            return <span className={'no-propagation custom-placeholder ' + (suggestion.disabled ? 'muted' : '')}>{suggestion[this.state.textName]}</span>
        } else {
            return <span className={'no-propagation ' + (suggestion.disabled ? 'muted' : '')}>{suggestion[this.state.textName]}</span>
        }
    }

    renderSuggestionsContainer = ({ containerProps, children, query }) => {
        return <Box {...containerProps}>
            {children}
        </Box>
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex }) => {
        event.stopPropagation();
        
        if(!suggestion.disabled){
            if(suggestion[this.state.valueName] === -1){
                event.preventDefault();
                event.stopPropagation();
    
                this.setState({
                    value: ''
                }, () => {
                    if(this.props.onChange) {
                        this.props.onChange(null, this);
                    }
    
                    this.hideAllSuggestions();
                });
            } else {
                this.setState({
                    value: suggestion[this.state.textName]
                }, () => {
                    if(this.props.onChange) {
                        this.props.onChange(suggestion, this);
                    }
    
                    this.hideAllSuggestions();
                });
            }
        } else {
            this.hideAllSuggestions();
        }
    }


    clear = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            value: ''
        }, () => {
            if(this.props.onChange) {
                this.props.onChange(null, this);
            }
        });
    };

    onChange = (event, { newValue, method }) => {
        let disabled = false;
        if(method === 'click') {
            let suggestions = this.getSuggestions(newValue);
            if(suggestions && suggestions.length > 0){
                disabled = suggestions[0].disabled;
            }
        }

        if(!disabled){
            if(this.state.isAjax){
                this.setState({
                    value: newValue
                }, () => {
                    if(this.props.onSearch && method !== 'click') {
                        this.props.onSearch(newValue, this);
                    }
                });
            } else {
                this.setState({
                    value: newValue,
                    suggestions: this.getSuggestions(newValue)
                });
            }
        }
    };

    onBlur = (e) => {
        if(this.props.onBlur) {
            this.props.onBlur();
        }
    };

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            if(this.props.onPressEnter){
                this.props.onPressEnter();
            }
        }
    };

    onMouseDown = () => {
        if(!this.state.disabled){
            if(this.state.isAjax){
                this.setState({
                    isLoading: true,
                });
            }
        }
    };
    
    onMouseUp = () => {
        if(!this.state.disabled){
            if(this.state.isAjax){
                this.setState({
                    isLoading: true,
                }, () => {
                    if(this.props.onClick) {
                        this.props.onClick('', this);
                    }
                });
            }

            if(this.state.showAllSuggestion){
                if(this.props.onClick) {
                    this.props.onClick('', this);
                }
            }
        }
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
        // this.setState({
        //     suggestions: this.getSuggestions(value)
        // });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    

	render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            autoComplete: 'off',
            name: this.state.name,
            id: (this.state.name !== '') ? this.state.name + '-id' : new Date().getTime() + '-id',
            readOnly: this.state.readOnly,
            disabled: this.state.disabled,
            error: this.state.error,
            required: this.state.required,
            fullWidth: true,
            placeholder: this.state.placeholder,
            helperText: this.state.helperText,
            value: (value) ? value : '',
            onChange: this.onChange,
            onMouseDown: this.onMouseDown,
            onMouseUp: this.onMouseUp,
            onBlur: this.onBlur,
            onKeyPress: this.onKeyPress,
        };
        
        return <Box className={"custom-autosuggest-component no-icon " + this.state.className}>
            {this.state.label && <CustomLabel 
                className={"custom-label"}
                label={this.state.label}
                labelComponent={this.state.labelComponent}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
            <Autosuggest
                suggestions={suggestions}
                focusInputOnSuggestionClick ={false}
                alwaysRenderSuggestions={true}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                renderSuggestion={this.renderSuggestion}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
                renderInputComponent={params => (
                    <div className={"custom-autosuggest-input-component no-propagation"}>
                        {this.state.isLoading && <CircularProgress className={"custom-autosuggest-progress"} size={25} />}
                        {
                            (this.state.value && this.state.value !== '' && this.state.value !== 'undefined')
                            ? 
                                (!this.state.disabled)
                                ?
                                    (!this.state.hideClose)
                                    ?
                                        <Icon className={"custom-autosuggest-close no-propagation"} component={'i'} onClick={this.clear}>close</Icon>
                                    :
                                        <></>
                                :
                                    <></>
                            :
                            <></>
                        }
                        <CustomInput 
                            {...params} 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </div>
                )}
            />
        </Box>;
	}
}

export default CustomAutosuggest
