import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';

import { 
  Box,
  Grid,
  Hidden,
  Chip,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomCollapse from '../../../components/Collapse/CustomCollapse.jsx';


export class JobStepsList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let items = (props.items && props.items.length > 0) ? props.items : [];
    
    this.state = {
      job_steps: items,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            job_steps: items
        });
    }
  }


  /* FUNCTIONS */
  renderSteps = () => {
    let steps = [];

    if(this.state.job_steps && this.state.job_steps.length > 0 && this.state.job_steps.length > 0){
        steps = this.state.job_steps.map((item, i) => {
            return this.setItem(item, i)
        })
    }

    return steps;
  }
  /* END FUNCTIONS */


  setHeader = () => {
    return <Hidden smDown implementation="css">
        <Box className={'custom-grid-header'} pb={1}>
            <Grid container alignItems={'center'}>
                <Box clone pl={{ xs: 0, md: 2}}>
                    <Grid item xs={12} md={3}>
                        <CustomLabel
                            label={'Step Name'}
                        />
                    </Grid>
                </Box>
                <Box clone pl={{ xs: 0, md: 2}}>
                    <Grid item xs={12} md={3}>
                        <CustomLabel
                            label={'Status'}
                        />
                    </Grid>
                </Box>
                <Box clone pl={{ xs: 0, md: 2}}>
                    <Grid item xs={12} md={4}>
                        <CustomLabel
                            label={'Address'}
                        />
                    </Grid>
                </Box>
                <Box clone  pl={{ xs: 0, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
                    <Grid item xs={12} md={2}></Grid>
                </Box>
            </Grid>
        </Box>
    </Hidden>
  }

  setStatus = (item) => {
    let status = (item && item.job_step_status && item.job_step_status.name && item.job_step_status.name !== '') ? item.job_step_status.name : '';

    return (status !== '') && <Box><Chip className={'w-100 status-label ' + apiUtil.statusToClassName(status)} label={status} /></Box>
  }

  setItem = (item, i) => {
      return <Box key={i} pb={1}>
          <CustomCollapse
            className={'job-steps-row'}
            item={(obj, isOpen) => {
                return <Box clone p={2}>
                    <Grid container alignItems={'center'}>
                        <Box clone>
                            <Grid item xs={12} md={3}>
                                <CustomLabel
                                    label={item.job_step_name}
                                />
                            </Grid>
                        </Box>
                        <Box clone pl={{ xs: 0, md: 1}}>
                            <Grid item xs={12} md={3}>
                                <Tooltip title={(item && item.job_step_status && item.job_step_status.name && item.job_step_status.name !== '') ? item.job_step_status.name : ''}>
                                    {this.setStatus(item)}
                                </Tooltip>
                            </Grid>
                        </Box>
                        <Box clone pl={{ xs: 0, md: 2}}>
                            <Grid item xs={12} md={4}>
                                <CustomLabel
                                    label={item.location}
                                />
                            </Grid>
                        </Box>
                        <Box clone pl={{ xs: 0, md: 2}} textAlign={'right'}>
                            <Grid item xs={12} md={2}>
                                <IconButton 
                                    color={'primary'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        obj.toggle();
                                    }}
                                >
                                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            }}
            details={<Box clone p={2}>
                <Grid container>
                    <Box clone>
                        <Grid item xs={12} md={5}>
                            <CustomLabel
                                label={'Person in Charge'}
                            />
                            <CustomLabel
                                label={item.job_step_pic}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12} md={4}>
                            <CustomLabel
                                label={'Phone Number'}
                            />
                            <CustomLabel
                                label={item.job_step_pic_contact}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12} md={3}>
                            <CustomLabel
                                label={'Remarks'}
                            />
                            <CustomLabel
                                label={item.description}
                            />
                        </Grid>
                    </Box>
                </Grid>
            </Box>}
        />
      </Box>
  }


  render() {
    return <Box>
        {this.setHeader()}
        {this.renderSteps()}
    </Box>
  }
}

export default JobStepsList;