import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import BackToTop from '../../components/Header/BackToTop.jsx';


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
  },
  drawerOpen: {
    width: drawerWidth + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    height: '56px',
  },
  appBarClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${0}px)`,
    marginLeft: drawerWidth,
    height: '56px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  toolbarHeight: {
    minHeight: '56px',
  },
  notoolbarHeight: {
    minHeight: '0px',
  },
  drawerPaper: {
    maxWidth: drawerWidth,
    borderRight: '0px',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    height: '56px',
    width: '100%',
  },
  nocontent: {
    flexGrow: 1,
    padding: 0,
    height: '0px',
    width: '100%',
  },
}));


const PublicLayout = ({ children, ...rest }) => {
  
  const isMobile = () => {
    const width = document.documentElement.clientWidth || document.body.clientWidth;
    return width < 768;
  }

  const classes = useStyles();

  React.useEffect(() => {
    const updateWindowDimensions = () => {
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    }
  }, []);



  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.nocontent}>
        {children}
      </main>
      <BackToTop />
    </div>
  )
}

export default PublicLayout;
