import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../../api/apiUtil.jsx";
import Api from "../../../api/api.jsx";
import {
  getCategoryCheckList,
  tyreChange,
} from "./helper";
import CustomDateRangePicker from "../../../components/CustomFields/CustomDateRangePicker.jsx";
import {
  Grid,
  Divider,
} from "@material-ui/core";
import CustomLabel from "../../../components/CustomFields/CustomLabel.jsx";
import CustomSelectOption from "../../../components/CustomFields/CustomSelectOption.jsx";
import moment from 'moment';
/**
 * @todo
 * fix pagination.
 */
export const TyreChange = (props) => {
  const { accessToken } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [activeInactiveCustomers, setActiveInactiveCustomers] = useState(0);
  const [data, setData] = useState([]);
  const [sourceData, setSouceData] = useState([]);
  const [recordedIdList, setRecordedIdList] = useState([]);
  let rangeDateInitial = {
    startDate: moment().format(apiUtil.getDefaultDateFormat()),
    endDate: moment().format(apiUtil.getDefaultDateFormat()),
  };
  const [currentSubmittedId, setCurrentSubmittedId] = useState(null);

  let rangeDateItem = localStorage.getItem("tyre_servicing" + "_rangeDate");
  if (rangeDateItem && rangeDateItem !== "") {
    try {
      rangeDateInitial = JSON.parse(rangeDateItem);
    } catch (e) {}
  }
  const [rangeDate, setRangeDate] = useState(rangeDateInitial);
  useEffect(() => {
    callReadApi(rangeDate.startDate, rangeDate.endDate);
    return () => {};
  }, [page, searchVal, activeInactiveCustomers]);

  /* API */
  const callReadApi = (startDate, endDate) => {
    let param = {
      start_date: moment(startDate).format(apiUtil.getDefaultDateTimeFormat()),
      end_date: moment(endDate).format(apiUtil.getDefaultDateTimeFormat()),
      vehicle_id: props.vehicleId,
      description: "tyre check list",
    };
    Api.read("vehicle_check_list_records", param, accessToken).then(
      (results) => {
        apiUtil.parseResult(
          results,
          (data) => {
            const dataArr =
              data.result && data.result.length > 0 ? data.result : [];
            const dataList = dataArr.filter((item) => {
              if (item.vehicle_check_list_fields.length > 0) {
                return item;
              }
            });
            const ids = dataArr.map((item) => {
              return { value: item.id, label: moment(item.created_at).format(apiUtil.getDefaultDateTimeFormat()) };
            });
            setRecordedIdList(ids);
            setData(dataList.length > 0 ? [dataList[0]] : []);
            setSouceData(dataList);
            setCurrentSubmittedId(dataList.length > 0 ? dataList[0].id: null);
          },
          (error, type) => {
            setData([]);
          }
        );
      }
    );
  };

  const renderData = (data) => {
    return (
      <>
        {data.map((item) => {
          return (
            <>
              <span style={{ display: "block" }}>
                {" "}
                {item.vehicle_check_list_master_field && (
                  <div>
                    <span style={{ width: "50%", display: "inline-block" }}>
                      {item.vehicle_check_list_master_field.name}
                    </span>
                    :{" "}
                    <span>{item.vehicle_check_list_field_selection ? item.vehicle_check_list_field_selection.name : "-"}</span>
                  </div>
                )}
              </span>
            </>
          );
        })}
      </>
    );
  };

  const renderCheckList = (dataList, categoryId) => {
    const data = getCategoryCheckList(dataList, categoryId);
    if (!data || data?.length === 0) {
      return null;
    }

    return (
      <>
        {data.map((itemList) => {
          return renderData(itemList.vehicle_check_list_fields);
        })}
      </>
    );
  };

  return (
    <Box className="vehicle-checklist-page">
      <div>
        <Grid item xs={12}>
          <CustomLabel label={"Tyre Change"} weight={"bold"} size={"md"} />
          <Divider light />
        </Grid>
        <span style={{ display: "inline-block", width: "30%" }}>
          <CustomDateRangePicker
            className={"select-dates-step"}
            range={rangeDate}
            onChange={(range) => {
              localStorage.setItem(
                "tyre_servicing" + "_rangeDate",
                JSON.stringify(range)
              );
              setRangeDate(range);
              callReadApi(range.startDate, range.endDate);
            }}
          />
        </span>
        <span style={{ display: "block", marginTop: "13px", width: "30%" }}>
        <CustomSelectOption
            required={true}
            label={"Submitted list"}
            value={currentSubmittedId}
            items={recordedIdList}
            onChange={(value, item) => {
              setCurrentSubmittedId(value);
              const newData = sourceData.filter((item) => item.id === value);
              setData(newData);
            }}
          />
        </span>

        {data && data.length > 0 && renderCheckList(data, tyreChange)}
      </div>
    </Box>
  );
};
