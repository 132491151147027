import React, { useState } from 'react';

import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withScriptjs, withGoogleMap} from "react-google-maps"
// import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";

import apiUtil from '../../api/apiUtil.jsx';

import defaultImage from '../../assets/img/icons/live_tracking_user.png';
// import { ReactComponent as DeliveryTruck } from '../../assets/img/icons/delivery-truck.svg';

// import { 
//     Box,
//     Grid,
// } from '@material-ui/core';


const { 
    REACT_APP_CORS_ANYWHERE_URL,
} = process.env;

const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };

const GOOGLE_STYLE = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#46BCEC"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
];


const CustomLiveTrackingMap = compose(
    withProps({
        googleMapURL: apiUtil.getGoogleMapUrl(),
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%' }} />,
        mapElement: <div style={{ width: '100%', height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => {
    const [defaultCenter] = useState(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
    const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
    const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 12);
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : 12);
    const [markers, setMarkers] = useState((props.markers && props.markers.length > 0) ? props.markers : []);


    React.useEffect(() => {
        setCenter(props.center ? props.center : DEF_CENTER);
        setZoom(props.zoom ? props.zoom : 12);
        prepareAllMarkers((props.markers && props.markers.length > 0) ? props.markers : []);
    }, [props.center, props.zoom, props.markers]);

    
    const prepareAllMarkers = async (markers) => {
        if(markers && markers.length > 0){
            for(let i = 0; i < markers.length; i++){
                if(markers[i].profile_image_url_small && markers[i].profile_image_url_small !== ''){
                    await urlToBase64(markers[i].profile_image_url_small).then(result => {
                        markers[i].base64Result = (result) ? result : '';
                        markers[i].isOpen = false;
                    });
                } else {
                    markers[i].base64Result = defaultImage;
                    markers[i].isOpen = false;
                }
            }
        }
        
        setMarkers(markers)
    }

    const urlToBase64 = async (url) => {
        return new Promise(result => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if(typeof reader.result == 'string'){
                        result(defaultImage);
                    } else {
                        result(reader.result);
                    }
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', REACT_APP_CORS_ANYWHERE_URL + url);
            xhr.responseType = 'blob';
            xhr.send();
        }, (result) => {
            result(null);
        });
    };

    
    const getPinIcon = (img = '', color = '#e94560', driver = '', vehicle = '') => {
      return '<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="auto" height="47" viewBox="0 0 auto 47">' +
        '<defs>' +
          '<pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="-95 -95 800 800">' +
            '<image width="500" height="500" href="' + img + '"/>' +
          '</pattern>' +
        '</defs>' +
        '<g stroke="' + color + '" stroke-width="2">' +
          '<path fill="#fff" d="M 2.126 14.6306 c 1.122 -3.857 3.2747 -7.2401 6.0614 -9.526 C 11.1528 2.6714 14.9311 1.332 18.8271 1.332 c 3.8962 0 7.6748 1.3401 10.6403 3.7725 c 2.8832 2.3652 5.0607 5.9258 6.1303 10.0263 c 1.0531 4.036 0.9583 8.2435 -0.2669 11.8453 c -2.7622 8.1203 -10.0287 14.1755 -16.5034 19.5117 C 11.9664 40.8257 4.5076 34.1117 2.0395 25.9661 C 0.9831 22.4798 1.0136 18.4543 2.126 14.6306 z"/>' +
          '<g stroke="white" stroke-width="1" fill="url(#pattern)">' +
            '<circle cx="22.5" cy="22.5" r="22.5" stroke="none"/>' +
            '<circle cx="28" cy="28" r="4" fill="' + color + '"/>' +
          '</g>' +
        '</g>' +
        '<text x="40" y="20" letter-spacing="0" fill="#4b7daa" stroke="white" stroke-width="3" style="font-size: 14px; font-family: arial; font-weight: bold;">' + driver + '</text>' +
        '<text x="40" y="20" letter-spacing="0" fill="#4b7daa" style="font-size: 14px; font-family: arial; font-weight: bold;">' + driver + '</text>' +
        '<text x="40" y="35" letter-spacing="0" fill="white" stroke="white" stroke-width="3" style="font-size: 13px; font-family: arial; font-weight: bold;">' + vehicle + '</text>' +
        '<text x="40" y="35" letter-spacing="0" fill="black" style="font-size: 13px; font-family: arial; font-weight: bold;">' + vehicle + '</text>' +
      '</svg>';
    }

    const showAllMarkers = () => {
        return markers.map((item, i) => {
            let name = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');
            //if (/^[\w\-\s\.]+$/.test(name) !== true) name = "";
            let vehicle = (item.vehicle && item.vehicle.plate_no && item.vehicle.plate_no !== '') ? item.vehicle.plate_no : '';
            let position = (item.latitude && item.longitude) ? { lat: item.latitude, lng: item.longitude } : null;
            let color = (item.is_online) ? '#2ec1ac' : '#e94560';
            let svg = getPinIcon(item.base64Result, color, name, vehicle);
            let icon = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);

            if(position){
              return <Marker 
                  key={i}
                  position={position}
                  icon={{
                      url: icon,
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(17, 46),
                  }}
                  draggable={false}
                  onClick={(e) => {
                      if(props.onClick){
                          props.onClick(e, item, i);
                      }
                  }}
              >
                  {/* {(item.isOpen) && <InfoBox 
                      options={{
                          maxWidth: 250,
                          pixelOffset: new window.google.maps.Size(-210, -125),
                      }}
                      onCloseClick={() => {
                          if(props.onClick){
                              props.onClick(null, item, i);
                          }
                      }}
                  >
                      <Box
                          style={{
                              width: '250px',
                              backgroundColor: 'white',
                              borderRadius: '6px',
                              overflow: 'hidden',
                          }}
                      >
                          <Grid container>
                              <Box clone>
                                  <Grid item xs={'auto'}>
                                      <Box className={(item.is_online) ? 'bg-primary' : 'bg-second'} p={2}>
                                          <DeliveryTruck style={{ position: 'relative', top: '3px' }} />
                                      </Box>
                                  </Grid>
                              </Box>
                              <Box clone>
                                  <Grid item xs={true}>
                                      <Box p={2} fontWeight={'bold'}>{name}</Box>
                                  </Grid>
                              </Box>
                          </Grid>
                      </Box>
                  </InfoBox>} */}
              </Marker>
            }
        });
    }

    
    const googleMap = () => {
      return <div>
        <GoogleMap
            ref={props.onMapMounted}
            onCenterChanged={props.onCenterChanged}
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            center={center}
            zoom={zoom}
            defaultOptions={{
                disableDefaultUI: true,
                disableDoubleClickZoom: false,
                rotateControl: false,
                styles: GOOGLE_STYLE,
            }}
        >
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={10}
          >
            {showAllMarkers()}
          </MarkerClusterer>
        </GoogleMap>
      </div>
    }
    
    return googleMap();
});


export default CustomLiveTrackingMap;