import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';

export class ItemsInJobForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let description = (item && item.description) ? item.description : '';
    let volume = (item && item.volume) ? item.volume : '';
    let width = (item && item.width) ? item.width : '';
    let length = (item && item.length) ? item.length : '';
    let height = (item && item.height) ? item.height : '';
    let division = (item && item.division) ? item.division : '';
    let part_no = (item && item.part_no) ? item.part_no : '';
    let lot_no = (item && item.lot_no) ? item.lot_no : '';
    let serial_no = (item && item.serial_no) ? item.serial_no : '';
    let expiry_date = (item && item.expiry_date) ? item.expiry_date : '';
    let quantity = (item && item.quantity) ? item.quantity : '';
    let uom = (item && item.uom) ? item.uom : '';
    let weight = (item && item.weight) ? item.weight : '';
    let remarks = (item && item.remarks) ? item.remarks : '';

    this.state = {
        isLoading: isLoading,
        item: item,

        description: description,
        descriptionValidated: false,
        volume: volume,
        width: width,
        widthValidated: false,
        length: length,
        lengthValidated: false,
        height: height,
        heightValidated: false,
        division: division,
        divisionValidated: false,
        part_no: part_no,
        part_noValidated: false,
        lot_no: lot_no,
        lot_noValidated: false,
        serial_no: serial_no,
        serial_noValidated: false,
        expiry_date: expiry_date,
        expiry_dateValidated: false,
        quantity: quantity,
        quantityValidated: false,
        uom: uom,
        // uomValidated: false,
        weight: weight,
        // weightValidated: false,
        remarks: remarks,
        remarksValidated: false,
    }
  }
  

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* FORM */
  setForm = () => {
    let weightLabel = localStorage.getItem('Weight') !== null ? localStorage.getItem('Weight') : 'Weight (lb)';
    if (weightLabel === 'kilogram (kg)') weightLabel = 'Weight (kg)';
    else if (weightLabel === 'pound (lb)') weightLabel = 'Weight (lb)';

    return <Box>
        <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Division'}
                        placeholder={'Division'}
                        value={this.state.division}
                        autoFocus={true}
                        onChange={(e) => {
                            this.setState({
                                division: e.target.value,
                                divisionValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Part No*'}
                        placeholder={'Part No'}
                        value={this.state.part_no}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                part_no: e.target.value,
                                part_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Description*'}
                        placeholder={'Brief about the item....'}
                        value={this.state.description}
                        error={this.state.descriptionValidated}
                        multiline={true}
                        rows={3}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                description: e.target.value,
                                descriptionValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Lot No'}
                        placeholder={'Lot No'}
                        value={this.state.lot_no}
                        onChange={(e) => {
                            this.setState({
                                lot_no: e.target.value,
                                lot_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Serial No'}
                        placeholder={'Serial No'}
                        value={this.state.serial_no}
                        onChange={(e) => {
                            this.setState({
                                serial_no: e.target.value,
                                serial_noValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Expiry Date'}
                        placeholder={'Expiry Date'}
                        type={'date'}
                        value={this.state.expiry_date}
                        onChange={(e) => {
                            this.setState({
                                expiry_date: e.target.value,
                                expiry_dateValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Quantity'}
                        placeholder={'Quantity'}
                        type={'number'}
                        value={this.state.quantity}
                        error={this.state.quantityValidated}
                        required={true}
                        onChange={(e) => {
                            this.setState({
                                quantity: e.target.value,
                                quantityValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Length (cm)'}
                        placeholder={'Length (cm)'}
                        type={'number'}
                        value={this.state.length}
                        onChange={(e) => {
                            this.setState({
                                length: e.target.value,
                                lengthValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Width (cm)'}
                        placeholder={'Width (cm)'}
                        type={'number'}
                        value={this.state.width}
                        onChange={(e) => {
                            this.setState({
                                width: e.target.value,
                                widthValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Height (cm)'}
                        placeholder={'Height (cm)'}
                        type={'number'}
                        value={this.state.height}
                        onChange={(e) => {
                            this.setState({
                                height: e.target.value,
                                heightValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
            {/* <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'UOM'}
                        placeholder={'Enter uom'}
                        value={this.state.uom}
                        // error={this.state.uomValidated}
                        // required={true}
                        onChange={(e) => {
                            this.setState({
                                uom: e.target.value,
                                // uomValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box> */}
            {/* <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={weightLabel}
                        placeholder={'Enter weight of the item'}
                        type={'number'}
                        value={this.state.weight}
                        // error={this.state.weightValidated}
                        // required={true}
                        onChange={(e) => {
                            this.setState({
                                weight: e.target.value,
                                // weightValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box> */}
            <Box clone>
                <Grid item xs={12}>
                    <CustomInput
                        label={'Remarks'}
                        placeholder={'Message here....'}
                        value={this.state.remarks}
                        error={this.state.remarksValidated}
                        multiline={true}
                        rows={3}
                        // required={true}
                        onChange={(e) => {
                            this.setState({
                                remarks: e.target.value,
                                remarksValidated: (e.target.value !== '') ? false : true,
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Save' : 'Save')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        descriptionValidated: false,
        quantityValidated: false,
        // uomValidated: false,
        // weightValidated: false,
        remarksValidated: false,
    });
    
    if(this.state.description === ''){
      this.setState({
        descriptionValidated: true
      });
    }
    if(this.state.quantity === ''){
      this.setState({
        quantityValidated: true
      });
    }
    // if(this.state.uom === ''){
    //   this.setState({
    //     uomValidated: true
    //   });
    // }
    // if(this.state.weight === ''){
    //   this.setState({
    //     weightValidated: true
    //   });
    // }
    if(this.state.remarks === ''){
      this.setState({
        remarksValidated: true
      });
    }


    if (form.checkValidity() === true) {
        if(this.props.onSave){
            this.props.onSave((this.state.item ? true : false), this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        description: this.state.description,
        width: this.state.width,
        length: this.state.length,
        height: this.state.height,
        division: this.state.division,
        part_no: this.state.part_no,
        lot_no: this.state.lot_no,
        serial_no: this.state.serial_no,
        expiry_date: this.state.expiry_date,
        quantity: this.state.quantity,
        uom: this.state.uom,
        weight: this.state.weight,
        remarks: this.state.remarks,
    }
    console.log(data);
    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default ItemsInJobForm;