import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';


export default function BackToTop(props) {
  const { window } = props;
  
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" style={{ position: 'fixed', bottom: '40px', right: '15px', zIndex: '1' }}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <i className="material-icons text-white">keyboard_arrow_up</i>
        </Fab>
      </div>
    </Zoom>
  );
}