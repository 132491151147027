import React, { Component } from 'react';

import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../../api/apiUtil.jsx';
import ldsApi from "../../../api/ldsApi.jsx";
import {RoleAccessService} from '../../../data/role-access'; 
import { 
  Box,
  Grid,
} from '@material-ui/core';

import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../../components/CustomFields/CustomButton.jsx';
import CustomSelectOption from '../../../components/CustomFields/CustomSelectOption.jsx';
import CustomGoogleMap from '../../../components/GoogleMap/CustomGoogleMap.jsx';
import CustomCheckbox from '../../../components/CustomFields/CustomCheckbox.jsx';
import CustomGoogleMapSearch from '../../../components/GoogleMap/CustomGoogleMapSearch.jsx';


export class StepsForm extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let item = (props.item) ? props.item : null;
    
    let job_step_name = (item && item.job_step_name) ? item.job_step_name : '';
    let job_step_status_id = (item && item.job_step_status_id) ? item.job_step_status_id : 1;
    let location = (item && item.location) ? item.location : '';
    let latitude = (item && item.latitude) ? item.latitude : 0;
    let longitude = (item && item.longitude) ? item.longitude : 0;
    let is_signature_required = (item && (item.is_signature_required === true || item.is_signature_required === false)) ? item.is_signature_required : true;
    let is_scan_required = (item && (item.is_scan_required === true || item.is_scan_required === false)) ? item.is_scan_required : false;
    let job_step_pic = (item && item.job_step_pic) ? item.job_step_pic : '';
    let job_step_pic_contact = (item && item.job_step_pic_contact) ? item.job_step_pic_contact : '';
    let description = (item && item.description) ? item.description : '';
    let grantedAccess = new RoleAccessService();
    console.log("this.props.isAddJob");
    console.log(this.props.isAddJob);
    this.state = {
        isLoading: isLoading,
        item: item,
        grantedAccess : grantedAccess,
        currentRole: window.localStorage.getItem('current_role') || null,
        geocoderAddressIsLoading: false,
        reverseGeocoderIsLoading: false,

        jobStepStatusesIsLoading: false,
        jobStepStatuses: [],

        job_step_name: job_step_name,
        job_step_nameValidated: false,
        job_step_status_id: job_step_status_id,
        job_step_status: null,
        job_step_status_idValidated: false,
        location: location,
        latitude: latitude,
        longitude: longitude,
        locationValidated: false,
        is_signature_required: is_signature_required,
        is_scan_required: is_scan_required,
        job_step_pic: job_step_pic,
        job_step_pic_contact: job_step_pic_contact,
        description: description,
    }
  }
  

  componentDidMount() {
    this.callJobStepStatusesApi();
    if(this.state.location !== '' && 
      !this.state.latitude && 
      !this.state.longitude){
      this.callGeoCoderAddressApi(this.state.location, (data) => {
        if(data){
          this.setState({
            latitude: data.lat,
            longitude: data.lng,
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.item !== this.state.item) {
        let item = (nextProps.item) ? nextProps.item : null;
        this.setState({
            item: item
        });
    }
  }


  /* STATUSES API */
  callJobStepStatusesApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      jobStepStatusesIsLoading: true,
      jobStepStatuses: [],
    }, () => {
      ldsApi.get('api/job_step_statuses', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let arr = [];
            if(data && data.result && data.result.length > 0){
              arr = data.result.map((item, i) => {
                let value = item.id;
                let label = item.name;

                if(arr.findIndex(x => x.value === value) === -1){
                  return {
                    value: value,
                    label: label,
                    item: item
                  }
                } else {
                  return null;
                }
              });
      
              this.setState({
                jobStepStatusesIsLoading: false,
                jobStepStatuses: arr,
              }, () => {
                if(callback){
                  callback(arr);
                }
              });
            } else {
              this.setState({
                jobStepStatusesIsLoading: false,
                jobStepStatuses: [],
              }, () => {
                if(callback){
                  callback([]);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              jobStepStatusesIsLoading: false,
              jobStepStatuses: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          });
      });
    });
  }
  /* END ORDER STATUSES API */


  /* GEO FUNCTIONS API */
  callGeoCoderAddressApi = (address = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      geocoderAddressIsLoading: true,
    }, () => {
      let params = {
        address: address,
      };

      ldsApi.create('geo_functions/geocoder_address', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              this.setState({
                geocoderAddressIsLoading: false,
              }, () => {
                if(callback){
                  callback(data.result);
                }
              });
            } else {
              this.setState({
                geocoderAddressIsLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                geocoderAddressIsLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callReverseGeocoderAddressApi = (latitude = '', longitude = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
        reverseGeocoderIsLoading: true,
    }, () => {
      let params = {
        latitude: latitude,
        longitude: longitude
      };

      ldsApi.create('geo_functions/reverse_geocode', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              this.setState({
                reverseGeocoderIsLoading: false,
              }, () => {
                if(callback){
                  callback(data.result);
                }
              });
            } else {
              this.setState({
                reverseGeocoderIsLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
                reverseGeocoderIsLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END GEO FUNCTIONS API */


  /* FORM */
  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone pr={{ xs: 0, md: 2 }}>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomInput
                                    label={'Step Name'}
                                    placeholder={'Step Name'}
                                    value={this.state.job_step_name}
                                    error={this.state.job_step_nameValidated}
                                    required={true}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        this.setState({
                                            job_step_name: e.target.value,
                                            job_step_nameValidated: (e.target.value !== '') ? false : true,
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomSelectOption
                                    disabled={this.props.isAddJob === true ? false : this.state.grantedAccess.isShouldReadyOnlyAccess(this.state.currentRole, "step_status")}
                                    label={'Step Status'}
                                    placeholder={'Step Status'}
                                    value={this.state.job_step_status_id}
                                    items={this.state.jobStepStatuses}
                                    onChange={(value, item) => {
                                        this.setState({
                                            job_step_status_id: value,
                                            job_step_status: item,
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomGoogleMapSearch
                                    readOnly={this.props.isAddJob === true ? false : this.state.grantedAccess.isShouldReadyOnlyAccess(this.state.currentRole, "step_address")}                                    
                                    label={'Step Address'}
                                    placeholder={'Step Address'}
                                    value={this.state.location}
                                    error={this.state.locationValidated}
                                    required={true}
                                    onChange={(e) => {
                                        this.setState({
                                            location: e.target.value,
                                            locationValidated: (e.target.value !== '') ? false : true,
                                        });
                                    }}
                                    onPlacesChanged={(place) => {
                                      let address = apiUtil.getGmapAddress(place);
                      
                                        this.setState({
                                            location: address,
                                            latitude: place.geometry.location.lat(),
                                            longitude: place.geometry.location.lng(),
                                            locationValidated: (address !== '') ? false : true,
                                        }, () => {
                                            // this.callGeoCoderAddressApi(this.state.location, (data) => {
                                            //     if(data){
                                            //       this.setState({
                                            //         latitude: data.lat,
                                            //         longitude: data.lng,
                                            //       });
                                            //     }
                                            // });
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>

                        <Box clone pt={2}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Box clone>
                                        <Grid item xs={'auto'}>
                                            <CustomCheckbox
                                                label={'Requires Signature'}
                                                checked={this.state.is_signature_required}
                                                onChange={(checked) => {
                                                    this.setState({
                                                        is_signature_required: checked,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={'auto'}>
                                            <CustomCheckbox
                                                label={'Scan Tracking Number Here'}
                                                checked={this.state.is_scan_required}
                                                onChange={(checked) => {
                                                    this.setState({
                                                        is_scan_required: checked,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box clone>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomInput
                                    label={'Person In Charge'}
                                    placeholder={'Person In Charge'}
                                    value={this.state.job_step_pic}
                                    onChange={(e) => {
                                        this.setState({
                                            job_step_pic: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomInput
                                    label={'Phone No.'}
                                    placeholder={'Phone No.'}
                                    value={this.state.job_step_pic_contact}
                                    onChange={(e) => {
                                        this.setState({
                                            job_step_pic_contact: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12}>
                                <CustomInput
                                    label={'Remarks'}
                                    placeholder={'Message here....'}
                                    value={this.state.description}
                                    multiline={true}
                                    rows={3}
                                    onChange={(e) => {
                                        this.setState({
                                            description: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
            <Box clone style={{ height: '400px' }}>
                <Grid item xs={12}>
                    <CustomGoogleMap
                        center={(this.state.latitude !== '' && this.state.longitude !== '') ? { lat: this.state.latitude, lng: this.state.longitude } : null}
                        onDragEnd={(position) => {
                          this.setState({
                            latitude: position.lat,
                            longitude: position.lng,
                          }, () => {
                            this.callReverseGeocoderAddressApi(this.state.latitude, this.state.longitude, (data) => {
                              if(data && data.address){
                                this.setState({
                                  location: data.address,
                                });
                              }
                            });
                          });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'secondary'} 
                        isLoading={this.state.isLoading}
                    >
                        {(this.state.item ? 'Save' : 'Save')}
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    this.setState({
        isLoading: true,
    });

    const form = e.currentTarget;

    this.setState({
        job_step_nameValidated: false,
        job_step_status_idValidated: false,
        locationValidated: false,
    });
    
    if(this.state.job_step_name === ''){
      this.setState({
        job_step_nameValidated: true
      });
    }
    if(this.state.job_step_status_id === ''){
      this.setState({
        job_step_status_idValidated: true
      });
    }
    if(this.state.location === ''){
      this.setState({
        locationValidated: true
      });
    }


    if (form.checkValidity() === true) {
        if(this.props.onSave){
            this.props.onSave((this.state.item ? true : false), this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }

  
  getForm = () => {
    let data = {
        job_step_name: this.state.job_step_name,
        job_step_status_id: this.state.job_step_status_id,
        job_step_status: this.state.job_step_status,
        location: this.state.location,
        is_signature_required: this.state.is_signature_required,
        is_scan_required: this.state.is_scan_required,
        job_step_pic: this.state.job_step_pic,
        job_step_pic_contact: this.state.job_step_pic_contact,
        description: this.state.description,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
    }

    return data;
  }
  /* END SUBMIT */
      
  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container>
                <Box clone pt={2} pb={2}>
                    <Grid item xs={12}>
                        {this.setForm()}
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        {this.setButtons()}
                    </Grid>
                </Box>
            </Grid>
        </form>
    </Box>
  }
}

export default StepsForm;